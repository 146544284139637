import getToken from "./GetToken";

function SplitToken(){
    const token = getToken();
    // console.log("token is", token);
    if (!token){return};
 
    function parseJwt(token) {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    const pulledToken = parseJwt(token);
    const userArray = Object.values(pulledToken);
    const user = { 
        id: userArray[0],
        userName: userArray[1],
        EmailId: userArray[2],
        isAdmin: userArray[4], 
        LoginUnix: userArray[6],
        ExpireUnix: userArray[7],
        ConfirmToken: userArray[8],
        hasConfirmedEmail: userArray[9],
        UserRole: userArray[10]
    }
    console.log("pulled token", pulledToken);
    console.log("ARRAY", userArray)
    console.log("user is", user);
    return user;
}

export default SplitToken;