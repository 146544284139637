import React, { useEffect, useState } from "react";
import Page from '../../components/Page';
import ChartService from "../../utils/DashboardChartService";
import getToken from "../../components/Tokens/GetToken";
import {
    Box,
    Container,
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import theme from "../../theme";
import Toolbar from '../Toolbar/Toolbar';
import { styled } from '@mui/system';
import { useNavigate } from "react-router";
import moment from "moment";

const MyPage = styled(Page)({
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
})


const NotificationsChart = () => {

    const token = getToken();
    // const user = SplitToken();
    const fleetData = useSelector((state) => state.DeviceReducers.FleetData);

    const [startDate, setStartDate] = React.useState(moment().subtract(30, "days").startOf('day').format());
    const [endDate, setEndDate] = React.useState(moment().format());
    const [notifChart, setNotifChart] = React.useState();



    useEffect(() => {
        if (token) {
            dispatch({ type: 'HOME_DATA', payload: "92a82177-885e-4ca4-a094-654851e21851", deviceId:"5867ad00-bcda-4a58-bcb3-7eb4f92c7d05", start: startDate, end: endDate })
        }
    }, [])

    const dispatch = useDispatch();

    // useEffect(() => {
    //     makeStuff(fleetData)
    // }, [fleetData])

    let info = 0;
    let warning = 0;
    let error = 0;
    console.log("#$ fleet data", fleetData);

    // function makeStuff(data) {
        if (fleetData.length > 1) {

            fleetData.forEach((deviceInfo) => {

                // if (deviceInfo.alerts ) {
                    deviceInfo.alerts.forEach((alert) => {

                        switch (alert.severity) {
                            case 0:
                                info++;
                                break;
                            case 1:
                                warning++;
                                break;
                            case 2:
                                error++;
                                break;

                            default:
                                break;
                        }

                    }
                    )

                // }
            }
            );
        }
    // }

    return (
        <div >
            <Box>

                <div style={{ "font-size": "18pt", "margin": "15px", "color": "gray" }}>Notifications: Red River</div>

            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ height: "fit-content", width: "100%", display: "flex", flexDirection: "row" }}>
                    <Box sx={{ height: 150, width: 150, borderRadius: 100, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Box sx={{ height: 80, width: 80, borderRadius: 100, backgroundColor: "red", display: "flex", alignItems: "center", justifyContent: "center", margin: 1 }}>
                            <Box sx={{ height: 55, width: 55, borderRadius: 50, backgroundColor: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <div style={{ "font-size": "18pt" }}>{error}</div>
                            </Box>
                        </Box>
                        Error
                    </Box>
                    <Box sx={{ height: 150, width: 150, borderRadius: 100, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Box sx={{ height: 80, width: 80, borderRadius: 100, backgroundColor: "orange", display: "flex", alignItems: "center", justifyContent: "center", margin: 1 }}>
                            <Box sx={{ height: 55, width: 55, borderRadius: 50, backgroundColor: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <div style={{ "font-size": "18pt" }}>{warning}</div>
                            </Box>
                        </Box>
                        Warning
                    </Box>
                    <Box sx={{ height: 150, width: 150, borderRadius: 100, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Box sx={{ height: 80, width: 80, borderRadius: 100, backgroundColor: "green", display: "flex", alignItems: "center", justifyContent: "center", margin: 1 }}>
                            <Box sx={{ height: 55, width: 55, borderRadius: 50, backgroundColor: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <div style={{ "font-size": "18pt" }}>{info}</div>
                            </Box>
                        </Box>
                        Info
                    </Box>
                </Box>
            </Box>
        </div>
    )

}

export default NotificationsChart;