import { combineReducers } from 'redux';

const AllDevices = (state = [], action) => {
    switch (action.type) {
        case 'SET_DEVICE_DATA':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const EverythingSet = (state = false, action) => {
    switch (action.type) {
        case 'SET_EVERYTHING':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const GroupDevices = (state = [], action) => {
    switch (action.type) {
        case 'SET_GROUP_DEVICES':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const FleetData = (state = [], action) => {
    switch (action.type) {
        case 'SET_FLEET_DATA':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const SearchClicked = (state = false, action) => {
    switch (action.type) {
        case 'SEARCH_CLICKED':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const DeviceInfo = (state = {}, action) => {
    switch (action.type) {
        case 'SET_DEVICE_INFO':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const DeviceInfoStatus = (state = {}, action) => {
    switch (action.type) {
        case 'SET_DEVICE_INFO_STATUS':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const AlarmData = (state = {}, action) => {
    switch (action.type) {
        case 'SET_ALARM_DATA':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const DeviceMeasurements = (state = {}, action) => {
    switch (action.type) {
        case 'SET_DEVICE_METHANE_MEASUREMENTS':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const HomeMeasurements = (state = {}, action) => {
    switch (action.type) {
        case 'SET_HOME_MEASUREMENTS':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const TransferGroup = (state = [], action) => {
    switch (action.type) {
        case 'SET_TRANSFER_GROUP':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const TransferDevice = (state = [], action) => {
    switch (action.type) {
        case 'SET_TRANSFER_DEVICE':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

export default combineReducers({
    AllDevices,
    EverythingSet,
    GroupDevices,
    FleetData,
    SearchClicked,
    DeviceInfo,
    AlarmData,
    TransferGroup,
    TransferDevice,
    DeviceInfoStatus,
    DeviceMeasurements,
    HomeMeasurements,
});