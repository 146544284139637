import React from 'react';
import {
    TextField,
    Button,
    Typography
} from '@mui/material';
import { connect, useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/system';
import getToken from '../../../components/Tokens/GetToken';

const Paper = styled('div')({
    backgroundColor: 'white',
    width: '80%',
    height: '80%',
    display: 'flex',
    justifyContent: 'center',
    // flexWrap: 'wrap',
    border: '1px solid black'
})

const MyButton = styled(Button)({
    width: '25%',
    minHeight: '50px'
})

const Input = styled(TextField)({
    margin: '10px'
})

const CreateHardware = React.forwardRef((props, ref) => {
    const [minor, setMinor] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const [major, setMajor] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [revision, setRevision] = React.useState(null);
    const dispatch = useDispatch();
    const token = getToken();
    // console.log('props ', props);

    const handleChange = (type, event) => {
        switch (type) {
            case 'minor':
                return setMinor(event.target.value);
            case 'file':
                return setFile(event.target.value);
            case 'major':
                return setMajor(event.target.value);
            case 'name':
                return setName(event.target.value);
            case 'revision':
                return setRevision(event.target.value);
            default:
                break;
        }
    }

    const handleSubmit = () => {
        const item = {
            Name: name,
            MajorVersion: parseInt(major),
            MinorVersion: parseInt(minor),
            RevisionVersion: parseInt(revision),
        };
        // console.log('item', item);
        fetch('/api/Hardware/CreateHardware', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(item)
        })
            .then(response => console.log(response.status))
            .then(props.close)
            // .then(dispatch({ type: "ADMIN_GRAB_HARDWARE" }))
            .catch(error => console.error('Unable to add item.', error));

            dispatch({ type: "ADMIN_GRAB_HARDWARE" });
    }

    return (
        <Paper>
            <div style={{ width: '100%', textAlign: 'center', marginTop: "15%" }}>
                <div style={{ margin: '15px' }}>
                    <Typography
                        variant="h2"
                    >
                        ADD Hardware Version
                    </Typography>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <Input
                        required
                        id="name"
                        label="Version Name"
                        defaultValue={name}
                        variant="outlined"
                        onChange={(event) => handleChange('name', event)}
                    />
                    <Input
                        required
                        id="majorVersion"
                        label="Major Version"
                        defaultValue={major}
                        variant="outlined"
                        type="number"
                        onChange={(event) => handleChange('major', event)}
                    />
                    <br />
                    <Input
                        required
                        id="minorVersion"
                        label="Minor Version"
                        defaultValue={minor}
                        variant="outlined"
                        type="number"
                        onChange={(event) => handleChange('minor', event)}
                    />
                    <Input
                        required
                        id="revision"
                        label="Revision Version"
                        defaultValue={revision}
                        variant="outlined"
                        type="number"
                        onChange={(event) => handleChange('revision', event)}
                    />
                </div>
                <br />
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px'
                }}>
                    <MyButton
                        variant="contained"
                        // color="primary"
                        sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </MyButton>
                </div>
            </div>
        </Paper>
    )
})

export default connect()(CreateHardware);