import { all } from 'redux-saga/effects';
import DevicesSaga from './DevicesSagas/Devices.saga';
import HomeDataSaga from './HomeDataSaga/HomedataSaga';
import UserConfirmEmail from './UserSagas/UserConfirmEmail.saga';
import UserInfoUpdate from './UserSagas/UserInfoUpdate.saga';
import UserLogin from './UserSagas/UserLogin.saga';
import UserRegister from './UserSagas/UserRegister.saga';
import EverythingSaga from './EverythingSagas/EverythingSagas';
import DeviceConfigSaga from './DeviceConfigSagas/DeviceConfigSaga';
import UpdateMethaneSubscription from './SubscriptionSagas/Subscription.saga'
import AdminSaga from './AdminSagas/Admin.sagas';
import DashboardSaga from './DashboardSagas/DashboardSaga';
import JsonFileDownloadSaga from './FileSagas/JsonFileDownload';
import CasePhotoSaga from './FileSagas/CasePhotoSaga';
import DeleteJsonFileSaga from './FileSagas/DeleteJsonFile';
import UploadJsonFileSaga from './FileSagas/UploadJsonFile';
import User2FA from './UserSagas/User2FA.saga';

export default function* rootSaga() {
    yield all([
        UserLogin(),
        UserRegister(),
        UserConfirmEmail(),
        UserInfoUpdate(),
        HomeDataSaga(),
        DevicesSaga(),
        EverythingSaga(),
        DeviceConfigSaga(),
        UpdateMethaneSubscription(),
        AdminSaga(),
        DashboardSaga(),
        JsonFileDownloadSaga(),
        CasePhotoSaga(),
        DeleteJsonFileSaga(),
        UploadJsonFileSaga(),
        User2FA(),
    ]);
}