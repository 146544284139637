import React, { useState, useEffect } from 'react';
import {
    Box,
} from "@mui/material";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Typography } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import ChartService from "../../../utils/DashboardChartService";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ExternalLink } from 'react-feather';
import Swal from 'sweetalert2';

const FleetStatus = (props) => {
    const fleetData = useSelector((state) => state.DeviceReducers.FleetData);
    // const isLoading = useSelector((state) => state.ViewReducer.FleetLoad);
    const isLoading = false;
    const [fleetList, setFleetList] = useState(fleetData);
    const dispatch = useDispatch();
    const columns = [
        { field: "deviceName", headerName: "Device Name", flex: 1, cellClassName: 'links', renderCell: (params) => <SerialCell params={params} cell={params.row.deviceName} parent='serial' /> },
        { field: 'alerts', headerName: 'Notifications', flex: 1, headerClassName: 'header', renderCell: (params) => (
            Alerts(params.row)
        )},
        { field: 'battery', headerName: 'Battery', flex: 1, headerClassName: 'header', renderCell: (params) => (
            Battery(params.row)
        )},
        // { field: "battery", headerName: "Battery", flex: 1, renderCell: (params) => typeof( params.row.battery) === 'number' ? Number(params.row.battery).toFixed(1) + "%" : params.row.battery },
        // {field:"externalId", headerName: "IMEI", flex: 1},
        // {field:"status", headerName: "Status", flex: 1},
        // { field: "measurementCount", headerName: "Measurement Count", flex: 1 },
        { field: "reportDate", headerName: "Report Date", flex: 1, valueGetter: (params) => moment.utc(params.row.reportDate).local().format('M/D/YY, HH:mm ') }
    ];
    const navigate = useNavigate();

    function Battery(x){
        if (x.battery >= 75){
            return(
                <span style={{color:"green"}}>{x.battery}%</span>
            )
        } else if (x.battery < 75 && x.battery > 20){
            return(
                <span style={{color:"orange"}}>{x.battery}%</span>
            )
        } else if (x.battery < 20){
            return(
                <span style={{color:"red"}}>{x.battery}%</span>
            )
        }
    }

    function Alerts(x) {
        if ( x.alerts.length === 0 ) {
            return (
                <div>None</div>
            )
        }
        else if ( x.alerts.length > 0) {
            // console.log(x.alerts.length)
            let info = 0;
            let warning = 0;
            let error = 0;
            for (let a = 0; a < x.alerts.length; a++){
                // console.log(x.alerts[a].severity)
                // console.log(x.alerts[a].name)
                if (x.alerts[a].severity == 0){
                    info += 1;
                }
                if (x.alerts[a].severity == 1){
                    warning += 1;
                }
                if (x.alerts[a].severity == 2){
                    error += 1;
                }
            }
            return (
                <div>
                    {error > 0 ?
                        <span style={{color:"red"}}>Error({error})  </span> 
                        :
                        <span></span>
                    }
                    {warning > 0 ?
                        <span style={{color:"orange"}}>Warning({warning})  </span> 
                        :
                        <span></span>
                    }
                    {info > 0 ?
                        <span style={{color:"green"}}>Info({info})  </span> 
                        :
                        <span></span>
                    }
                </div>
            )
        }
    };

    useEffect(() => {
        for(let data of fleetData){
            if(data.measurementCount == 0 && data.stateOfCharge == 0){
                data.stateOfCharge = "Not Reported";
            }
        }
        setFleetList(fleetData)
    }, [])
    // console.log('device list', deviceList); //
    // console.log('fleet data', fleetData)
    // console.log('fleet list', fleetList)

    if (fleetList.length > 0 && fleetData.length > 0 && isLoading === false) {
        if (fleetList[0].faults !== fleetData[0].faults) {
            setFleetList(fleetData);
        }
    }

    if (isLoading === true) {
        Swal.fire({
            title: "Loading...",
            imageUrl: '/static/images/loading-gif.gif',
            showConfirmButton: false,
            backdrop: "false",
        })
    };
    if (isLoading === false) {
        Swal.close();
    };

    const SerialCell = ({ params, cell, parent }) => {
        // console.log('cell in special cell', cell);
        let display = '';
        if (parent === 'serial' || cell === 'View Alarms') {
            display = <><u>{cell}</u> <ExternalLink style={{ marginBottom: -2, height: 16, width: 16 }} /> </>;
        } else {
            display = cell;
        }

        return <p onClick={() => {
            // console.log('params', params.row);
            // params.row.serialNumber = params.row.commonName;
            window.google = undefined
            dispatch({ type: 'FLEET_SERVICE', payload: params.row, path: 'fleetCellClick' });
            dispatch({ type: 'GET_DEVICE_VIEW_DATA', payload: params.row.id, start: moment().subtract(30, "days").startOf('day').format(), end: moment().format() })
            navigate("/deviceView");

        }}> {display} </p>
    }

    // if (fleetList.length > 0) {
        return (
            <div>
                { fleetData.length > 0 ?
                    <Box width={'100%'} height={'100%'}>
                        <ChartService data={{ type: 'special chart', title: 'Status', columns: columns, list: fleetData }} />
                    </Box>
                    :
            //     {/* ) */}
            // {/* // }
            // // else {
            // //     return ( */}
                    <Box width={'100%'} height={'100%'} sx={{textAlign:'center', padding:'5px'}}>
                        Status: Select device to load data table
                    </Box>
                }
            </div>
        )
    // }
}

export default FleetStatus;
