import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../../alerts/Toast';
import getToken from '../../../components/Tokens/GetToken';

function* DevicesSaga() {
    yield takeLatest('FETCH_ALL_DEVICES', FetchAllDevices);
    yield takeLatest('GET_GROUP_DEVICES', GetGroupDevices);
    yield takeLatest('GET_FLEET_DATA', GetFleetData);
    yield takeLatest('GET_DEVICE_VIEW_DATA', GetDeviceViewData);
    yield takeLatest('UPDATE_DEVICE_LOCATION', UpdateDeviceLocation);
    yield takeLatest('CREATE_NEW_DEVICE', CreateNewDevice);
    yield takeLatest('HOME_DATA', HomePage);

}

function* FetchAllDevices(action) {
    try {
        let token = getToken();
        let retval = yield axios.get(`api/Device/GetAllDevices`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
        console.log("retval for fetch all devices", retval.data);
        yield put({ type: "SET_DEVICE_DATA", payload: retval.data })

    } catch (error) {
        console.log('error in FetchAllDevices saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with Grabbing Devices: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

function* GetGroupDevices(action) {
    try {
        // console.log('start of fetch devices', action );
        let token = getToken();
        let retval = yield axios.get(`api/Device/GetGroupDevices/${action.payload}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
        // console.log("retval for fetch group devices", retval.data);
        yield put({ type: "SET_GROUP_DEVICES", payload: retval.data })

    } catch (error) {
        console.log('error in GetGroupDevices saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with Grabbing Group Devices: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}
function* GetFleetData(action) {
    yield put({ type: "SET_FLEET_DATA", payload: [] })
    try {
        // console.log('start of fetch fleet data', action );
        let token = getToken();
        let retval = yield axios.get(`api/Device/GetFleetData/${action.payload}/${action.start}/${action.end}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
        // console.log("retval for fetch fleet data", retval.data);
        yield put({ type: "SET_FLEET_DATA", payload: retval.data })

        // let chartData = yield axios.get(`api/Device/GetDeviceChartData/${action.payload}/${action.start}/${action.end}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        // console.log('#$ chart data return', chartData.data);
        // yield put({ type: 'SET_DEVICE_METHANE_MEASUREMENTS', payload: chartData.data });

    } catch (error) {
        console.log('error in GetFleetData saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with Grabbing Group Devices: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

function* HomePage (action) {
    // yield put({ type: "SET_FLEET_DATA", payload: [] })
    try {
        // console.log('start of fetch fleet data', action );
        let token = getToken();
        let retval = yield axios.get(`api/Device/GetFleetData/${action.payload}/${action.start}/${action.end}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
        // console.log("retval for fetch fleet data", retval.data);
        yield put({ type: "SET_FLEET_DATA", payload: retval.data })

        // let deviceId = '5867ad00-bcda-4a58-bcb3-7eb4f92c7d05' 
        let chartData = yield axios.get(`api/Device/GetDeviceChartData/${action.deviceId}/${action.start}/${action.end}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        console.log('#$ chart data return', chartData.data);
        yield put({ type: 'SET_HOME_MEASUREMENTS', payload: chartData.data });

    } catch (error) {
        console.log('error in GetFleetData saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with Grabbing Group Devices: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

function* GetDeviceViewData(action) {
    try {
        // console.log('#$ start of fetch device data', action );
        let token = getToken();
        let device = yield axios.get(`api/Device/GetDeviceMapViewData/${action.payload}/${action.start}/${action.end}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } }); // for map data
        // console.log("#$ 1 device for fetch fleet data", device.data);
        yield put({ type: "SET_FLEET_DATA", payload: device.data });

        let deviceInfo = yield axios.get(`api/Device/GetDeviceInfoData/${action.payload}/${action.start}/${action.end}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        deviceInfo.data.model = "SolarBee"
        // console.log('#$ 2 device info ', deviceInfo.data);
        yield put({ type: 'SET_DEVICE_INFO', payload: deviceInfo.data });
        yield put({ type: 'SET_DEVICE_INFO_STATUS', payload: deviceInfo.status });

        let notificationInfo = yield axios.get(`api/Device/GetNotificationData/${action.payload}/${action.start}/${action.end}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        // console.log('#$ 2 notification info ', notificationInfo.data);

        yield put({ type: 'SET_ALARM_DATA', payload: notificationInfo.data }); 

        let chartData = yield axios.get(`api/Device/GetDeviceChartData/${action.payload}/${action.start}/${action.end}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        // console.log('#$ chart data return', chartData.data);
        yield put({ type: 'SET_DEVICE_METHANE_MEASUREMENTS', payload: chartData.data });
        

    } catch (error) {
        console.log('error in GetFleetData saga', error);
        // Toast.fire({
        //     icon: 'error',
        //     title: `Error with Grabbing Group Devices: Error Code ${error.response.status} ${error.response.statusText}`,
        // });
    }
}

//"7867ad00-bcda-4a58-bcb3-7eb4f92c7d05"

function* UpdateDeviceLocation(action) {
    try {
        const token = getToken();
        let newDevice = yield axios.put(`api/Device/NewLocation/${action.payload.id}/${action.payload.lat}/${action.payload.long}`, {}, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
        // console.log("retval for device lat and long data", newDevice.data);

        yield put({ type: "FETCH_ALL_DEVICES"})
        Toast.fire({
            icon: 'success',
            title: `Device location has changed successfully`,
        });
    } catch (e) {
        console.log("error in update device location", e);
        Toast.fire({
            icon: 'error',
            title: `Error with Grabbing Group Devices: Error Code ${e.response.status} ${e.response.statusText}`,
        });
    }
}

function* CreateNewDevice(action) {
    try {
        console.log('new device', action);
        const token = getToken();
        let newDevice = yield axios.post(`api/Device/NewDevice`, action.payload, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
        // console.log("retval for device lat and long data", newDevice);

        let devices = yield axios.get(`api/Device/GetAllDevices`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        yield put({ type: "SET_DEVICE_DATA", payload: devices.data })
    } catch (e) {
        console.log("error in create new device", e);
    }
}

export default DevicesSaga;