import Swal from "sweetalert2";
import "./Toast.css"

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    iconColor: "white",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    // didOpen: (toast) => {
    //     toast.addEventListener('mouseenter', Swal.stopTimer)
    //     toast.addEventListener('mouseleave', Swal.resumeTimer)
    //   },
    customClass: {
        popup: 'colored-toast'
    },
});

export default Toast;