import { createTheme } from '@mui/material/styles';
import { colors } from '@mui/material';
import typography from './typography';

const theme = createTheme({
  overrides: {
    MuiListItem: {
      "&$selected": {
        // color: colors.blue[500], // original
        // color: '#2196f3', // original in hex
        color: '#00b4ad', // Ixom
        // backgroundColor: colors.lightBlue[500] // original
        // backgroundColor: '#03a9f4' // orginal in hex
        backgroundColor: '#00968e' // Ixom
      }
    }
  },
  palette: {
    background: {
      // dark: '#F4F6F8', // original
      dark: '#F4F6F8', // ixom
      default: '#FFFFFF', // original
      // default: '#00b4ad', // ixom
      // paper: '#FFFFFF' // original
      paper: '#FFFFFF' // ixom
    },
    primary: {
      // main: '#0e5e8a'
      main: '#d64343', // vivid red 5% lighter
      black: '#000000', // black
      white: '#ffffff', // white
    },
    secondary: {
      main: '#fff',
      vividDarkRed: '#a72525', // vivid dark red
      palePink: '#f78da7', // pale pink
      gray: '#a6a6a6', // gray
      darkPink: '#ee1148', // dark pink
      // darkTeal: '#00968e', // darkTeal
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    },
    button: {
      primary: '#cf2e2e', // vivid red
      secondary: '#f78da7', // pale pink
      hover: '#a72525', // vivid dark red
      secondaryHover: '#ee1148', // dark pink
    },
    borderWithShadow: {
      borderColor: '#a72525', // vivid dark red
      borderStyle: 'solid', 
      borderWidth: '2px', 
      borderRadius: '10px', 
      boxShadow: '5px 5px 5px 3px rgba(168,168,168,0.45)'
    },
    shadow: {
      boxShadow: '5px 5px 5px 3px rgba(168,168,168,0.45)'
    },
    borderNoShadow: {
      borderColor: '#a72525', // vivid dark red
      borderStyle: 'solid', 
      borderWidth: '2px', 
      borderRadius: '10px', 
    }
  },
  typography
});

export default theme;


/* Ixom colors:
  main:
    teal: '#00b4ad'
    black: '#000000'
    white: '#ffffff'
  compliment:
    purple: '#9e2fa1'
    gray: '#a6a6a6'
    orange: '#e7641c'
    dark teal: '#00968e'
*/

/* notes for color deisgn: */
/* for buttons: to change the color other than standard, and on hover:
 sx={{ bgcolor: theme.palette.secondary.darkTeal, '&:hover' : {bgcolor : theme.palette.secondary.purple} }} */