import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../../alerts/Toast';

function* UserRegister() {
    yield takeLatest('USER_REGISTER', Register);
}

function* Register(action) {
    
    try {
        let responseUser =""
        if (action.admin) { // if the request is coming from the admin page
            
            // console.log('in RegisterUser saga, admin block');

            responseUser = yield axios.post(`api/Account/CreateUser`, action.payload);
            if (responseUser.status === 200) {
                const info = JSON.stringify(responseUser.data)
                sessionStorage.setItem('currentUser', info);

                Toast.fire({
                    icon: 'success',
                    title: `Successfully registered account, next please verify email address.`,
                    timer: 6000,
                });
            }

        } else if (!action.payload.CaptchaResponse && !action.payload.BypassCaptcha) { // if captcha is not completed, and bypass = false
            
            // console.log('in RegisterUser saga, else if block')

            Toast.fire({
                icon: 'error',
                title: `Please complete the captcha to register.`,
                timer: 6000,
            });
        
        } else { //if the request comes from the regular user register page we don't need to store the info

            // console.log('in RegisterUser saga ', action.payload);

            yield axios.post(`api/Account/CreateUser`, action.payload );
            
            Toast.fire({
                icon: 'success',
                title: `Successfully registered account, next please verify email address.`,
                timer: 6000,
            });

            action.navigate('/login');
        }

    } catch (error) {

        console.log('error in register user saga.', error);

        Toast.fire({
            icon: 'error',
            title: `Error with registration`,
        });
    }
}

export default UserRegister;


