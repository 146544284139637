const getToken = () => {
    let info = window.sessionStorage.getItem('auth');
    if (info) {
        let token = JSON.parse(info);
        return token.token;
    } else {
        return
    }
};

export default getToken;