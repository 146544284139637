import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../../alerts/Toast';
import getToken from '../../../components/Tokens/GetToken';

function* HomeDataSaga() {
    yield takeLatest('FETCH_HOME_DATA', FetchHomeData);
}

function* FetchHomeData(action) {
    try {
        let token = getToken();

        let retval = yield axios.get(`api/HomeData/GetHomeData`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        // console.log("retval for homedata", retval.data);
        yield put({ type: "SET_HOME_DATA", payload: retval.data });

        let dayCount = yield axios.get(`api/HomeData/GetReadingsDayCount`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });   
        // console.log("response for day readings", dayCount);
        yield put({ type: "SET_HOME_DAY", payload: dayCount.data });

        let monthCount = yield axios.get(`api/HomeData/GetReadingsMonthCount`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });   
        // console.log("response for month readings", monthCount);
        yield put({ type: "SET_HOME_MONTH", payload: monthCount.data });

        let totalCount = yield axios.get(`api/HomeData/GetTotalReadingsCount`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } }); 
        // console.log("response for total readings", totalCount);
        yield put({ type: "SET_HOME_TOTAL", payload: totalCount.data });

        let activity = yield axios.get(`api/HomeData/GetActiveDeviceCount`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } }); 
        console.log("response for activity", activity.data);
        yield put({ type: "SET_ACTIVITY", payload: activity.data });

    } catch (error) {
        console.log('error in FetchHomeData saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with Grabbing Home Info: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}//GetReadingsDayCount-GetReadingsMonthCount

export default HomeDataSaga;