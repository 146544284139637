import React from 'react';
import { Box } from '@mui/material';
import Dashboard from './Dashboard.js';

const Home = () => {
    return (
        <Box
            style={{ height: '100%', minHeight: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "1%", width: "100%"}}
        >
            
            <Dashboard />
            
        </Box>
    )
}

export default Home;