import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../../alerts/Toast';

function* UserConfirmEmail() {
    yield takeLatest('CONFIRM_EMAIL', ConfirmEmail);
}

function clearToken() {
    sessionStorage.removeItem("auth");
    window.location.reload();
}

function* ConfirmEmail(action) {
    try {
        console.log("HERE",action.payload)
        const confirm = yield axios.get(`/api/Account/ConfirmEmail/${action.payload}`);
        if (confirm.status === 200) {
            Toast.fire({
                icon: 'success',
                title: `Email confirmed. Please re-login to continue.`,
                delay: 5000,
            });
            setTimeout(clearToken, 4500);
            action.navigate('/login');
        }

    } catch (error) {
        console.log(error)
    }
}

export default UserConfirmEmail;