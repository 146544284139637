import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../../alerts/Toast';
import getToken from '../../../components/Tokens/GetToken';

function* DeleteJsonFileSaga() {
    yield takeLatest('DELETE_JSON_FILE', DeleteJsonFile);
}

function* DeleteJsonFile(action) {
    console.log("Saga", action)
    try {
        let token = getToken();

        let delFile = yield axios.delete(`api/JsonFile/DeleteJsonFile/${action.payload}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        // yield put({ type: "SET_HOME_DATA", payload: retval.data });
        // fileDownload(fileJson, 'SystemLogData.json');
        if (delFile.status === 200) {
            Toast.fire({
                icon: 'success',
                title: `File Deleted.`,
            });
            yield put({type: "FETCH_EVERYTHING"})
        }
    } catch (error) {
        console.log('error in DeleteJsonFile saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with Deleting Json File: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

export default DeleteJsonFileSaga;