import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import NavBar from './NavBar'
import TopBar from './TopBar';
import { styled } from '@mui/system';
import theme from '../theme';
import LoginView from '../views/LoginRegister/Login';
import RegisterView from '../views/LoginRegister/Register';
import Home from '../views/Home';
import FleetManagementView from '../views/FleetView/FleetViewIndex';
import DeviceView from '../views/DevicesView/DevicesViewIndex';
import Configuration from '../views/Configuration/ConfigurationIndex';
import Admin from '../views/AdminPage/AdminIndex';
import AccountPage from '../views/Account/Account';
import DashboardIndex from '../views/Dashboard/DashboardIndex'
import PageNotFoundIndex from '../views/404/PageNotFoundIndex';
import FileUploadPage from '../views/FileUploadPage/FileUploadPage';
import { Dashboard } from '@mui/icons-material';
import TFA from '../views/LoginRegister/TFA';

const Paper = styled('div')({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
})

const Wrapper = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64
})

const ContentContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
})

const Content = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  borderLeft: '1px solid rgb(0,0,0,0.12)'
})

const MainLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const authenticated = useSelector((state) => state.UserReducer.isAuthenticated)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
      let auth = sessionStorage.getItem('auth')
      if (auth) {
          let info = JSON.parse(auth)
          dispatch({type: "IS_AUTHENTICATED", payload: info})
          dispatch({type: "USER_DATA", payload: info.id})
          // dispatch({type: "USER_TOKEN", payload: info.token})
      }
  },[])

  React.useEffect(() => {
      if (window.location.pathname === '/logout') {
        sessionStorage.removeItem('auth')
        dispatch({type: 'IS_AUTHENTICATED', payload: false})
        dispatch({type: 'LOGGED_OUT', payload: false})
        navigate('/login')
      }
  },[window.location.pathname])

  function PrivateRoute({ children }) {
      const auth = authenticated;
      return auth ? children : <Navigate to="/login" />;
  }


  return (
    <Paper>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      {/* <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
        /> */}
      <Wrapper>
        <ContentContainer>
          <Content>

            {authenticated && window.location.pathname === '/login' ? <Navigate to='/'/> : ''}

            <Routes>

              <Route path='/login' element={ <LoginView /> } />
              <Route path='/2FA' element={<TFA/>}/>
              <Route path='/register' element={ <RegisterView /> } />
              <Route path='/logout'/>

              <Route path='/' element={<PrivateRoute> <Home /></PrivateRoute>} />
              <Route path='/configuration' element={<PrivateRoute> <Configuration /></PrivateRoute>} />
              <Route path='/fleet' element={<PrivateRoute> <FleetManagementView /></PrivateRoute>} />
              <Route path='/deviceView' element={<PrivateRoute> <DeviceView /></PrivateRoute>} />
              <Route path='/admin' element={<PrivateRoute> <Admin /></PrivateRoute>} />
              <Route path='/account' element={<PrivateRoute> <AccountPage /></PrivateRoute>} />
              <Route path='/dashboard' element={<PrivateRoute> <DashboardIndex /></PrivateRoute>} />
              <Route path='/fileUpload' element={<PrivateRoute> <FileUploadPage/></PrivateRoute>} />
              <Route path='*' element={ <PageNotFoundIndex /> } />

            </Routes>

          </Content>
        </ContentContainer>
      </Wrapper>
    </Paper>
  );
};

export default MainLayout;