import React from 'react';
import { Box } from '@mui/material';
import LandingPage from './LandingPage';

import { ReactComponent as Image1 } from '../../components/Home-Icon_Icons-neuro.svg';
import { ReactComponent as Image2 } from '../../components/Web-icon_Icons-peripheral.svg';
import { ReactComponent as Image3 } from '../../components/Web-Icons_Icons-VR-120x100-2.svg';

const LandingPageIndex = () => {
    return (
        <Box width={ '100%' }>
            <Box style={{ marginTop: "-20px", width: '100%', display: "flex", flexWrap: 'nowrap', }}>
                {/* <img src={'../../components/Home-Icon_Icons-nero.svg'} style={{ flex: 1, height: '180px' }} />
                <img src={'../../components/Web-icon_Icons-peripheral.svg'} style={{ flex: 1, height: '180px' }} />
                <img src={'../../components/Web-icon_Icons-peripheral.svg'} style={{ flex: 1, height: '180px' }} /> */}
                <Image1 style={{ flex: 1, height: '180px' }} />
                <Image2 style={{ flex: 1, height: '180px' }} />
                <Image3 style={{ flex: 1, height: '180px' }} />
            </Box>
            <Box
                style={{ paddingTop: "-200px", height: '100%', minHeight: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%" }}
            >
                <LandingPage />
            </Box>

        </Box>
    )
}

export default LandingPageIndex;