import React from 'react';
import {
    Button,
    Stepper,
    Step,
    StepLabel,
    Typography,
    TextField,
    Breadcrumbs
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector, connect } from 'react-redux';
import { styled } from '@mui/system';
import theme from '../../../theme';
import Toast from '../../../alerts/Toast';
import getToken from '../../../components/Tokens/GetToken';

const Paper = styled('div')({
    position: 'absolute',
    width: '80%',
    height: '80%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column'
})

const Input = styled(TextField)({
    margin: '10px'
})

function getSteps() {
    return ['Username and Email', 'Select user group', 'Select user access', 'Overview'];
}


function GroupPermission(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [permission, setPermission] = React.useState({ name: 'Read' });
    const [user, setUser] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [pass, setPass] = React.useState(null);
    const [confirm, setConfirm] = React.useState(null);
    const groups = useSelector((state) => state.GroupReducer.AllGroups);
    const [group, setGroup] = React.useState(groups[0]);
    const roles = [
        { name: 'Read' },
        { name: "Write" },
        { name: 'Admin' }
    ]
    const token = getToken();
    const steps = getSteps();

    const handleNext = () => {
        if (activeStep === 0 && user === null) {
            return Toast.fire({
                icon: 'error',
                title: 'Please enter username'
            })
        }
        if (activeStep === 0 && email === null) {
            return Toast.fire({
                icon: 'error',
                title: 'Please enter email'
            })
        }
        if (activeStep === 0 && pass === null) {
            return Toast.fire({
                icon: 'error',
                title: 'Please enter password'
            })
        }
        if (activeStep === 0 && pass.length < 6) {
            return Toast.fire({
                icon: 'error',
                title: 'Please enter password with at least 6 characters'
            })
        }
        if (activeStep === 0 && pass !== confirm) {
            return Toast.fire({
                icon: 'error',
                title: 'Passwords do not match'
            })
        }
        if (activeStep === 0 && pass.search(/[A-Z]/) < 0) {
            return Toast.fire({
                icon: 'error',
                title: 'Password must have contain at least one capital letter'
            })
        } else if (activeStep === 0 && pass.search(/[0-9]/) < 0) {
            return Toast.fire({
                icon: 'error',
                title: 'Password must have contain at least one number'
            })
        } else if (activeStep === 0 && pass.search(/[^a-zA-Z0-9\s]/) < 0) {
            return Toast.fire({
                icon: 'error',
                title: 'Password must have contain at least one special character'
            })
        }
        if (activeStep === 1 && group.length === 0) {
            return Toast.fire({
                icon: 'error',
                title: 'Please select group'
            })
        }
        else if (activeStep === 2 && permission.length === 0) {
            return Toast.fire({
                icon: 'error',
                title: 'Please select permissions'
            })
        }
        if (activeStep === 3) {
            // console.log('active 3 ', group);
            // console.log('active 3 ', permission);

            let item = {
                EmailAddress: email,
                UserName: user,
                PasswordHash: pass,
                GroupId: group.id,
                RoleName: permission.name,
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
            // console.log('item', item);
            fetch('/api/Account/AdminCreateNewUser', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(item)
            })
                .then(response => {
                    if (response.status === 200) {
                        props.dispatch({ type: "ADMIN_GRAB_ALL_USERS" })
                    }
                })
                .then(() => Toast.fire({
                    icon: 'success',
                    title: 'New User Created'
                }))
                .catch(error => {
                    console.error('Unable to add User.', error)
                    Toast.fire({
                        icon: 'error',
                        title: JSON.stringify(error)
                    })
                });
        }
        else {
            return setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setGroup([]);
        setPermission([]);
        setEmail(null);
        setUser(null);
        setPass(null);
        setConfirm(null);
        setActiveStep(0);
    };
    function getStepContent(stepIndex) {
        let display = ''
        switch (stepIndex) {
            case 0:
                display = (
                    <div
                        style={{ textAlign: 'center' }}
                    >
                        <Input
                            variant="outlined"
                            label="Username"
                            onChange={(event) => setUser(event.target.value)}
                        />
                        <Input
                            variant="outlined"
                            label="Email"
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        <br />
                        <Input
                            variant="outlined"
                            label="Password"
                            type="password"
                            onChange={(event) => setPass(event.target.value)}
                        />
                        <Input
                            variant="outlined"
                            label="Confirm Password"
                            type="password"
                            onChange={(event) => setConfirm(event.target.value)}
                        />
                        <br />
                        <h3 style={{ color: 'red' }}>Password needs at least six characters with: <br /> at least one non-aplhanumeric, one capital, and one number.</h3>
                    </div>
                )
                return display;
            case 1:
                display = (
                    <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Autocomplete
                            // multiple
                            id="combo-box"
                            options={groups}
                            value={group}
                            onChange={(event, newValue) => {
                                setGroup(newValue)
                            }}
                            getOptionLabel={(option) => option.name}
                            style={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Select Group" variant="outlined" />}
                        />
                    </div>
                )
                return display;
            case 2:
                display = ''
                display = (
                    <div>
                        {/* {group.map((x) => {
                    return ( */}
                        <div>
                            <Breadcrumbs>
                                <Typography>{group.name}/</Typography>
                            </Breadcrumbs>
                        </div>
                        {/* )
                    })} */}
                        <Autocomplete
                            // multiple
                            id="checkboxes-tags"
                            value={permission}
                            options={roles}
                            onChange={(event, newValue) => {
                                setPermission(newValue)
                            }}
                            getOptionLabel={(option) => option.name}
                            style={{ width: 500 }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Access" placeholder="Access" />
                            )}
                        />
                    </div>
                )
                return display;
            case 3:
                display = (
                    <div
                        style={{ textAlign: 'center', width: '100%' }}
                    >
                        <h2
                            style={{ marginBottom: '20px' }}
                        >
                            User Confirmation
                        </h2>
                        <div
                            style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}
                        >
                            <div>
                                Username: {user}
                                <br />
                                Email: {email}
                            </div>
                            <div>
                                Groups:
                                <ul>
                                    <li>{group.name}</li>
                                </ul>
                            </div>
                            <div>
                                Permissions:
                                <ul>
                                    <li>{permission.name}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )
                return display;
            default:
                return 'Unknown stepIndex';
        }
    }

    const body = (
        <Paper>
            <Stepper
                style={{ padding: '0px' }}
                activeStep={activeStep}
                alternativeLabel
            >
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div style={{ width: '100%' }}>
                {activeStep === steps.length ? (
                    <div style={{ textAlign: 'center', marginTop: '15px' }}>
                        <Typography
                            sx={{
                                marginTop: theme.spacing(1),
                                marginBottom: theme.spacing(1)
                            }}
                        >
                            All steps completed
                        </Typography>
                        <Button
                            onClick={handleReset}
                        >
                            Reset
                        </Button>
                    </div>
                ) : (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <div style={{ marginTop: '15px', marginBottom: '15px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {getStepContent(activeStep)}
                        </div>
                        <div
                            style={{ marginTop: '15px', textAlign: 'center' }}
                        >
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ marginRight: theme.spacing(1) }}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                // color="primary"
                                sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }}
                                onClick={handleNext}
                            >
                                {activeStep === steps.length - 1 ? 'Accept' : 'Next'}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Paper>
    );

    return body;
}


export default connect()(GroupPermission);
