import React, { useEffect } from 'react';
import {
    Container,
    Box,
    Button,
    Typography,
    IconButton,
    FormControl,
    Select,
    MenuItem,
    LinearProgress,
    Checkbox
} from '@mui/material';
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import theme from '../../theme';
import moment from "moment";
import { Edit, Plus, Save } from 'react-feather';
import axios from 'axios';
import Toast from '../../alerts/Toast';
import Swal from 'sweetalert2';
import SplitToken from '../../components/Tokens/SplitToken';
import Page from '../../components/Page'
import Popup from '../../components/Popup';
import DeviceConfig from './DeviceConfig';
import getToken from '../../components/Tokens/GetToken';
import SetNewLocation from './SetNewLocation';
import CreateDevice from "./CreateDevice";
import { useRef } from 'react';

const MyPage = styled(Page)({
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
})

const Configuration = (props) => {
    const token = getToken();
    const user = SplitToken();
    // let user = {};
    // user.isAdmin = true; // remove later
    const navigate = useNavigate();
    const [configOpen, setConfigOpen] = React.useState(false);
    const [configDevice, setConfigDevice] = React.useState({});
    const [newLocationOpen, setNewLocationOpen] = React.useState(false);
    const [deviceLocation, setDeviceLocation] = React.useState({});
    const [filter, setFilter] = React.useState('');
    const [edit, setEdit] = React.useState(false);
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const devices = useSelector((state) => state.DeviceReducers.AllDevices);
    const firmware = useSelector((state) => state.FirmwareReducer.AllFirmware);
    const hardware = useSelector((state) => state.HardwareReducer.AllHardware);
    const groups = useSelector((state) => state.GroupReducer.AllGroups);

    const [open, setOpen] = React.useState(false);
    const [rows, setRows] = React.useState(devices);
    const dispatch = useDispatch();
    // console.log("***firmware***", firmware)
    // console.log('rows ', rows);
    // console.log('can config ', canConfig);
    // console.log('hardware', hardware);
    // console.log('groups', groups);
    // console.log('devices ', devices);
    // console.log('window.google in index', window.google);
    // console.log('#$ user ', user.isAdmin);

    // if (!token) {
    //     navigate("/login")
    // }
    let role = useRef(false);
    let editRole = useRef(false); // false can see, true cannot
    // useEffect(() => {
    //     if (!token){
    //       navigate('/login');
    //     } else {
    //         if (user.isAdmin) {
    //             role.current = false;
    //         }
    //         if (user.isAdmin) {
    //             editRole.current = false; // to display edit buttons
    //         }
    //     }
    // }, [])

    function CustomLoadingOverlay() {
        return (
            <GridOverlay>
                <div
                    style={{ position: 'absolute', top: 0, width: '100%' }}
                >
                    <LinearProgress />
                </div>
            </GridOverlay>
        )
    };

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150, editable: edit },
        // { field: 'serialNumber', headerName: 'Serial Number', flex: 1, minWidth: 100, editable: edit },
        { field: 'imei', headerName: 'IMEI', flex: 1, minWidth: 100 },
        {
            field: 'groupId', headerName: 'Group', flex: 1, minWidth: 100, valueGetter: (params) => {
                const machineGroup = groups.find((group) => group.id === params.row.groupId)
                if (machineGroup === undefined) {
                    return ''
                }
                else {
                    return machineGroup.name
                }
            }, renderEditCell: (params) => {
                return (
                    <FormControl fullWidth>
                        <Select
                            defaultValue={params.row.canGroupId}
                            onChange={(event) => {
                                params.row.groupId = event.target.value
                                Object.values(editRowsModel)[0].groupId.value = event.target.value
                            }}
                        >
                            {groups.map((x, i) => {
                                return <MenuItem key={i} value={x.id}>{x.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                )
            }, editable: edit
        },
        { field: 'updated', headerName: 'Last Check In', flex: 1, valueGetter: (params) => params.row.updated ? moment.utc(params.row.updated).local().format('M/D/YY, HH:mm') : "" },
        {
            field: 'hardwareVersionId', headerName: 'Hardware', flex: 1, valueGetter: (params) => {
                if (params.row.hardwareVersionId && hardware.length > 0) {
                    const deviceHardware = hardware.find((version) => version.id === params.row.hardwareVersionId)
                    // console.log('device hardware version', deviceHardware);
                    return deviceHardware.name
                } else {
                    return "None Assigned"
                }
            }, renderEditCell: (params) => {
                return (
                    <FormControl fullWidth>
                        <Select
                            defaultValue={params.row.hardware}
                            onChange={(event) => {
                                params.row.hardware = event.target.value
                                Object.values(editRowsModel)[0].hardwareVersionId.value = event.target.value
                            }}
                        >
                            {hardware.map((x, i) => {
                                return <MenuItem key={i} value={x.id}>{x.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                )
            },
            editable: edit
        },
        {
            field: 'firmwareVersionId', headerName: 'Firmware', flex: 1, minWidth: 150, valueGetter: (params) => {
                // console.log('params row for firm', params.row);
                if (params.row.firmwareVersionId && firmware.length) {
                    const deviceFirmware = firmware.find((version) => version.id === params.row.firmwareVersionId)
                    // console.log('device firmware', deviceFirmware);
                    if (deviceFirmware.name) {
                        return deviceFirmware.name
                    }
                } else {
                    return "None Assigned"
                }
            }, renderEditCell: (params) => {
                return (
                    <FormControl fullWidth>
                        <Select
                            defaultValue={params.row.firmware}
                            onChange={(event) => {
                                params.row.firmwareVersionId = event.target.value
                                Object.values(editRowsModel)[0].firmwareVersionId.value = event.target.value
                            }}
                        >
                            {firmware.map((x, i) => {
                                return <MenuItem key={i} value={x.id}>{x.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                )
            },
            editable: edit
        },
        {
            field: 'active', headerName: 'Active', flex: 1, minWidth: 150, editable: edit, renderEditCell: (params) => {
                return (
                    <FormControl fullWidth>
                        <>{params.row.active}</>
                        <Checkbox checked={params.row.active} text={params.row.active} onChange={(event) => {
                            console.log("params check", params);
                            console.log("event check", event.target.checked);
                            params.row.active = event.target.checked;
                            Object.values(editRowsModel)[0].active.value = event.target.checked;
                        }}
                        /> 
                    </FormControl>
                )
            },
        },
        {
            field: 'deviceConfig', headerName: 'Configuration', flex: 1, minWidth: 150, renderCell: (params) => (
                <Button
                    variant="contained"
                    // color="primary"
                    sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }}
                    onClick={() => {
                        handleConfigOpen(params.row)
                        dispatch({ type: "SET_CONFIG_STATUS", payload: 0 })
                    }}
                >
                    View
                </Button>
            )
        },
        {
            field: 'setLoaction', headerName: 'Set Location', flex: 1, minWidth: 150, renderCell: (params) => (
                <Button
                    variant="contained"
                    // color="primary"
                    sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }}
                    onClick={() => handleLocationOpen(params.row)}
                >
                    Set Location
                </Button>
            )
        },
    ]

    const handleCommit = () => {
        const objId = Object.keys(editRowsModel)[0];
        console.log("obj Id:", objId)
        const obj = Object.values(editRowsModel)[0];
        console.log("obj:", obj)
        let key = Object.keys(obj)[0]
        console.log("KEY", key)
        let target = '';
        rows.map((x) => {
            if (x.id === objId) {
                target = x;
            }
        })
        //console.log("TARGET Cab", target.cab)
        switch (key) {
            case "name":
                // update device name        
                // console.log("name", obj.name.value);       
                if (obj.name !== undefined) {
                    return axios.put(`/api/Device/NameChange/${objId}/${obj.name.value}`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
                        .then((response) => response.status == 200 ? Toast.fire({ icon: 'success', title: 'Name Updated' }) : Toast.fire({ icon: 'error', title: 'update failed' }))
                        .then(() => dispatch({ type: 'UPDATE_DEVICELIST', id: objId, device: devices }))
                }
            case "hardwareVersionId":
                // update device hardware
                if (obj.hardwareVersionId !== undefined) {
                    return axios.put(`/api/Device/HardwareUpdate/${objId}/${obj.hardwareVersionId.value}`, {}, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
                        .then(() => Toast.fire({
                            icon: 'success',
                            title: 'Hardware Version Updated'
                        }))
                        .catch((error) => {
                            Toast.fire({
                                icon: 'error',
                                title: JSON.stringify(error)
                            })
                        })
                }
            case "firmwareVersionId":
                // update device firmwar
                if (obj.firmwareVersionId !== undefined) {
                    return axios.put(`/api/Device/FirmwareUpdate/${objId}/${obj.firmwareVersionId.value}`, {}, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
                        .then(() => Toast.fire({
                            icon: 'success',
                            title: 'Firmware Version Updated'
                        }))
                        .catch((error) => {
                            Toast.fire({
                                icon: 'error',
                                title: JSON.stringify(error)
                            })
                        })
                }
            case "groupId":
                // update device machine group
                if (obj.groupId !== undefined) {
                    return axios.put(`/api/Device/GroupUpdate/${objId}/${obj.groupId.value}`, {}, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
                        .then(() => {
                            Toast.fire({
                                icon: 'success',
                                title: 'Group Updated'
                            })
                        })
                        .then(() => dispatch({ type: 'UPDATE_DEVICELIST', id: objId, device: devices }))
                        .catch((error) => {
                            Toast.fire({
                                icon: 'error',
                                title: JSON.stringify(error)
                            })
                        })
                }
            case "active":
                // update active property
                if (obj.active !== undefined) {
                    return axios.put(`/api/Device/ActiveUpdate/${objId}/${obj.active.value}`, {}, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
                        .then(() => Toast.fire({
                            icon: 'success',
                            title: 'Activity Updated'
                        }))
                        .catch((error) => {
                            Toast.fire({
                                icon: 'error',
                                title: JSON.stringify(error)
                            })
                        })
                }
            // case "serialNumber":
            //     // update Signum Serial Number in Device DB Table 
            //     if (obj.serialNumber !== undefined) {
            //         console.log('in serial number');
            //         return axios.put(`/api/Device/UpdateSerial/${objId}/${obj.serialNumber.value}`, {}, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
            //             .then(() => {
            //                 Toast.fire({
            //                     icon: 'success',
            //                     title: 'Serial Number Updated'
            //                 })
            //             })
            //             .then(() => dispatch({ type: 'UPDATE_DEVICELIST', id: objId, device: devices }))
            //             .catch((error) => {
            //                 Toast.fire({
            //                     icon: 'error',
            //                     title: JSON.stringify(error)
            //                 })
            //             })
            //     }
            default:
                break;
        }
    }

    const handleEditRowsModelChange = React.useCallback((model) => {
        //console.log("****model change", model)
        setEditRowsModel(model);
    }, []);

    // const handleChange = (event, value) => {
    //     setPage(value)
    // }

    const handleConfigOpen = (device) => {
        // console.log('device in config open', device);
        setConfigDevice(device);
        dispatch({
            type: 'FETCH_DEVICE_CONFIG',
            payload: device.id
        })
        setConfigOpen(true)
    };

    const handleLocationOpen = (device) => {
        // console.log('device in location open', device);
        setDeviceLocation(device);

        setNewLocationOpen(true)
    };


    let handleClose = () => { // for Device config
        return setConfigOpen(false)
    }

    let handleCreateClose = () => { // for the add device functionality
        return setOpen(false)
    }

    const locationClose = () => {
        setNewLocationOpen(false);
        window.google = undefined;
    };

    return (
        <MyPage title="Configuration">
            <Container maxWidth={false}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant='h2'>
                        Configuration
                    </Typography>
                    {editRole.current ? "" :
                        <IconButton onClick={() => setEdit(!edit)}>
                            <Edit />
                        </IconButton>
                    }
                    {edit ?
                        <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                            <Typography textAlign={"center"} color={"red"} variant='h4'>
                                Editing Mode
                            </Typography>
                            <Typography textAlign={"center"} variant='body2'>
                                Double click to enter row, hit enter or click out to save changes. <br /> Press Esc to cancel editing a field.
                            </Typography>
                        </Box>
                        :
                        <></>
                    }
                    {editRole.current ? "" :
                        <span>
                            {/* <Button style={{ marginRight: 10}} disabled={role} startIcon={<Plus/>} onClick={() => setCanBulk(!canBulk)} variant="contained" color="primary">
                                Bulk CAN Group Update
                            </Button>
                            <Button style={{ marginLeft: 10}} disabled={role} startIcon={<Plus/>} onClick={() => setBulk(!bulk)} variant="contained" color="primary">
                                Bulk Firmware Update
                            </Button> */}
                            <Button sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }} style={{ marginLeft: 10 }} disabled={role.current} startIcon={<Plus />} onClick={() => setOpen(!open)} variant="contained" >
                                Add Device
                            </Button>
                        </span>
                    }
                </Box>
                {devices.length > 0 /* && firmware.length > 0 && hardware.length > 0 && groups.length > 0 */ ?
                    <Box mt={3} sx={{ height: 600, width: '100%', paddingRight: 3 }}>
                        <DataGrid
                            editMode="cell"
                            columns={columns}
                            rows={devices}
                            // loading={loading}
                            onCellEditCommit={handleCommit}
                            editRowsModel={editRowsModel}
                            onEditRowsModelChange={handleEditRowsModelChange}
                            components={{
                                LoadingOverlay: CustomLoadingOverlay
                            }}
                        />
                    </Box>
                    : ""}
                <Popup open={configOpen} close={handleClose}>
                    <DeviceConfig device={configDevice} close={handleClose} />
                </Popup>
                <Popup open={newLocationOpen} close={() => locationClose()}>
                    <SetNewLocation device={deviceLocation} close={() => locationClose()} />
                </Popup>
                {/* <Popup open={bulk} close={() => setBulk(false)}>
                    <Bulk bulk={bulk} setBulk={setBulk} close={() => setBulk(false)}/>
                </Popup> */}
                <Popup open={open} close={handleCreateClose}>
                    <CreateDevice firmware={firmware} hardware={hardware} close={handleCreateClose} groups={groups} />
                </Popup>
            </Container>
        </MyPage>
    );
};

export default Configuration;
