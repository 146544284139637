import { combineReducers } from 'redux';

const AllFirmware = (state = [], action) => {
    switch (action.type) {
        case 'SET_FIRMWARE_DATA':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

export default combineReducers({
    AllFirmware,
});