import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import moment from 'moment';
import getToken from '../../../components/Tokens/GetToken';

// Grabs devices for selected group in widget creation
function* GrabDevice(action){
    try {
        // console.log('1 grab device action: ', action);
        let token = getToken();

        yield put({
            type: "DEVICE_LIST_LOADING",
            payload: true
        })
        let retval = yield axios.get('/api/MachineService/MachineDropdown/' + action.payload.id, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
        // let retval = yield axios.get('/api/Device/GetMinimalDataForAllDescendantsForGroupId/' + (action.payload.parentGroupId ? action.payload.parentGroupId : action.payload.id), {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
        let deviceArray = retval.data;
        // console.log('2 device array', deviceArray);
        yield put({
            type: "DEVICE_LIST_LOADING",
            payload: false
        })

        yield put ({
            type: "SET_DASH_DEVICES",
            payload: deviceArray
        });
    } catch (error) {
        console.log(`error with grabbing devices for selected group: ${error}`)
    }
};

// Grabs the different datapoints in mongo for selected device for widget creation
function* GrabDataPoints(action){
    try {
        let token = getToken();

        let pointsArray = [];
        let index = action.payload.length - 1

        const points = yield axios.get(`/api/DeviceData/type/${action.payload[index].Id}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

        if( points.status === 200 ){
            pointsArray.push(points.data)
        };

        yield put ({
            type: "SET_DASH_DATA_POINTS",
            payload: pointsArray
        });
    } catch (error) {
        console.log(`error with grabbing data points for device: ${error}`);
    }
}

// Starts the chart making process. Gets data in a format the chart library can use
function* ChartDataCreator(action){
    try {
        let token = getToken();

        yield put({
            type: "SET_CHART_LOADING",
            payload: true
        });

        const dashboardItem = yield axios.get(`/api/Dashboard/${action.payload}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

        let colorArray = ['#00B3E6','#0e5e8a','#33DAFF',
      '#4D80CC','#4DB3FF', '#40F0E5', '#99FF99','#FF6633', '#B34D4D',
      '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
      '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
      '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
      '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
      '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
      '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
      '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF', '#FFFF99']

        let allData = []
        let index = 0

        for (const chart of dashboardItem.data) {
            const Id = chart.id
            var end = chart.end;
            var start = chart.start;
            const timeline = chart.timeline;
            const name = chart.name;
            const type = chart.type;
            let newData = [];
            let unit = '';
            
            //timeline :
            // {
            //     0: Past 3 days,
            //     1: Past Month,
            //     2: Past 3 Months,
            //     3: Past 6 Months,
            //     4: Custom ( use end and start ) 
            // }

            switch (timeline) {

                case 0:
                    end = moment().add(6, 'hours').format();
                    start = moment().subtract(3, 'days').format();
                    break;

                case 1:
                    end = moment().add(6, 'hours').format();
                    start = moment().subtract(1, "month").startOf('month').format();
                    break;

                case 2:
                    end = moment().add(6, 'hours').format();
                    start = moment().subtract(3, "months").startOf('month').format();
                    break;

                case 3:
                    end = moment().add(6, 'hours').format();
                    start = moment().subtract(6, "months").startOf('month').format();
                    break;

                case 4:
                    break;

                case 5:
                    break;
                default:
                    break;
            };

            switch (type) {
                case 'line': // Creates data stucture for line chart
                    unit = '';
                    for (const x of chart.devices) {
                        const device = yield axios.get(`/api/Device/Name/${x}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

                        const deviceName = device.data.name

                        for (const i of chart.dataPoints){
                            let label = i

                            if (chart.devices.length > 1){
                                label = deviceName
                            }

                            let chartData = {
                                label: label,
                                fill: false,
                                borderColor: colorArray[index],
                                backgroundColor: colorArray[index],
                                data: [],
                                barPercentage: 0.5,
                                barThickness: 6,
                                maxBarThinkness: 8,
                                minBarLength: 2,
                                borderWidth: 2
                            }
                            const data = yield axios.put(`/api/DeviceData/chart`,{
                                id: String(x),
                                start: String(start),
                                end: String(end),
                                dataPoint: String(i)
                            },
                            {
                                headers: {
                                  Authorization: 'Bearer ' + token
                                }
                            });
                            // Start of bringing units in old data will not work since naming changed when we switched to dbc files
                            yield axios.get(`/api/CanSPN/Name/${i}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}})
                            .then((response) => unit = response.data.unit)
                            .catch((error) => console.log(`Error getting unit: ${error}`));
                            // end units
                            data.data.map((u) => {
                                return chartData.data.push({ x: moment.utc(u.created).local().format(), y: u.value.toFixed(2)});
                            })

                            newData.push(chartData)
                            index ++
                        }
                        index ++
                    }
                    newData.layout = {x: chart.x, y: chart.y, w: chart.w, h: chart.h}

                    allData.push({
                        title: name,
                        id: Id,
                        datasets: newData,
                        type: type,
                        unit: unit,
                        layout: {x: chart.x, y: chart.y, w: chart.w, h: chart.h}
                    });
                    break;

                case 'bar': // Creates dataset for bar chart
                    unit = '';
                    for (const x of chart.devices) {
                        const device = yield axios.get(`/api/Device/Name/${x}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

                        const deviceName = device.data.name;

                        for (const i of chart.dataPoints){
                            let label = i

                            if (chart.devices.length > 1){
                                label = deviceName
                            };

                            let chartData = {
                                label: label,
                                fill: false,
                                borderColor: colorArray[index],
                                backgroundColor: colorArray[index],
                                data: [],
                                barPercentage: 0.5,
                                barThickness: 6,
                                maxBarThinkness: 8,
                                minBarLength: 2,
                                borderWidth: 2
                            }
                            const data = yield axios.put(`/api/DeviceData/chart`,{
                                id: String(x),
                                start: String(start),
                                end: String(end),
                                dataPoint: String(i)
                            },
                            {
                                headers: {
                                  'Authorization': 'Bearer ' + token
                                }
                            })

                            // Start of bringing units in old data will not work since naming changed when we switched to dbc files
                            yield axios.get(`/api/CanSPN/Name/${i}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}})
                            .then((response) => unit = response.data.unit)
                            .catch((error) => console.log(`Error getting unit: ${error}`));
                            // end units

                            data.data.map((u) => {
                                return chartData.data.push({ x: moment.utc(u.created).local().format(), y: u.value.toFixed(2) })
                            })

                            newData.push(chartData)
                            index ++
                        }
                        index++
                    }
                    newData.layout = {x: chart.x, y: chart.y, w: chart.w, h: chart.h}

                    allData.push({
                        title: name,
                        id: Id,
                        datasets: newData,
                        type: type,
                        unit: unit,
                        layout: {x: chart.x, y: chart.y, w: chart.w, h: chart.h}
                    });
                    break;

                case 'pie': // Creates data structure for Pie charts currently only supports averages
                    let labels = [];
                    
                    unit = '';

                    let chartData = {
                        id: Id,
                        fill: false,
                        borderColor: [],
                        backgroundColor: [],
                        data: []
                    };

                    for (const x of chart.devices) {
                        const device = yield axios.get(`/api/Device/Name/${x}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

                        const deviceName = device.data.name;

                        for (const i of chart.dataPoints){
                            let label = i;

                            if (chart.devices.length > 1){
                                label = deviceName
                            };

                            labels.push(label);

                            chartData.borderColor.push(colorArray[index]);

                            chartData.backgroundColor.push(colorArray[index]);

                            // Start of bringing units in old data will not work since naming changed when we switched to dbc files
                            yield axios.get(`/api/CanSPN/Name/${i}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}})
                            .then((response) => unit = response.data.unit)
                            .catch((error) => console.log(`Error getting units: ${error}`));
                            // end units

                            const data = yield axios.put(`/api/DeviceData/chart`,{
                                id: String(x),
                                start: String(start),
                                end: String(end),
                                dataPoint: String(i)
                            },
                            {
                                headers: {
                                  'Authorization': 'Bearer ' + token
                                }
                            });

                            let average = 0;

                            data.data.map((u) => {
                                return average += Number(u.value)
                            });

                            average = Number(average) / data.data.length;

                            chartData.data.push(average.toFixed(2));

                            index ++
                        }

                        newData.push(chartData)

                        index ++
                    }
                    newData.layout = {x: chart.x, y: chart.y, w: chart.w, h: chart.h};

                    allData.push({
                        title: name,
                        labels: labels,
                        id: Id,
                        datasets: newData,
                        type: type,
                        unit: unit,
                        layout: {x: chart.x, y: chart.y, w: chart.w, h: chart.h}
                    });

                    break;
                case 'map': // Creates data structure for maps
                    let markers = [];
                    for (const x of chart.devices) {
                        const device = yield axios.get(`/api/Device/GetDevice/Device/${x}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
                        const deviceName = device.data.name;
                        // if( timeline === 5 ) {
                        //     const locations = yield axios.get(`/api/LocationHistory/Single/${x}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
                        //     if (locations.status === 200) {
                        //         markers.push({
                        //             lat: locations.data.latitude,
                        //             lng: locations.data.longitude,
                        //             id: deviceName,
                        //             created: moment.utc(locations.data.created).local().format('lll'),
                        //         });
                        //     }
                        //     else {
                        //         continue;
                        //     }
                        // }
                        // else{
                        //     const locations = yield axios.get(`/api/LocationHistory/${x}/${start}/${end}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});   

                        //     if (locations.data.length <= 0) {
                        //         continue;
                        //     }
                        //     else {
                        //         for ( const y of locations.data ){
                        //             markers.push({
                        //                 lat: y.latitude,
                        //                 lng: y.longitude,
                        //                 id: deviceName,
                        //                 created: moment.utc(y.created).local().format('lll'),
                        //             })
                        //         }
                        //     }
                        // }
                        if (device) {
                            markers.push({
                                lat: device.latitude,
                                lng: device.longitude,
                                id: deviceName,
                                created: moment.utc(device.created).local().format('lll'),
                            })
                        }
                    }
                    if( markers.length > 0 ){
                        allData.push({
                            title: name,
                            id: Id,
                            datasets: markers,
                            center: {lat: markers[0].lat, lng: markers[0].lng},
                            type: type,
                            layout: {x: chart.x, y: chart.y, w: chart.w, h: chart.h}
                        })
                    }
                    else {
                        allData.push({
                            title: name,
                            id: Id,
                            type: 'none',
                            layout: {x: chart.x, y: chart.y, w: chart.w, h: chart.h}
                        })
                    }
                    break;
                case "list": // Gets the data for creating list
                    const list = [];
                    for (const x of chart.devices) {
                        const device = yield axios.get(`/api/Device/GetDevice/Device/${x}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}});
                        // const status = yield axios.get(`/api/DeviceStatus/${device.data.id}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
                        // const data = yield axios.get(`/api/DeviceData/Single/${x}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}});
                        // device.data.data = status.data.created
                        list.push(device.data)
                    }
                    allData.push({
                        title: name,
                        id: Id,
                        type: type,
                        list: list,
                        layout: {x: chart.x, y: chart.y, w: chart.w, h: chart.h}
                    });
                    break;
                case "check": // Gets latest data from each device for checkin status
                    const latestList = [];
                    for (const x of chart.devices) {
                        const data = yield axios.get(`/api/DeviceData/Single/${x}`, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}});
                        if(data.response === 404){
                            continue;
                        }
                        else{
                            latestList.push(...data.data)
                        }
                    };
                    allData.push({
                        title: name,
                        id: Id,
                        type: type,
                        list: latestList,
                        layout: {x: chart.x, y: chart.y, w: chart.w, h: chart.h}
                    });
                    break;
                case "query": // Gets data for custom data query
                    const allDataArray = [];
                    let dataArray = [];
                    for (const x of chart.devices) {
                        dataArray = [];
                        if (chart.dataPoints) {
                            for (const i of chart.dataPoints) {
                                const data = yield axios.put(`/api/DeviceData/chart`, {
                                    id: String(x),
                                    start: String(start),
                                    end: String(end),
                                    dataPoint: String(i)
                                },
                                {
                                    headers: {
                                        'Authorization': `Bearer ${token}`
                                    }
                                })
                                // for bringing in units for dashboard
                                // yield axios.get(`/api/CanSPN/Name/${i}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}})
                                // .then((response) => data.data.unit = response.data.unit)
                                // .catch((error) => console.log(`Error getting unit: ${error}`));
                                // console.log(`================================`)
                                // console.log(data.data)
                                // console.log(`================================`)
                                dataArray.push(...data.data)
                            }
                        }
                        else {
                            const data = yield axios.put(`/api/DeviceData/chart`, {
                                id: String(x),
                                start: String(start),
                                end: String(end),
                                dataPoint: "all"
                            },
                            {
                                headers: {
                                    'Authorization': `Bearer ${token}`
                                }
                            })
                            // For bringing in units for dashboard
                            // const points = yield axios.get(`/api/DeviceData/type/${x}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
                            // console.log(`================================`)
                            // console.log(data.data)
                            // console.log(`================================`)
                            dataArray.push(...data.data)
                        }
                        allDataArray.push(...dataArray)
                    }
                    allData.push({
                        title: name,
                        id: Id,
                        type: type,
                        list: allDataArray,
                        layout: {x: chart.x, y: chart.y, w: chart.w, h: chart.h}
                    });
                default:
                    // If chart type is unknown just don't make a chart.
                    break;
            }
            index = 0
        }
        
        yield put({
            type: "SET_CHART_DATA",
            payload: allData
        });

        yield put({
            type: "SET_CHART_LOADING",
            payload: false
        });
        
    } catch (error) {
        console.log(`error occurred displaying chart data: ${error}`)
    }
}

function* DashboardSaga(){
    yield takeLatest("GET_DASH_DEVICES", GrabDevice);
    yield takeLatest("GET_DASH_POINTS", GrabDataPoints);
    yield takeLatest("GET_CHART_DATA", ChartDataCreator);
};

export default DashboardSaga;