import React, { useState, useEffect } from 'react';
import {
    Box,
} from "@mui/material";
import axios from 'axios';
import { useSelector } from 'react-redux';
// import { GrandCentralDispatch } from '../../../utils/GrandCentralDispatch';
// import authService from '../../../components/api-authorization/AuthorizeService';
import { Tooltip, Typography } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
//import { RFC_2822 } from 'moment';
// import "./ServiceMap.css";
import { Helmet } from 'react-helmet';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import getToken from '../../../components/Tokens/GetToken';
import moment from 'moment';

class Bounds {
    constructor() {
        this.ne = { lat: NaN, lng: NaN };
        this.sw = { lat: NaN, lng: NaN };
    }

    extend(point) {
        this.ne.lat = isNaN(this.ne.lat) ? point.lat : this.ne.lat > point.lat ? this.ne.lat : point.lat;
        this.ne.lng = isNaN(this.ne.lng) ? point.lng : this.ne.lng > point.lng ? this.ne.lng : point.lng;
        this.sw.lat = isNaN(this.sw.lat) ? point.lat : this.sw.lat < point.lat ? this.sw.lat : point.lat;
        this.sw.lng = isNaN(this.sw.lng) ? point.lng : this.sw.lng < point.lng ? this.sw.lng : point.lng;
    }

    getCenterPoint() {
        return {
            lat: (this.ne.lat + this.sw.lat) / 2,
            lng: (this.ne.lng + this.sw.lng) / 2
        }
    }
}

const FleetMap = (props) => {
    const [zoom, setZoom] = useState(10);
    const [points, setPoints] = useState([]);
    const [center, setCenter] = useState({ lng: -96.7898, lat: 46.8772 });
    const [returnStatus, setReturnStatus] = useState(0);
    const selectedDevices = useSelector((state) => state.DeviceReducers.FleetData);
    // const [selectedDevices, setSelectedDevices] = useState();
    const [mapRef, setMapRef] = useState(React.createRef());
    // console.log('select devices', selectedDevices);
    let updated = false;
    const deviceIds = [];
    const infoPoints = [];
    const token = getToken()
    const parent = props.parent
    for (let device of selectedDevices) {
        deviceIds.push(device.id);
    }
    // console.log('device ids', deviceIds);
    selectedDevices.forEach(infoPusher);
    function infoPusher(points) {
        infoPoints.push({
            // Device: points.id,
            Name: points.deviceName,
            Location: `${points.latitude} / ${points.longitude}`,
            LastReported: points.reportDate
        })
    };

    console.log(infoPoints);

    const updatePoints = (devices) => {
        if (selectedDevices.length === 0) {
            return
        }
        setPoints([]);

        // axios.put(`/api/Device/GetPoints`, deviceIds,
        //     {
        //         headers: {
        //             Authorization: 'Bearer ' + token
        //         }
        //     }).then((retval) => {
        //         // console.log('fleet loc retval', retval.data);
        //         if (retval.status === 200) {
        //             setReturnStatus(200);
        //         }

                let newPoints = [];
                let bounds = new Bounds();
                // retval.data.forEach(element => {
                selectedDevices.forEach(element => {
                    console.log('#$ element', element);
                    if (element.latitude && element.longitude) {
                        if (element.lat !== 0 && element.long !== 0) {
                            let point = {
                                id: element.id,
                                lat: element.latitude,
                                lng: element.longitude,
                                name: element.deviceName ? element.deviceName : element.name,
                                // lastReported: moment(element.updated).format('MMM Do YY, HH:mm')
                                lastReported: moment.utc(element.reportDate).local().format('M/D/YY, HH:mm ') + 'UTC'
                            };
                            bounds.extend(point);
                            newPoints.push(point);
                        } else {
                            setPoints([]);
                        }
                    } else {
                        setPoints([])
                    }
                });
                setPoints(newPoints);
                let centerPoint = bounds.getCenterPoint();
                setCenter(centerPoint);
                let west = bounds.ne.lng;
                let east = bounds.sw.lng;
                let north = bounds.ne.lat;
                let south = bounds.sw.lat;
                let angle = east - west < north - south ? east - west : north - south;
                while (angle < 0) {
                    angle += 360;
                }
                let myZoom = Math.round(Math.log(10000 /* <- magic number ¯\_(ツ)_/¯ */ / angle) / Math.LN2);
                setZoom(myZoom);

                initMap();
            // });
    }
    function initMap() {
        if (window.google === undefined) {
            return
        }
        const map = new window.google.maps.Map(document.getElementById("map"), {
            zoom: zoom,
            center: center,
        });
        const infoWindow = new window.google.maps.InfoWindow({
            content: infoPoints.Device,
            disableAutoPan: true,
        });

        // Create an array of alphabetical characters used to label the markers.
        // Add some markers to the map.
        const markers = points.map((position, i) => {
            // const label = labels[i % labels.length];
            const label = `${points.indexOf(position)}`;
            const marker = new window.google.maps.Marker({
                position,
                label,
            });
            // markers can only be keyboard focusable when they have click listeners
            // open info window when marker is clicked
            const windowContent = `Name: ${points[i].name}<br/> Last Reported: ${points[i].lastReported}<br/> Location: ${points[i].lat} / ${points[i].lng}`
            marker.addListener("click", () => {
                infoWindow.setContent(windowContent);
                infoWindow.open(map, marker);
            });
            return marker;
        });

        // Add a marker clusterer to manage the markers.
        new MarkerClusterer({ markers, map });
    }

    window.initMap = initMap;

    useEffect(() => {
        setPoints([]);
        // setSelectedDevices([]);
        if (selectedDevices.length > 0) {
            updatePoints(selectedDevices)
        };

        updatePoints(selectedDevices);
        // setSelectedDevices(selectedFleetDevices);
    }, [selectedDevices]);
    // site for on hover display: https://sudolabs.com/blog/react-google-maps-geodesic-polylines-polygons
    if (selectedDevices.length > 0) {
        // console.log('1st option, render');
        return (
            <Box width={'100%'} height={'100%'} value={points.length}>
                <div id='map' className='map' style={{ height: '100%' }} >
                    <Helmet >
                        <script sync defer src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCfD8SfnjyXBOHK_Ge18erIVoKSV5wnI3c&callback=initMap"></script>
                    </Helmet>
                </div>
            </Box>
        )
    } else if (returnStatus === 200 && points.length === 0) {
        // console.log('2nd option, render');
        return (
            <div><p style={{ color: 'red' }}>No map data for this device between dates.</p></div>
        )
    } else {
        // console.log('3rd option, render');
        return (
            <div style={{justifyContent:"center"}}><p style={{textAlign: 'center'}}>Please select device to load Map data.</p></div>
        )
    }

}
export default FleetMap;