import React from 'react';
import logo from '../logo.svg';
import { Box } from '@mui/material';
import LandingPageIndex from './LandingPage/LandingPageIndex.js';

const Home = () => {
    return (
        <Box
            style={{ height: '100%', minHeight: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "1%", width: "100%"}}
        >
            
            <LandingPageIndex />

        </Box>
    )
}

export default Home;