import React, { useEffect } from "react";
import {
    Box,
    Container,
} from "@mui/material";
import Page from '../../components/Page';
import FleetMap from './FleetMap/Fleetmap';
import FleetStatus from './FleetStatus/FleetStatus';
import theme from "../../theme";
import Toolbar from '../Toolbar/Toolbar'
import { styled } from '@mui/system';
import { useNavigate } from "react-router";
import getToken from "../../components/Tokens/GetToken";
import { useDispatch } from "react-redux";

const MyPage = styled(Page)({
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
})

const FleetManagementView = (props) => {
    const token = getToken();
    const [data, setData] = React.useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
        dispatch({ type: 'SET_FLEET_DATA', payload: [] });
        // dispatch({ type: 'SET_DEVICE_INFO', payload: {} });
    }, [])
    return (
        <MyPage title="Fleet Management" >
            <Container maxWidth={false}>
                <Box
                    sx={{ flex: 1, margin: "10px" }}
                    style={theme.palette.borderWithShadow}
                >
                    <Toolbar data={data} setData={setData} hideLimit={true} parent={"fleet"} />
                </Box>
                <Box mt={3}>
                    <Box sx={{ height: 800 }} >
                        <Box height={'50%'} width={'100%'} sx={theme.palette.shadow}>
                            <FleetMap title="I'm the map." />
                        </Box>
                        <Box height={'50%'} width={'100%'} sx={{ marginTop: '25px', textAlign: 'left', borderColor: theme.palette.secondary.purple, borderStyle: 'solid', borderWidth: '2px', borderRadius: '10px', marginBottom: '10px' }}>
                            <FleetStatus />
                        </Box>
                    </Box>
                </Box>
            </Container>
        </MyPage>
    );
}
export default FleetManagementView;
