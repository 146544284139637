import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Card } from '@mui/material';
import SplitToken from '../../components/Tokens/SplitToken';
import { useDispatch } from 'react-redux';
import { textAlign } from '@mui/system';
import theme from '../../theme';
import { useNavigate } from 'react-router';
import getToken from '../../components/Tokens/GetToken';

function AccountPage() {
    const user = SplitToken();
    const dispatch = useDispatch();
    const [userName, setUserName] = useState(user.userName);
    const [userEmail, setUserEmail] = useState(user.EmailId);
    const [newPassword, setNewPassword] = useState("");
    const [edit, setEdit] = useState(false);
    const [editPassword, setEditPassword] = useState(false);
    const navigate = useNavigate();
    const token = getToken();
    
    let buttonSpace = 0
    // console.log('account page', user);
    if (edit) {
        buttonSpace = 5;
    } else {
        buttonSpace = 0;
    }

    useEffect(() => {
        if (!token){
          navigate('/login');
        }
    }, [])

    function handleSubmit() {
        // console.log("submit", userName, userEmail);
        const dataToSend = {
            userName,
            emailAddress: userEmail.toLocaleLowerCase(),
            Id: user.id,
            Password: "no"
        }
        dispatch({ type: 'UPDATE_USER_INFO', payload: dataToSend })
    }

    return (
        <Box >
            <h1>User Account</h1>
            <Card sx={{ border: 1, textAlign: 'left', paddingLeft: 10 }}>
                <>
                <h4 style={{textAlign: "center"}}>You may need to log out and back in in order to see changes</h4>

                    <h3>User Name:</h3>

                    {edit ? <TextField
                        // error={Boolean(touched.email && errors.email)}
                        // fullWidth
                        // helperText={touched.email && errors.email}
                        label="New Username"
                        margin="normal"
                        name="UserName"
                        //   onBlur={handleBlur}
                        onChange={(event) => setUserName(event.target.value)}
                        type="email"
                        value={userName}
                        variant="outlined"
                    />
                        :
                        <p>{user.userName}</p>}

                    <h3>User Email:</h3>
                    {edit ?
                        <TextField
                            // error={Boolean(touched.email && errors.email)}
                            // fullWidth
                            // helperText={touched.email && errors.email}
                            label="New Email Address"
                            margin="normal"
                            name="email"
                            // onBlur={handleBlur}
                            onChange={(event) => setUserEmail(event.target.value)}
                            type="email"
                            value={userEmail}
                            variant="outlined"
                        />
                        :
                        <p>{user.EmailId}</p>}
                </>
                <>

                </>
                <div style={{ textAlign: 'left', paddingBottom: 5 }}>
                    {edit ? <Button sx={{ marginRight: buttonSpace, bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }} variant='contained' onClick={() => handleSubmit()} >Submit </Button> : ""}
                    <Button sx={{ marginLeft: buttonSpace, bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }} variant='contained' onClick={() => setEdit(!edit)} >{edit ? 'Cancel' : 'Edit'}</Button>
                    {/* <Button sx={{ marginLeft: 5, bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }} variant='contained' onClick={() => setEdit(!editPassword)} >{editPassword? 'Cancel' : 'Change Password'}</Button> */}
                </ div>
            </Card>
        </Box>
    )
}

export default AccountPage;