import React from "react";
import Page from '../../components/Page';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Button
} from "@mui/material";
import theme from "../../theme";
import { styled } from '@mui/system';
import getToken from '../../components/Tokens/GetToken';

const MyPage = styled(Page)({
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    width: "100%"
})


const PageNotFound = () => {
    const navigate = useNavigate();
    const token = getToken();

    function knownTerritory() {
        
        if (token) {
            navigate('/')
        }
        else
        {
            navigate('/login')
        }
    }

    return (
        <MyPage title="404" >
            <Container maxWidth={false}>
                <Box mt={3}>
                    <Box sx={{ height: 800 }} >
                        <Box height={'50%'} width={'100%'} sx={{ display: 'flex', flexDirection: 'row' }}  >
                            <Box
                                sx={{ flex: 2, margin: "10px", paddingLeft: '10px' }}
                                style={theme.palette.borderWithShadow}
                            >
                                <p>Error 404:</p>
                                <p>There is nothing here!</p>
                                <p>Return to known territory:</p>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={knownTerritory}
                                    sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }}
                                >
                                    Known territory
                                </Button>

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </MyPage>
    )

}

export default PageNotFound;