import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../../alerts/Toast';
import getToken from '../../../components/Tokens/GetToken';

function* UploadJsonFileSaga() {
    yield takeLatest('UPLOAD_FILE', JsonFileUpload);
}

function* JsonFileUpload(action) {
    console.log("Saga",action)
    try {
        let token = getToken();

        let jsonFile = yield axios.post(`api/JsonFile/JsonFileUpload`, action.payload, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        console.log("File Uploaded", jsonFile.data);
        if (jsonFile.status == 200){
            let AllJsonFiles = yield axios.get(`api/JsonFile/GetJsonFiles`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
            console.log("retval for fetch all Json Fiels", AllJsonFiles.data);
            yield put({ type: "SET_JSON_DATA", payload: AllJsonFiles.data })
            Toast.fire({
                icon: 'success',
                title: `File Uploaded`,
            });
        }

    } catch (error) {
        console.log('error in JsonFileUpload saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with uploading JsonFile: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

export default UploadJsonFileSaga;