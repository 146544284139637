import React from 'react';
import {
    TextField,
    Button,
    Typography,
    Box,
    Grid,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux"
import { styled } from '@mui/system';
import Toast from '../../../alerts/Toast';
import axios from 'axios';
import getToken from '../../../components/Tokens/GetToken';
import theme from '../../../theme';

const MyButton = styled(Button)({
    width: '30%',
    minHeight: '50px'
})

const Paper = styled('div')({
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    border: '1px solid black',
    flexDirection: 'column'
})

const CreateFirmware = React.forwardRef((props, ref) => {
    const [minor, setMinor] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const [major, setMajor] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [revision, setRevision] = React.useState(null);
    const token = getToken();
    const dispatch = useDispatch();

    const handleUpload = () => {
        if (name === null) return Toast.fire({ icon: 'error', title: 'Please enter name' })
        else if (file === null) return Toast.fire({ icon: 'error', title: 'Enter file path' })
        else if (minor === null) return Toast.fire({ icon: 'error', title: 'Enter minor version' })
        else if (major === null) return Toast.fire({ icon: 'error', title: 'Enter major version' })
        else if (revision === null) return Toast.fire({ icon: 'error', title: 'Enter revision version' })

        // console.log('file', file);
        // console.log('name', name);
        // console.log('major', minor);
        // console.log('minor', major);
        // console.log('revision', revision);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        formData.append('majorVersion', major);
        formData.append('minorVersion', minor);
        formData.append('revisionVersion', revision);

        axios.post('api/Firmware/AdminCreateFirmware/', formData, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => {
                if (response.status === 200) Toast.fire({ icon: 'success', title: 'Upload Complete' });
                else Toast.fire({ icon: 'error', title: 'Error Uploading' });
            })
            .then(() => props.close())
            .then(() => dispatch({ type: "ADMIN_GRAB_FIRMWARE" }))
    }

    const handleChange = (type, event) => {
        switch (type) {
            case 'minor':
                return setMinor(event.target.value);
            case 'major':
                return setMajor(event.target.value);
            case 'name':
                return setName(event.target.value);
            case 'revision':
                return setRevision(event.target.value);
            default:
                break;
        }
    };

    return (
        <Paper>
            <Grid container spacing={3} sx={{ width: '80%' }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                        textAlign={"center"}
                        variant="h1"
                    >
                        ADD Firmware Version
                        (Incomplete)
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        defaultValue={name}
                        variant="outlined"
                        onChange={(event) => handleChange('name', event)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                        required
                        fullWidth
                        id="majorVersion"
                        label="Major Version"
                        defaultValue={major}
                        variant="outlined"
                        type="number"
                        onChange={(event) => handleChange('major', event)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                        required
                        fullWidth
                        id="minorVersion"
                        label="Minor Version"
                        defaultValue={minor}
                        variant="outlined"
                        type="number"
                        onChange={(event) => handleChange('minor', event)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                        required
                        fullWidth
                        id="revision"
                        label="Revision Version"
                        defaultValue={revision}
                        variant="outlined"
                        type="number"
                        onChange={(event) => handleChange('revision', event)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        required
                        fullWidth
                        type={"file"}
                        onChange={(event) => setFile(event.target.files[0])}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <MyButton
                        fullWidth
                        variant="outlined"
                        color="primary"
                        onClick={props.close}
                    >
                        Cancel
                    </MyButton>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <MyButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleUpload}
                        sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }}
                    >
                        Submit
                    </MyButton>
                </Grid>
            </Grid>
        </Paper>
    )
})

export default CreateFirmware;