import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../../alerts/Toast';
import getToken from '../../../components/Tokens/GetToken';
import fileDownload from 'js-file-download';

function* CasePhotoSaga() {
    yield takeLatest('GET_CASE_PHOTO', FetchCasePhoto);
}

function* FetchCasePhoto(action) {
    console.log("Saga",action)
    try {
        let token = getToken();

        let casePhotoData = yield axios.get(`api/JsonFile/GetJsonPhoto/${action.payload}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        console.log("FOUND PHOTO", casePhotoData.data);
        yield put({ type: "SET_CASE_PHOTO", payload: casePhotoData.data });

    } catch (error) {
        console.log('error in FetchCasePhoto saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with Grabbing Case Photo: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

export default CasePhotoSaga;