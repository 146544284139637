import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../../alerts/Toast';
import getToken from '../../../components/Tokens/GetToken';

function* DeviceConfigSaga() {
    yield takeLatest('FETCH_DEVICE_CONFIG', DevicePageConfig);
    yield takeLatest('DEVICE_CONFIG_EDIT', DeviceConfigEdit);
}

function* DevicePageConfig(action) {
    try {
        // console.log('start of fetch devices', action);
        let token = getToken();
        let retval = yield axios.get(`api/DeviceConfig/GetDeviceConfig/${action.payload}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
        // console.log("retval for fetch device Config", retval.data);
        yield put({ type: "SET_DEVICE_CONFIG", payload: retval.data })
        yield put({ type: "SET_CONFIG_STATUS", payload: retval.status })

    } catch (error) {
        console.log('error in DevicePageConfig', error);
        yield put({ type: "SET_CONFIG_STATUS", payload: error.response.status })
        Toast.fire({
            icon: 'error',
            title: `Error with Grabbing Device Config: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

function* DeviceConfigEdit(action) {
    try {
        // console.log('start of Device config', action.payload);
        let token = getToken();
        let retval = yield axios.put(`api/DeviceConfig/DeviceConfigEdit/${action.id}`, action.payload, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
        // console.log("retval for device config update", retval.data);
        // yield put({ type: "SET_DEVICE_CONFIG", payload: retval.data })

        yield put({ type: "FETCH_DEVICE_CONFIG", payload: action.id })

    } catch (error) {
        console.log('error in Config edit saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with Updating Device Config: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

export default DeviceConfigSaga;