import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../../alerts/Toast';
import getToken from '../../../components/Tokens/GetToken';

function* UserInfoUpdate() {
    yield takeLatest('UPDATE_USER_INFO', UpdateUserNameAndEmail);
}

function* UpdateUserNameAndEmail(action){
    try {
        let token = getToken();
        yield axios.put(`api/Account/UpdateUserNameAndEmail`, action.payload, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        
        // console.log('return value:', retval);
        Toast.fire({
            icon: 'success',
            title: `User Data Edit Successful!`,
        });
    } catch (error) {
        console.log('error in username and email update saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with Updating User: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

export default UserInfoUpdate;