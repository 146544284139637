import React from "react";
import {
    Button,
    Modal,
    Box,
    Card,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@mui/material";
import {
    DataGrid,
    GridToolbar
} from "@mui/x-data-grid";
import {
    Plus,
    Save,
    ArrowDown
} from "react-feather";
import NewUser from './UserCreation'
import { useSelector, useDispatch } from "react-redux";
import Toast from "../../../alerts/Toast";
import getToken from "../../../components/Tokens/GetToken";
import Popup from "../../../components/Popup";
import { useState } from "react";
import UserDataGrid from "./AdminUserGrid";
import theme from "../../../theme";


const UserTable = (props) => {
    const [editRowsModel, setEditRowsModel] = useState({});
    const [popGroups, setPopGroups] = useState({});
    const [openUser, setOpenUser] = useState(false);
    const [userName, setUserName] = useState('');
    const [accordionOpen, setAccordionOpen] = useState(false);
    const [popChildGroups, setPopChildGroups] = useState(false);
    const allUsers = useSelector((state) => state.AdminReducer.AdminAllUsers);
    const pendingUsers = useSelector((state) => state.AdminReducer.AdminPendingUsers);
    const approvedUsers = useSelector((state) => state.AdminReducer.AdminApprovedUsers);
    const suspendedUsers = useSelector((state) => state.AdminReducer.AdminDeniedUsers);
    const token = getToken();
    // const groups = useSelector((state) => state.admin.adminGroups);
    const users = props.data;
    const groups = props.groups;

    const dispatch = useDispatch();
    // console.log('users ', users);
    // console.log('user page props', props);
    console.log("allUsers", allUsers);
    console.log("PendingUsers", pendingUsers);
    console.log("approvedUsers", approvedUsers);
    console.log("suspendedUsers", suspendedUsers);


    const userOpen = () => {
        setOpenUser(true)
    }
    const userClose = () => {
        setOpenUser(false)
    }

    return (
        <Box>
            <Box
                style={{ marginBottom: "15px", display: 'flex', justifyContent: 'space-between' }}
            >
            <p style={{margin:'20px'}}>User Roles:
                <li><b>Read / Marketing:</b> Data Viewing Only</li>
                <li><b>Write / Sales Reps:</b> Upload Files, Only View self uploaded data.</li>
                <li><b>Admin / R&D:</b> Everything</li>
            </p>
                <Button
                    variant="contained"
                    // color="primary"
                    sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover}, height:'50%', marginTop: 'auto', marginBottom:'auto', marginRight:'20px' }}
                    onClick={userOpen}
                >
                    <Plus /> User
                </Button>
                {Object.keys(editRowsModel).length !== 0 ?
                    <Button
                        variant="contained"
                        // color="primary"
                        sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }}
                        onClick={handleSave}
                    >
                        <Save /> save
                    </Button>
                    : ''}
            </Box>
            <Accordion sx={{ border: '1px solid #e0e0e0', marginTop: '5px' }} >
                <AccordionSummary expandIcon={<ArrowDown />}>
                    Pending Users
                </AccordionSummary>
                <AccordionDetails sx={{ height: 550 }}>
                    <UserDataGrid type={"Pending"} users={pendingUsers} groups={groups} />
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ border: '1px solid #e0e0e0', marginTop: '5px' }} >
                <AccordionSummary expandIcon={<ArrowDown />}>
                    Approved Users
                </AccordionSummary>
                <AccordionDetails sx={{ height: 550 }}>
                    <UserDataGrid type={"Active"} users={approvedUsers} groups={groups} />
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ border: '1px solid #e0e0e0', marginTop: '5px' }} >
                <AccordionSummary expandIcon={<ArrowDown />}>
                    Suspended Users
                </AccordionSummary>
                <AccordionDetails sx={{ height: 550 }}>
                    <UserDataGrid type={"Suspended"} users={suspendedUsers} groups={groups} />
                </AccordionDetails>
            </Accordion>
            <Modal
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                open={openUser}
                onClose={userClose}
            >
                <NewUser close={userClose} />
            </Modal>

        </Box>
    )
}

export default UserTable;