import React from "react";
import { useEffect, useState } from "react";
import {
    Box,
    Hidden,
    Button,
    Typography,
    Chip,    
    Modal,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

function CasePhotoDisplay({photo}){
    // const dispatch = useDispatch();
    // const CasePhoto = useSelector((state) => state.CasePhotoReducer.CasePhoto);
    // console.log("PHOTO", CasePhoto)
    // const [photo, setPhoto] = React.useState(CasePhoto.casePhoto);
    // console.log("PHOTO", photo)

    //   let deCasePhoto = JSON.parse(CasePhoto.casePhoto)

    // React.useEffect(() => {
    // dispatch({ type: 'GET_CASE_PHOTO', payload: casePhotoId });
    // // setPhoto(CasePhoto.casePhoto)
    // },[]);

    return(
        <Box>
            <img style={{maxWidth:500, maxHeight:500}} src={`data:image/png;base64,${photo}`}/>
        </Box>
    )
}

export default CasePhotoDisplay;