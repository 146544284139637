import { combineReducers } from 'redux';

const HomeData = (state = 0, action) => {
    switch (action.type) {
        case 'SET_HOME_DATA':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const TotalCount = (state = 0, action) => {
    switch (action.type) {
        case 'SET_HOME_TOTAL':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const MonthCount = (state = 0, action) => {
    switch (action.type) {
        case 'SET_HOME_MONTH':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const DayCount = (state = 0, action) => {
    switch (action.type) {
        case 'SET_HOME_DAY':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const Activity = (state = 0, action) => {
    switch (action.type) {
        case 'SET_ACTIVITY':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

export default combineReducers({
    HomeData,
    TotalCount,
    MonthCount,
    DayCount,
    Activity,
    
});