import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { 
  createStore,
  applyMiddleware,
  compose
} from 'redux';
import { createRoot } from 'react-dom/client';
import rootSaga from './redux/sagas/_root.saga';
import rootReducer from './redux/reducers/_root.reducer';

const sagaMiddleware = createSagaMiddleware();

const middlewareList = process.env.NODE_ENV === 'development' ? [sagaMiddleware, logger] : [sagaMiddleware];

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middlewareList)
  )
);

sagaMiddleware.run(rootSaga);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>
  );