import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden, List } from '@mui/material';
import {
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  Home as HomeIcon

} from 'react-feather';
import NavItem from './NavItem';
import { styled } from '@mui/system';

const items = [
    {
        href: '/',
        icon: HomeIcon,
        title: 'Home'
    },
    {
        href: '/login',
        icon: LockIcon,
        title: 'Login'
    },
    {
        href: '/register',
        icon: UserPlusIcon,
        title: 'Register'
    },
];

const MyDrawer = styled(Drawer)({
  width: 256,
  top: 64, 
  marginTop: '64px'
},` & > div {
  width: 256px;
  border: none;
  margin-top: 64px;
}`)

const NavBar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return <>
    <Hidden lgUp>
      <Drawer
        anchor="left"
        sx={{ width: 256 }}
        // classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer>
    </Hidden>
    <Hidden xlDown>
      <MyDrawer
        anchor="left"
        // classes={{ paper: classes.desktopDrawer }}
        open
        variant="persistent"
      >
        {content}
      </MyDrawer>
    </Hidden>
  </>;
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;