import React, { useEffect } from 'react';
import {
    Box,
    Button,
    Card,
    MenuItem,
    Checkbox,
    TextField,
    Autocomplete,
    Chip,
} from '@mui/material';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    CheckBoxOutlineBlank,
    CheckBox as CheckBoxIcon
} from '@mui/icons-material'
import Toast from '../../alerts/Toast';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import theme from '../../theme';

const Toolbar = (props) => {
    const parent = props.parent;
    const groups = useSelector((state) => state.GroupReducer.AllGroups);
    const devices = useSelector((state) => state.DeviceReducers.GroupDevices);
    const transferGroup = useSelector((state) => state.DeviceReducers.TransferGroup);
    const transferDevice = useSelector((state) => state.DeviceReducers.TransferDevice);
    console.log('#$ transfer group', transferGroup);
    console.log('#$ transfer device', transferDevice);
    // const deviceLoading = useSelector((state) => state.DashboardReducer.deviceListLoading);
    const deviceLoading = false;
    // const [deviceList, setDeviceList] = React.useState(props.parent === 'device' ? transferDevice : "");
    const [deviceList, setDeviceList] = React.useState([]);
    const [submitDevices, setSubmitDevices] = React.useState([]);
    const [selectDevice, setSelectDevice] = React.useState([]);
    // const [group, setGroup] = React.useState(props.parent === 'device' ? transferGroup : "");
    const [group, setGroup] = React.useState([]);
    const [all, setAll] = React.useState(false);
    const [startDate, setStartDate] = React.useState(moment().subtract(30, "days").startOf('day').format());
    const [endDate, setEndDate] = React.useState(moment().format());
    // console.log('start and end', startDate, endDate);

    const dispatch = useDispatch();
    // console.log('props: ', props);
    // console.log('devices: ', devices);
    // console.log('device list: ', deviceList);
    // console.log('parent and group', parent, group);
    useEffect(() => {
        dispatch({ type: 'SET_DEVICE_METHANE_MEASUREMENTS', payload: [] });
        dispatch({ type: 'SET_TRANSFER_GROUP', payload: [] });
        dispatch({ type: 'SET_TRANSFER_DEVICE', payload: [] });
        dispatch({ type: 'SET_FLEET_DATA', payload: [] });
        dispatch({ type: 'SET_DEVICE_INFO_STATUS', payload: 0 });
        dispatch({ type: 'SEARCH_CLICKED', payload: false });
        if (group === '') {
            dispatch({ type: 'SET_GROUP_DEVICES', payload: [] });
        }
    }, [])

    if (group === '') {
        dispatch({ type: 'SET_FLEET_DATA', payload: [], origin: 'fleetStatus' });
        dispatch({ type: 'SET_DEVICE_INFO', payload: [], origin: 'fleetStatus' });
        dispatch({ type: 'SET_ALARM_DATA', payload: [], origin: 'fleetStatus' });
        // dispatch({ type: 'SET_DEVICE_METHANE_MEASUREMENTS', payload: [], origin: 'fleetStatus' });
        // dispatch({ type: 'SET_TRANSFER_GROUP', payload: [] });
        // dispatch({ type: 'SET_TRANSFER_DEVICE', payload: [] });
    }

    const icon = <CheckBoxOutlineBlank fontSize='small' />
    const checkedIcon = <CheckBoxIcon fontSize='small' />

    const onSubmit = () => {
        let submitDevice = []
        let subId = []
        for (let x of devices) {
            for (let y of deviceList) {
                if (y === x.name || y === x.id) {
                    submitDevice.push(x.imei);
                    subId.push(x.id);
                }
            }
        }
        console.log('#$ parent', parent);
        console.log('group', group);
        console.log('submit device', deviceList);
        console.log("#$ sub id ", subId);
        console.log('#$ devices', devices)
        console.log('#$ submit start', startDate);
        console.log('#$ submit end', endDate);

        dispatch({ type: 'SEARCH_CLICKED', payload: true });
        if (parent === 'fleet') {
            dispatch({ type: 'GET_FLEET_DATA', payload: group, start: startDate, end: endDate })

            dispatch({ type: 'SET_TRANSFER_GROUP', payload: group });
            // dispatch({ type: 'SET_TRANSFER_DEVICE', payload: params.row});
        } else if (parent === 'device') {
            // console.log("#$ in device should be going to the place");
            dispatch({ type: 'GET_DEVICE_VIEW_DATA', payload: subId, start: startDate, end: endDate })
        }
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }} >
            <>
                <Box sx={{ padding: '10px', flex: 1 }} >
                    <TextField
                        label="Location"
                        select
                        focused
                        variant="outlined"
                        value={group ? group : ""}
                        sx={{ width: '100%', color: 'black' }}
                        onChange={(event) => {
                            // if (parent !== 'fleet') {
                                setDeviceList([]);
                                dispatch({ type: 'SET_GROUP_DEVICES', payload: [] });
                                dispatch({ type: "GET_GROUP_DEVICES", payload: event.target.value });
                                setAll(false);
                            // }
                            setGroup(event.target.value);
                        }}
                    >
                        {groups.length ? groups.map((x, i) => {
                            return <MenuItem key={i} value={x.id}>{x.name}</MenuItem>
                        }) : <p>Loading</p>}
                    </TextField>
                </Box>

                {props.parent === "fleet" ? 
                    <Box sx={{ padding: '10px', flex: 1 }}>
                        <Autocomplete 
                            sx={{ width: '100%' }}
                            options={deviceLoading ? [] : [...devices]}
                            multiple
                            disableClearable={false}
                            // disableCloseOnSelect
                            value={deviceList}
                            isOptionEqualToValue={(option, value) => option.name === value}
                            renderTags={(value, getTagProps) => {
                                const numTags = value.length;
                                const limitTags = 1;
                                return (
                                    <>
                                    {value.slice(0, limitTags).map((option, index) => (
                                        <Chip
                                        {...getTagProps({ index })}
                                        key={index}
                                        label={option}
                                        />
                                    ))}
                                    {numTags > limitTags && ` +${numTags - limitTags}`}
                                    </>
                                );
                            }}
                            loading={deviceLoading}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                                let values = []
                                // newValue.forEach((x) => {
                                //     setSelectDevice({ externalId: x.externalId, serialNumber: x.serialNumber, id: x.id})
                                //     x.serialNumber ? values.push(x.serialNumber) : values.push(x)
                                // }) // new
                                newValue.forEach((x) => {
                                    setSelectDevice({ externalId: x.externalId, name: x.name, id: x.id })
                                    x.name ? values.push(x.name) : values.push(x)
                                }) // new
                                if (values.length >= 1)
                                {
                                    if (values.length > 1)
                                    {
                                        if (values[0] !== values[1])
                                        {
                                            values.splice(0, 1);
                                        }
                                    }
                                    setDeviceList(values)
                                    props.setData(newValue[0])
                                }
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    {/* <Checkbox 
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={deviceList.indexOf(option.name) > -1}
                                    /> */}
                                    {option.name}
                                </li>
                            )}
                            renderInput={(params) => <TextField {...params} focused label="Machine" variant='outlined'/>}
                        />
                    </Box>
                :
                    <Box
                        sx={{ padding: '10px', flex: 1 }}
                    >
                        <Autocomplete
                            sx={{ width: '100%' }}
                            options={deviceLoading ? [] : [...devices]}
                            multiple
                            disableClearable={false}
                            // disableCloseOnSelect
                            value={deviceList}
                            isOptionEqualToValue={(option, value) => option.name === value}
                            renderTags={(value, getTagProps) => {
                                const numTags = value.length;
                                const limitTags = 1;
                                return (
                                    <>
                                        {value.slice(0, limitTags).map((option, index) => (
                                            <Chip
                                                {...getTagProps({ index })}
                                                key={index}
                                                label={option}
                                            />
                                        ))}
                                        {numTags > limitTags && ` +${numTags - limitTags}`}
                                    </>
                                );
                            }}
                            loading={deviceLoading}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                                let values = []
                                newValue.forEach((x) => {
                                    setSelectDevice({ externalId: x.externalId, name: x.name, id: x.id })
                                    x.name ? values.push(x.name) : values.push(x)
                                }) // new
                                if (values.length >= 1) {
                                    if (values.length > 1) {
                                        if (values[0] !== values[1]) {
                                            values.splice(0, 1);
                                        }
                                    }
                                    setDeviceList(values)
                                    props.setData(newValue[0])
                                }
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    {/* <Checkbox 
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={deviceList.indexOf(option.name) > -1}
                        /> */}
                                    {option.name}
                                </li>
                            )}
                            renderInput={(params) => <TextField {...params} focused label="Machine" variant='outlined' />}
                        />
                    </Box>
                }
            </>
            <br></br>
            {/* {props.parent === "fleet" ? "" : */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ m: 1 }} >
                        <DatePicker
                            className='DatePicker'
                            label="Start Date"
                            value={startDate}
                            onChange={(newValue) => {
                                setStartDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>
                    <Box sx={{ m: 1 }} >
                        <DatePicker
                            className='DatePicker'
                            label="End Date"
                            value={endDate}
                            onChange={(newValue) => {
                                setEndDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>
                </LocalizationProvider>
            {/* } */}
            <Box sx={{ padding: '10px' }} >
                <Button
                    variant='contained'
                    sx={{ height: '56px', bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }}
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </Box>
        </Box>
    );
};

export default Toolbar;
