import React from 'react';
import {
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from "react-redux"
import { styled } from '@mui/system';
import theme from '../../../theme';
import Toast from '../../../alerts/Toast';
import getToken from '../../../components/Tokens/GetToken';

const Paper = styled('div')({
    position: 'absolute',
    width: '50%',
    height: '50%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center'
})

const Content = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%'
})

const Text = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
})

const ButtonContainer = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
})

const ButtonGroup = styled('div')({
    display: 'flex',
    width: '70%',
    justifyContent: 'space-between',
    marginTop: '20px'
})

const UserGroupAssignment = React.forwardRef((props, ref) => {
    const [groupOptions] = React.useState(props.groups);
    const [roleOptions] = React.useState([
        {id: 0, name: "Read"},
        {id: 1, name: "Write"},
        {id: 2, name: "Admin"},
    ]);
    const [groupAssignment, setGroupAssignment] = React.useState(null);
    const [roleAssignment, setRoleAssignment] = React.useState(null);
    const token = getToken();
    const dispatch = useDispatch();

    const handleSubmit = () => {
        const item = {
            userId: props.user.id,
            RoleName: roleAssignment,
            // GroupId: groupAssignment
        }
        console.log('item for submit', item);
        // if (name === null) {
        //     return Toast.fire({
        //         icon: 'error',
        //         title: 'Enter Group Name'
        //     })
        // }
        // else {
            // console.log('in submit', group);
            dispatch({ type: 'ADMIN_ASSIGN_USER_GROUP_ROLE', payload: item })
        // }
        props.close();
    }

    return (
        <Paper>
            <Content>
                <h1 style={{ textAlign: "center", marginBottom: '20px' }}>Assign {props.user.displayName} to a permissions role.</h1>
                <Text>
                {/* <Autocomplete
                        id="group-box"
                        options={groupOptions}
                        getOptionLabel={(options) => options.name}
                        onChange={(event, newValue) => {
                            setGroupAssignment(newValue.id)
                        }}
                        style={{ width: '30%' }}
                        renderInput={(params) => <TextField {...params} label="Group" variant="outlined" />}
                    />    */}
                    <Autocomplete
                        id="role-box"
                        options={roleOptions}
                        getOptionLabel={(roleOptions) => roleOptions.name}
                        onChange={(event, newValue) => {
                            setRoleAssignment(newValue.name)
                        }}
                        style={{ width: '30%' }}
                        renderInput={(params) => <TextField {...params} label="Role" variant="outlined" />}
                    />       
                </Text>
                <ButtonContainer>
                    <ButtonGroup>
                        {/* <Button
                            onClick={() => {
                                dispatch({ type: 'ADMIN_CHANGE_USER_STATUS', payload: {userId: props.user.id, status: 'Approved'}});
                                props.close(false)
                            }}
                            // variant="contained"
                            color="secondary"
                        >
                            Skip
                        </Button> */}
                        <Button
                            variant="contained"
                            // color="primary"
                            sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }}
                            onClick={handleSubmit}
                            style={{margin:'auto'}}
                        >
                            Assign
                        </Button>
                    </ButtonGroup>
                </ButtonContainer>
            </Content>
        </Paper>
    )
})

export default UserGroupAssignment;