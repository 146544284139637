
import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@mui/material';
import Page from '../../../src/components/Page';
import { styled } from '@mui/system';
import theme from '../../theme';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import getToken from '../../components/Tokens/GetToken';
import clearToken from '../../components/Tokens/ClearToken';

const MyPage = styled(Page)({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  paddingBottom: theme.spacing(3),
  paddingTop: theme.spacing(3)
})

const LoginView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getToken();
  
  useEffect(() => {
    if (token){
      navigate('/');
    }
  }, [])

  return (
    <MyPage
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values) => {
              // this is where you put the api for login with values
              console.log("values logging in", values);
              // navigate('/', { replace: true });
              if (!values.email) {
                Swal({
                  text: 'Please fill in your email address',
                  icon: "error"
                });
                return;
              }
          
              if (!values.password) {
                Swal({
                  text: 'Please fill in your password',
                  icon: "error"
                });
                return;
              }
          
              let dataToSend = { UserName: values.email.toLowerCase(), Password: values.password };
              dispatch({ type: 'USER_LOGIN', payload: dataToSend, navigate });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                </Box>
                {/* <Box
                  mt={3}
                  mb={1}
                >
                  <Typography
                    align="left"
                    color="textSecondary"
                    variant="body1"
                  >
                    login with email address
                  </Typography>
                </Box> */}
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    // color="primary"
                    sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }}
                    // disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </MyPage>
  );
};

export default LoginView;