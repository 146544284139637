import { combineReducers } from 'redux';
import UserReducer from './UserReducers/UserReducer';
import HomeData from './HomeDataReducers/HomeData';
import DeviceReducers from './DeviceReducer/DeviceReducers';
import FirmwareReducer from './FirmwareReducer/FirmwareReducer';
import HardwareReducer from './HardwareReducer/HardwareReducer';
import GroupReducer from './GroupReducer/GroupReducer';
import DeviceConfigReducer from './DeviceConfigReducer/DeviceConfigReducer';
import SubscriptionReducer from './Subscriptions/SubscriptionReducer';
import AdminReducer from './AdminReducer/AdminReducer';
import DashboardReducer from './DashboardReducer/DashboardReducer';
import JsonFileReducer from './FileReducer/JsonFileReducer';
import CasePhotoReducer from './FileReducer/CasePhotoReducer';

const appReducer = combineReducers({
    UserReducer,
    HomeData,
    DeviceReducers,
    FirmwareReducer,
    HardwareReducer,   
    GroupReducer,
    DeviceConfigReducer,
    SubscriptionReducer,
    AdminReducer,
    DashboardReducer,
    JsonFileReducer,
    CasePhotoReducer
});

const rootReducer = (state, action) => {
    if (action.type == 'LOGGED_OUT') {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;