import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

function TFA (){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [authLoader, setAuthLoader] = React.useState(true);

    var url_string = window.location;
    var url = new URL(url_string);
    var code = url.searchParams.get("confirm2FA");
    // console.log("URL CODE HERE", code);

    useEffect(() => {
            dispatch({ type: 'USER_TFA', payload: code, navigate, setAuthLoader });
    }, [])

    return(
        <div>
            {authLoader == true ?
                <div style={{margin:'auto', display:'flex', flexDirection:'column', alignItems:'center', border:'2px solid #a72525', borderRadius:10, padding:10, width:'25%', marginTop: '25%'}}>
                    <CircularProgress color='error'/>
                    <span style={{marginTop:20}}>AWAITING AUTHENTICATION</span>
                </div>
            :
                <div style={{margin:'25%', display:'flex', flexDirection:'column', alignItems:'center', border:'2px solid #a72525', borderRadius:10}}>
                    <h2>Two Factor Authentication Failed</h2>
                    <p>Please Reattempt Login.</p>
                    <p> Reminder Authentication Email Expires after a few minutes.</p>
                </div>
            }
        </div>
    )
}

export default TFA;