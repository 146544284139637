import React, { useEffect, useState } from "react";
import Page from '../../components/Page';
import ChartService from "../../utils/DashboardChartService";
import getToken from "../../components/Tokens/GetToken";
import {
    Box,
    Container,
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import theme from "../../theme";
import Toolbar from '../Toolbar/Toolbar';
import { styled } from '@mui/system';
import { useNavigate } from "react-router";
import moment from "moment";
import ActiveChart from "./ActiveChart";
import NotificationsChart from "./NotificationsChart";
import DataGraph from "./DataGraph";
import FileDataGrid from "./HomePageDataGrid/FileDataGrid";

const MyPage = styled(Page)({
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    width: "100%",
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3)
})


const LandingPage = () => {

    

    return (
        <MyPage title="Service Management" >
            <Container maxWidth={false}>
                <Box mt={3}>
                    <Box>
                        {/* <Box height={'30%'} width={'100%'} sx={{  display: 'flex', flexDirection: 'row' }}>
                            <Box
                                sx={{ flex: 3, margin: "10px" }}
                                style={theme.palette.borderWithShadow}
                            >
                                <ActiveChart />
                            </Box>
                            <Box
                                sx={{ flex: 2, margin: "10px" }}
                                style={theme.palette.borderWithShadow}
                            >
                                <NotificationsChart />
                            </Box>
                        </Box> */}
                        <Box height={'70vh'} width={'100%'} sx={{ display: 'flex', flexDirection: 'row' }}  >
                            <Box
                                sx={{ flex: 2, margin: "10px" }}
                                style={theme.palette.borderWithShadow}
                            >
                                {/* <DataGraph /> */}
                                <FileDataGrid/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </MyPage>
    )

}

export default LandingPage;