import React from 'react';
import { Box } from '@mui/material';
import PageNotFound from './PageNotFound';


const PageNotFoundIndex = () => {
    return (
        <Box width={ '100%' }>
            <Box
                style={{ paddingTop: "-200px", height: '100%', minHeight: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%" }}
            >
                <PageNotFound />

            </Box>

        </Box>
    )
}

export default PageNotFoundIndex;