import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../../alerts/Toast';
import getToken from '../../../components/Tokens/GetToken';
import SplitToken from '../../../components/Tokens/SplitToken';

function* EverythingSaga() {
    yield takeLatest('FETCH_EVERYTHING', GrabEverything);
}

function* GrabEverything(action) {
    try {
        // console.log('start of fetch devices');
        let token = getToken();
        let user = SplitToken();

        if (token) {
            // get all devices
            // let devices = yield axios.get(`api/Device/GetAllDevices`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
            // console.log("devices for fetch all devices", devices.data);
            // yield put({ type: "SET_DEVICE_DATA", payload: devices.data })

            // // get all hardware
            // let hardware = yield axios.get(`api/Hardware/GetAllHardware`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
            // console.log("retval for fetch all hardware", hardware.data);
            // yield put({ type: "SET_HARDWARE_DATA", payload: hardware.data })

            // //get all Firmware
            // let firmware = yield axios.get(`api/Firmware/GetAllFirmware`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
            // console.log("retval for fetch all firmware", firmware.data);
            // yield put({ type: "SET_FIRMWARE_DATA", payload: firmware.data })

            // let AllGroups = yield axios.get(`api/Groups/GetAllGroups`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
            // console.log("retval for fetch all Groups", AllGroups.data);
            // yield put({ type: "SET_GROUP_DATA", payload: AllGroups.data })

            if (user.isAdmin[1] == "Write"){
                let AllJsonFiles = yield axios.get(`api/JsonFile/GetJsonFiles`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
                console.log("retval for fetch all Json Fiels", AllJsonFiles.data);
                yield put({ type: "SET_JSON_DATA", payload: AllJsonFiles.data })
            } else {
                let AllJsonFiles = yield axios.get(`api/JsonFile/GetJsonFiles`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
                console.log("retval for fetch all Json Fiels", AllJsonFiles.data);
                yield put({ type: "SET_JSON_DATA", payload: AllJsonFiles.data })
            }


            // let AllSubscriptions = yield axios.get(`api/AlarmSubs/GetAllSubscriptions/${user.id}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
            // console.log("retval for fetch all user subscriptions", AllSubscriptions.data);
            // yield put({ type: "SET_USER_SUBSCRIPTIONS", payload: AllSubscriptions.data })
            // yield put({ type: "SET_SUBS_STATUS", payload: AllSubscriptions.status })
            
            yield put({ type: 'EVERYTHING_SET', payload: true }) // device reducer
        }

    } catch (error) {
        console.log('error in GrabEverything saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with Grabbing Data: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

export default EverythingSaga;