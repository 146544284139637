import { put, takeLatest } from 'redux-saga/effects';
import Toast from '../../../alerts/Toast'
import axios from 'axios';
import clearToken from '../../../components/Tokens/ClearToken';
import getToken from '../../../components/Tokens/GetToken';

function* UserLogin() {
    yield takeLatest('USER_TFA', TFALogin);
}

function* TFALogin(action) {
    // console.log("/login user", action);
    
    try {
        const response = yield axios.post(`api/Account/GetToken/${action.payload}`);
        const token = JSON.stringify(response.data);
        sessionStorage.setItem('auth', token);
        const gettoken = getToken();
        // console.log('gettoken', getToken);

        if (response.status === 200) {
            // console.log("HERE", response.data.isAdmin)
            // yield put({ type: 'SIGN_IN_RESPONSE', payload: response.status });
            yield put({ type: 'FETCH_EVERYTHING', payload: response.status });
            // yield put({ type: 'isAuthenticated', payload: true });
            action.navigate('/')
            window.location.reload();
            // if (response.data.isAdmin === -1){
            //     action.navigate('/#home');
            //     window.location.reload();
            // }
            // if (response.data.isAdmin === 0){
            //     action.navigate('/#home');
            //     window.location.reload();
            // }
            // if (response.data.isAdmin === 1){
            //     action.navigate('/admin');
            //     window.location.reload();
            // }
        }
    } catch (e) {
        console.log('error in Login saga.', e);
        Toast.fire({
            icon: 'error',
            title: 'Two Factor Authentication Failed'
        });
        action.setAuthLoader(false);
    }
}

export default UserLogin;
