import React, { useState, useEffect } from 'react';
import {
    Box, Button, TextField,
} from "@mui/material";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Wrapper } from '@googlemaps/react-wrapper';
import { useRef } from 'react';
import theme from '../../theme';

function SetNewLocation({ device, close }) {
    console.log('#$ start of new location', device);
    // const [points, setPoints] = useState([device]);
    // const [mapRef, setMapRef] = useState(React.createRef());
    const [mapKey, setMapKey] = useState(process.env.NEXT_GOOGLE_MAP_ID);//'fc81d2eb23808f52 //cae007a237d7e0c5
    // const [pointReturn, setPointReturn] = useState(0);
    const dispatch = useDispatch();
    const [lat, setLat] = useState(device.latitude);
    const [lng, setLng] = useState(device.longitude);
    let markerPlaced = useRef(false); // keeps track of if a new marker has been placed to keep device location and new location down to 2 markers
    let manualMarker = useRef(false);
    const marker = useRef();
    const [maps, setMaps] = useState();
    // console.log("lat/lng", lat,lng);
    // console.log('at top, map', maps);

    function DropMarker() {
        let location = { lat, lng }
        placeMarkerAndPanTo(location, maps);
        manualMarker.current = true;
        // console.log('2 (drop) marker placed & manual marker', markerPlaced, manualMarker);
        // the issue with manual markers not being able to be placed, has to do with this ^^ line, when removed allows the first pin to be placed either method but doesn't remove the previous 
        // marker allowing a lot of markers ... tried useRef and useState. use state allowed the manual to work first, but the click markers would multiply instead of the reverse.
        // useRef worked just like a standard variable...
    }

    async function initMap() {
        
        let myLatLng = { lat, lng };
        const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 8,
            center: myLatLng,
            mapId: 'cae007a237d7e0c5',
            // mapId: process.env.NEXT_GOOGLE_MAP_ID, // ??
        });
        setMaps(map);
        
        map.addListener("click", (e) => {
            placeMarkerAndPanTo(e.latLng.toJSON(), map);
        });
        new google.maps.Marker({
            position: myLatLng,
            map,
        });
    }

    async function placeMarkerAndPanTo(latLng, map) {
        const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");
        if (markerPlaced.current || (markerPlaced.current && manualMarker.current)) {
            // console.log('in if mark placed');
            marker.current.setMap(null); // clears markers so only 1 persists
        }
        const pinBackground = new PinElement({
            background: "#404CF5",
            borderColor: "#40a6f5",
            glyphColor: "#40a6f5"
        });
        // marker = new google.maps.Marker({ // for regular marker
        marker.current = new AdvancedMarkerElement({ // for new cool colored marker
            position: latLng,
            map,
            content: pinBackground.element,
        });
        // if(typeof(latLng.lat))
        setLat(latLng.lat)
        setLng(latLng.lng)
        markerPlaced.current = true;
        // maps.panTo(latLng); // if you want the map to move after placing a point
    }

    window.initMap = initMap;

    function handleSubmit() {
        const data = {
            id: device.id,
            lat: lat,
            long: lng
        }
        // console.log('submit data', data);
        dispatch({ type: 'UPDATE_DEVICE_LOCATION', payload: data });
    }

    return (
        <Box key={mapKey} width={'100%'} height={'100%'} textAlign={'center'} >
            <div id='map' className='map' style={{ height: '50%' }}>
                <Helmet >
                    <script async defer src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCfD8SfnjyXBOHK_Ge18erIVoKSV5wnI3c&callback=initMap&v=beta&libraries=marker"></script>
                </Helmet>
            </div>
            <div style={{ marginTop: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline' }} >
                <TextField type='number' style={{ marginRight: '15px' }} label="Latitude (Decimal)" margin="normal" variant="outlined" value={lat} onChange={(event) => setLat(parseFloat(event.target.value))}>  </TextField>
                <TextField type='number' style={{ marginLeft: '15px', marginRight: '15px' }} label="Longitude (Decimal)" margin="normal" variant="outlined" value={lng} onChange={(event) => setLng(parseFloat(event.target.value))}>  </TextField>
                <Button style={{ marginLeft: '15px', height: '36.5px', width: "210px" }} sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }} variant='contained' onClick={() => DropMarker()} >Move Marker</Button>
            </div>
            <Button variant='contained' sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }} onClick={() => handleSubmit()} >Save New Location</Button>
        </Box >
    )
}
export default SetNewLocation;