import { combineReducers } from 'redux';

const AdminAllUsers = (state = [], action) => {
    switch (action.type) {
        case 'ADMIN_SET_ALL_USERS':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const AdminApprovedUsers = (state = [], action) => {
    switch (action.type) {
        case 'ADMIN_SET_APPROVED_USERS':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const AdminPendingUsers = (state = [], action) => {
    switch (action.type) {
        case 'ADMIN_SET_PENDING_USERS':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const AdminDeniedUsers = (state = [], action) => {
    switch (action.type) {
        case 'ADMIN_SET_SUSPENDED_USERS':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const AdminUsersInGroup = (state = [], action) => {
    switch (action.type) {
        case 'ADMIN_SET_USERS_IN_GROUP':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const AdminUserGroupStatus = (state = [], action) => {
    switch (action.type) {
        case 'ADMIN_SET_USERS_RETURN_STATUS':
            state = action.payload;
            return state;
        default:
            return state;
    }
};


// dispatch({ type: "ADMIN_SET_USERS_IN_GROUP", payload: [] });
// dispatch({ type: "ADMIN_SET_USERS_RETURN_STATUS", payload: 0 });

export default combineReducers({
    AdminAllUsers,
    AdminApprovedUsers,
    AdminPendingUsers,
    AdminDeniedUsers,
    AdminUsersInGroup,
    AdminUserGroupStatus,
    
});