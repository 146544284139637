import { combineReducers } from 'redux';

const AllGroups = (state = [], action) => {
    switch (action.type) {
        case 'SET_GROUP_DATA':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

export default combineReducers({
    AllGroups,
});