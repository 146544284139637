import React, { useEffect } from "react";
import {
    Box,
    Container,
} from "@mui/material";
import Page from '../../components/Page';
import DeviceMap from './DeviceMap/DeviceMap';
import DeviceAlarmStatus from './DeviceStatus/DeviceStatus';
import DeviceInfo from './DeviceInfo/DeviceInfo';
import { useDispatch, useSelector } from 'react-redux';
import theme from "../../theme";
import Toolbar from '../Toolbar/Toolbar';
import { styled } from '@mui/system';
// import FleetMap from "../FleetView/FleetMap/Fleetmap";
import ChartService from "../../utils/DashboardChartService";
import { useNavigate } from "react-router";
import getToken from "../../components/Tokens/GetToken";
import moment from "moment";

const MyPage = styled(Page)({
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
})

const DeviceView = (props) => {
    const token = getToken();
    const measurements = useSelector((state) => state.DeviceReducers.DeviceMeasurements);
    // const selectedDevices = useSelector((state) => state.DeviceReducers.FleetData);
    // const measurements = [];
    const [data, setData] = React.useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let datasets = []
    let datasets1 = []
    let datasets2 = []
    let datasets3 = []


    console.log("#$ measurements", measurements);
    // console.log("^data device view", data);


    useEffect(() => {
        if (!token) {
            console.log('should navigate', token);
            navigate("/login")
        }
        dispatch({ type: 'FLEET_SERVICE', payload: {} });
        dispatch({ type: 'SET_DEVICE_INFO', payload: {} });

    }, [])

    let colorArray = ['#00B3E6', '#0e5e8a', '#33DAFF',
        '#4D80CC', '#4DB3FF', '#40F0E5', '#99FF99', '#FF6633', '#B34D4D',
        '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF', '#FFFF99'
    ]
    
    function GraphData() {
        if (measurements.length > 0) {
            let arrayGraph = []; // for the graph, resets every time through
            //motor current
            let batteryArray =[];
            let tempArray =[];

            // battery
            // temp

            for (let i = 0; i < measurements.length; i++) {

                // for the graph
                arrayGraph.push({ x: moment.utc(measurements[i].created).format('M/D/YY, HH:mm'), y: measurements[i].motorCurrent, name: 'Motor Current' });
                batteryArray.push({ x: moment.utc(measurements[i].created).format('M/D/YY, hh:mm'), y: measurements[i].battery, name: 'Battery' });
                tempArray.push({ x: moment.utc(measurements[i].created).format('M/D/YY, hh:mm'), y: measurements[i].temperature, name: 'Battery' });

            }
            
            // data sets for info
            // console.log('1 array graph j', DataGrid[i]);
            console.log('#$ 2 array graph', arrayGraph);

            datasets1.push({
                label: data.name,
                fill: false,
                borderColor: colorArray[0],
                lineTension: 0,
                // backgroundColor: colorArray[j+1],
                data: arrayGraph,
                barPercentage: 0.5,
                barThickness: 6,
                maxBarThinkness: 8,
                minBarLength: 2,
                borderWidth: 2,
            })

            datasets2.push({
                label: data.name,
                fill: false,
                borderColor: colorArray[2],
                lineTension: 0,
                // backgroundColor: colorArray[j+1],
                data: batteryArray,
                barPercentage: 0.5,
                barThickness: 6,
                maxBarThinkness: 8,
                minBarLength: 2,
                borderWidth: 2,
            })

            datasets3.push({
                label: data.name,
                fill: false,
                borderColor: colorArray[4],
                lineTension: 0,
                // backgroundColor: colorArray[j+1],
                data: tempArray,
                barPercentage: 0.5,
                barThickness: 6,
                maxBarThinkness: 8,
                minBarLength: 2,
                borderWidth: 2,
            })

            datasets = [datasets1, datasets2, datasets3]
            return datasets3;
        } else {
            return [];
        }
    }

    // function tempGraph() {
    //     if (measurements.length > 0) {
    //         let arrayGraph = []; // for the graph, resets every time through
    //         for (let i = 0; i < measurements.length; i++) {

    //             // for the graph
    //             arrayGraph.push({ x: moment.utc(measurements[i].timestamp).local().format(), y: measurements[i].ambientTemperature, name: data.name });

    //         }
    //         datasets2.push({
    //             label: data.name,
    //             fill: false,
    //             borderColor: colorArray[1],
    //             lineTension: 0,
    //             // backgroundColor: colorArray[j+1],
    //             data: arrayGraph,
    //             barPercentage: 0.5,
    //             barThickness: 6,
    //             maxBarThinkness: 8,
    //             minBarLength: 2,
    //             borderWidth: 2,
    //         })
    //         return datasets2;
    //     } else {
    //         return [];
    //     }
    // }

    let chartData1 = {
        datasets: GraphData(),
        type: "line",
        title: "Motor Current",
        // layout: { x: 0, y: 0, w: 6, h: 8 }
    }

    // let chartData2 = {
    //     datasets: tempGraph(),
    //     type: "line",
    //     title: "Ambient Temp (℃) Vs. Time",
    //     layout: { x: 0, y: 0, w: 6, h: 8 }
    // }
    console.log('#$ chart data in device view', chartData1);
    return (
        <MyPage title="Service Management" >
            <Container maxWidth={false}>
                <Box
                    sx={{ flex: 1, margin: "10px" }}
                    style={theme.palette.borderWithShadow}
                >
                    <Toolbar data={data} setData={setData} parent={'device'} />
                </Box>
                <Box mt={3}>
                    <Box sx={{ height: 800 }} >
                        {/* <Box height={'50%'}> */}
                        <Box height={'50%'} width={'100%'} sx={{ /* border: '1px solid', */ display: 'flex', flexDirection: 'row' }}>
                            <Box
                                sx={{ flex: 3, margin: "10px", paddingTop: "20px" }}
                                style={theme.palette.borderWithShadow}
                            >
                                <ChartService data={chartData1} />
                            </Box>
                            <Box
                                sx={{ flex: 2, margin: "10px" }}
                                style={theme.palette.shadow}
                                // style={theme.palette.borderWithShadow}
                            >
                                <DeviceMap/>
                                {/* <FleetMap /> */}
                            </Box>
                        </Box>
                        <Box height={'50%'} width={'100%'} sx={{ display: 'flex', flexDirection: 'row' }}  >
                            <Box
                                sx={{ flex: 2, margin: "10px" }}
                                style={theme.palette.borderWithShadow}
                            >
                                <DeviceInfo />
                            </Box>
                            <Box
                                sx={{ flex: 3, margin: "10px" }}
                                style={theme.palette.borderWithShadow}
                            >
                                <DeviceAlarmStatus />
                            </Box>
                        </Box>
                        {/* <Box height={'50%'} width={'100%'} >
                            <DeviceAlarmStatus />
                        </Box> */}
                    </Box>
                </Box>
            </Container>
        </MyPage>
    );
}
export default DeviceView;
