import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../../alerts/Toast';
import getToken from '../../../components/Tokens/GetToken';
import SplitToken from '../../../components/Tokens/SplitToken';

function* SubscriptionSaga() {
    yield takeLatest('UPDATE_METHANE_SUB', UpdateMethaneSubscription);
    yield takeLatest('UPDATE_HATCH_SUB', UpdateHatchSubscription);
    yield takeLatest('UPDATE_GAP_SUB', UpdateGapSubscription);
}

function* UpdateMethaneSubscription(action) {
    try {
        let token = getToken();
        let user = SplitToken();
        // console.log('methane, action', action);
        let retval = yield axios.post(`api/AlarmSubs/MethaneSubUpdate/${action.group}/${user.id}/${action.checked}`, {}, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
        // console.log("retval for methane sub", retval.data);

        let AllSubscriptions = yield axios.get(`api/AlarmSubs/GetAllSubscriptions/${user.id}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        // console.log("retval for fetch all user subscriptions", AllSubscriptions.data);
        yield put({ type: "SET_USER_SUBSCRIPTIONS", payload: AllSubscriptions.data })
        yield put({ type: "SET_SUBS_STATUS", payload: AllSubscriptions.status })

    } catch (error) {
        console.log('error in UpdateMethaneSubscription saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with Changing Methane Subscriptions: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

function* UpdateHatchSubscription(action) {
    try {
        let token = getToken();
        let user = SplitToken();
        // console.log('hatch, action', action);
        let retval = yield axios.post(`api/AlarmSubs/HatchSubUpdate/${action.group}/${user.id}/${action.checked}`, {}, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
        // console.log("retval for hatch sub", retval.data);

        let AllSubscriptions = yield axios.get(`api/AlarmSubs/GetAllSubscriptions/${user.id}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        // console.log("retval for fetch all user subscriptions", AllSubscriptions.data);
        yield put({ type: "SET_USER_SUBSCRIPTIONS", payload: AllSubscriptions.data })
        yield put({ type: "SET_SUBS_STATUS", payload: AllSubscriptions.status })

    } catch (error) {
        // console.log('error in UpdateHatchSubscription saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with Changing Hatch Subscriptions: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

function* UpdateGapSubscription(action) {
    try {
        let token = getToken();
        let user = SplitToken();
        // console.log('gap, action', action);
        let retval = yield axios.post(`api/AlarmSubs/GapSubUpdate/${action.group}/${user.id}/${action.checked}`, {}, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
        // console.log("retval for gap sub", retval);

        let AllSubscriptions = yield axios.get(`api/AlarmSubs/GetAllSubscriptions/${user.id}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        // console.log("retval for fetch all user subscriptions", AllSubscriptions.data);
        yield put({ type: "SET_USER_SUBSCRIPTIONS", payload: AllSubscriptions.data })
        yield put({ type: "SET_SUBS_STATUS", payload: AllSubscriptions.status })

    } catch (error) {
        console.log('error in UpdateHatchSubscription saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with Changing Hatch Subscriptions: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

export default SubscriptionSaga;