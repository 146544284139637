import React from "react";
import { useEffect, useState } from "react";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
    Box,
    Hidden,
    Button,
    Typography,
    Chip,    
    Modal,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import InfoModal from "./InfoModal";
import Toast from '../../../alerts/Toast';
import Swal from 'sweetalert2';
import splitToken from '../../../components/Tokens/SplitToken';

// const userToken = SplitToken();
// const token = getToken();

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const FileDataGrid = () => {
    const [rowItem, setRowItem] = useState();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const AllJsonFiles = useSelector((state) => state.JsonFileReducer.AllJsonFiles);
    const dispatch = useDispatch();
    const user = splitToken();
    console.log("JSON FILES", AllJsonFiles);

    React.useEffect(() => {
        // dispatch({ type: 'GET_COMPANY_INVOICES', payload: files[0]?.companyId });
        // dispatch({ type: 'GET_PDF_FILE', payload: 2, setPdfUrl });
    },[AllJsonFiles]);

    function findDownload(cellValues){
        console.log(cellValues.row.id);
        dispatch({ type: 'GET_JSON_FILE', payload: cellValues.row.id });
    }

    function showInfo(cellValues) { // hadles the double click to bring up modal to edit crop amount
        setRowItem({
            id: cellValues.row.id,
            additionalJsonData: cellValues.row.additionalJsonData,
            airInSystem: cellValues.row.airInSystem,
            calculatedStatisticsJson: cellValues.row.calculatedStatisticsJson,
            caseNotes: cellValues.row.caseNotes,
            casePhotoId: cellValues.row.casePhotoId,
            caseType: cellValues.row.caseType,
            created: cellValues.row.created,
            deleted: cellValues.row.deleted,
            estimatedBloodLoss: cellValues.row.estimatedBloodLoss,
            estimatedClotSize: cellValues.row.estimatedClotSize,
            fileFormatVersion: cellValues.row.fileFormatVersion,
            physician: cellValues.row.physician,
            representativeName: cellValues.row.representativeName,
            rhvInPlace: cellValues.row.rhvInPlace,
            separatorUsed: cellValues.row.separatorUsed,
            site: cellValues.row.site,
            syslogCaseUUID: cellValues.row.syslogCaseUUID,
            syslogDataId: cellValues.row.syslogDataId,
            syslogDeviceDescription: cellValues.row.syslogDeviceDescription,
            syslogMicroFwUUID: cellValues.row.syslogMicroFwUUID,
            syslogMicroUUID: cellValues.row.syslogMicroUUID,
            syslogProcedureDate: cellValues.row.syslogProcedureDate,
            updated: cellValues.row.updated,
            wireInPlace: cellValues.row.wireInPlace

        })
        dispatch({ type: 'GET_CASE_PHOTO', payload: cellValues.row.casePhotoId });

        handleOpen();
    };

    function DeleteFile(item){
        // new swal({
        //     title: "DELETE DATA",
        //     text: `Delete ALL File Data?.`,
        //     icon: "warning",
        //     buttons: true,
        //     dangerMode: false,
        // })
        // .then((willUpdate) => {
        //     if (willUpdate) {
            //     } else {
                //     new swal({
                    //         title: `Data Retained.`,
                    //         position: 'center'
                    //     });
                    // }})
            Swal.fire({
                title: "DELETE DATA",
                text: "Are you sure? You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "DELETE"
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log("ID", item);
                    dispatch({ type: 'DELETE_JSON_FILE', payload: item });
                    // Swal.fire({
                    //     title: "Deleted!",
                    //     text: "Your file has been deleted.",
                    //     icon: "success"
                    // });
                } else {
                    Swal.fire({
                        title: "Data Retained!",
                        text: "Data File Not Deleted.",
                        icon: "success"
                    })
                }
          });
    };

    let columns = [];
    if (user.isAdmin[1] == "Admin"){
        columns = [
            { field: 'syslogCaseUUID', headerName: 'Case ID', flex: .85, headerClassName: 'header', editable: false},
            { field: 'syslogProcedureDate', headerName: 'Date', flex: .60, headerClassName: 'header', editable: false},
            { field: 'caseType', headerName: 'Case Type', flex: .6, headerClassName: 'header', editable: false},
            { field: 'syslogDeviceDescription', headerName: 'Device', flex: .50, headerClassName: 'header', editable: false},
            { field: 'site', headerName: 'Site', flex: .5, headerClassName: 'header', editable: false},
            { field: 'physician', headerName: 'Physician', flex: .45, headerClassName: 'header', editable: false},
            { field: 'representativeName', headerName: 'Uploaded By', flex: .5, headerClassName: 'header', editable: false},   
            { field: 'infoTab', headerName: 'Info', flex: .3, headerClassName: 'header', sortable: false,
                renderCell: (cellValues)=> (
                    <div>
                        <Button variant="contained" style={{backgroundColor:'#fff', color:'#a72525', border: '2px solid #a72525'}} onClick={() => showInfo(cellValues)}>Info</Button>
                    </div>
                )
            },
            { field: 'downloadJsonFile', headerName: 'Download File', flex: .45, headerClassName: 'header', sortable: false,
                renderCell: (cellValues)=> (
                    <div>
                        <Button variant="contained" style={{backgroundColor:'#fff', color:'#a72525', border: '2px solid #a72525'}} onClick={()=> findDownload(cellValues)}>
                            Download
                        </Button>
                    </div>
                )
            },
            { field: 'deleteFile', headerName: 'Delete File', flex: .35, headerClassName: 'header', sortable: false,
                renderCell: (cellValues)=> (
                    <div>
                        <Button variant="contained" color="error" onClick={()=> DeleteFile(cellValues.row.id)}>
                            Delete
                        </Button>
                    </div>
                )
            },
        ];
    } else {
        columns = [
            { field: 'syslogCaseUUID', headerName: 'Case ID', flex: .85, headerClassName: 'header', editable: false},
            { field: 'syslogProcedureDate', headerName: 'Date', flex: .60, headerClassName: 'header', editable: false},
            { field: 'caseType', headerName: 'Case Type', flex: .6, headerClassName: 'header', editable: false},
            { field: 'syslogDeviceDescription', headerName: 'Device', flex: .50, headerClassName: 'header', editable: false},
            { field: 'site', headerName: 'Site', flex: .5, headerClassName: 'header', editable: false},
            { field: 'physician', headerName: 'Physician', flex: .5, headerClassName: 'header', editable: false},
            { field: 'representativeName', headerName: 'Uploaded By', flex: .5, headerClassName: 'header', editable: false},   
            { field: 'infoTab', headerName: 'Info', flex: .25, headerClassName: 'header', sortable: false,
                renderCell: (cellValues)=> (
                    <div>
                        <Button variant="contained" color="primary" onClick={() => showInfo(cellValues)}>Info</Button>
                    </div>
                )
            },
        ];
    }

    const rows = AllJsonFiles;

    return (
        <Box
            sx={{ '& .header': { backgroundColor: '#e2e2e2' }, height: '63vh' }}
        >
            <Typography textAlign={"left"} variant='h4'>
                Files
            </Typography>
            <DataGrid
                // components={{
                //     Toolbar: CustomToolBar
                // }}
                // editMode="row"
                // onRowEditCommit={handleSave}
                // editRowsModel={editRowsModel}
                // onEditRowsModelChange={handleEditRowsModelChange}
                columns={columns}
                rows={rows}
                pageSizeOptions={ [10, 100, 250, 500] }
            />
             <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <InfoModal item={rowItem} style={style}/>
            </Modal>
        </Box>
    )
};

export default FileDataGrid;