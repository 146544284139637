import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import Page from '../../components/Page';
import { styled } from '@mui/system';
import theme from '../../theme';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const MyPage = styled(Page) ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3)
})


const RegisterView = ( {admin} ) => { // RegisterView must be called with admin = true to disable captcha
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  let BypassCaptcha = true;

  if (admin) {
    BypassCaptcha = true;
  }

  let initialValues = {
    EmailAddress: '',
    DisplayName: '',
    Password: '',
    CaptchaResponse: '',
    BypassCaptcha: BypassCaptcha,
  }


  return (
    <MyPage
      title="Register"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik // https://formik.org/docs/examples/basic
            initialValues={initialValues}
            validationSchema={
              Yup.object().shape({ // https://www.npmjs.com/package/yup
                EmailAddress: Yup.string().email('Must be a valid email').max(64).required('Email address is required'),
                DisplayName: Yup.string().max(30).required('Username is required'),
                Password: Yup.string().max(64).required('Password is required'),
                
              })
            }
            onSubmit={(values) => { // https://formik.org/docs/guides/form-submission
                                    // formik submits a left join between initialValues and values; values is populated by value parameter in input fields
                                    // required fields and formatting is determined with validationSchema, but this step is elective per field
              // console.log(values);
              dispatch({ type: 'USER_REGISTER', payload: values, admin, navigate });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values
            }) => ( 
              <form onSubmit={handleSubmit}>
                <Box mb={3}>                 
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Create new account
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.DisplayName && errors.DisplayName)}
                  fullWidth
                  helperText={touched.DisplayName && errors.DisplayName}
                  label="Username"
                  margin="normal"
                  name="DisplayName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.DisplayName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.EmailAddress && errors.EmailAddress)}
                  fullWidth
                  helperText={touched.EmailAddress && errors.EmailAddress}
                  label="Email Address"
                  margin="normal"
                  name="EmailAddress"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.EmailAddress}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.Password && errors.Password)}
                  fullWidth
                  helperText={touched.Password && errors.Password}
                  label="Password"
                  margin="normal"
                  name="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.Password}
                  variant="outlined"
                />
                { BypassCaptcha ? "" :                
                  <HCaptcha
                    sitekey = {siteKey}
                    onVerify = { (token) => {
                      setFieldValue('CaptchaResponse', { token }.token)
                    }}
                  />                
                }
                <Box my={2}>
                  <Button
                    // color="primary"
                    sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }}
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign up now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </MyPage>
  );
};

export default RegisterView;