import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../../alerts/Toast';
import getToken from '../../../components/Tokens/GetToken';
import SplitToken from '../../../components/Tokens/SplitToken';

function* AdminSaga() {
    yield takeLatest('ADMIN_GRAB_ALL_USERS', AdminGrabAllUsers);
    yield takeLatest('ADMIN_GET_USERS_FOR_GROUP', AdminGrabGoupUsers);
    yield takeLatest('ADMIN_ADD_NEW_GROUP', AdminAddGroup);
    yield takeLatest('ADMIN_DELETE_USER_GROUP', AdminDeleteGroup);
    yield takeLatest('ADMIN_GRAB_FIRMWARE', AdminGrabFirmware);
    yield takeLatest('ADMIN_CHANGE_USER_STATUS', AdminChangeUserStatus);
    yield takeLatest('ADMIN_DELETE_USER', AdminDeleteUser);
    yield takeLatest('ADMIN_ASSIGN_USER_GROUP_ROLE', AdminAssignUserGroupRole);
    yield takeLatest('ADMIN_USER_UPDATE', AdminUserUpdate);
    yield takeLatest('ADMIN_GRAB_HARDWARE', AdminGrabHardware);
    yield takeLatest('ADMIN_GRAB_GROUPS', AdminGrabGroups);
}

function* AdminGrabAllUsers(action) {
    const token = getToken();
    const user = SplitToken();
    if (!user.isAdmin) {
        return
    }
    try {
        // console.log('in admin grab users', action);
        let pendingUsers = [];
        let approvedUsers = [];
        let suspendedUsers = [];
        // get all users for admin
        let AllUsers = yield axios.get(`api/Account/GetUserList`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        if (AllUsers.status == 200){
            yield put({ type: "ADMIN_SET_ALL_USERS", payload: AllUsers.data });
            for (let user of AllUsers.data) {
                if (user.accountStatus === "Pending") {
                    pendingUsers.push(user);
                } else if (user.accountStatus === "Active") {
                    approvedUsers.push(user);
                } else if (user.accountStatus === "Suspended") {
                    suspendedUsers.push(user);
                }
            }
                yield put({ type: "ADMIN_SET_PENDING_USERS", payload: pendingUsers });
                yield put({ type: "ADMIN_SET_APPROVED_USERS", payload: approvedUsers });
                yield put({ type: "ADMIN_SET_SUSPENDED_USERS", payload: suspendedUsers });
        }
        console.log(pendingUsers)
        console.log(approvedUsers)
        console.log(suspendedUsers)
    } catch (error) {
        console.log('error in fetch all users saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with fetching all users: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }

}

function* AdminGrabGoupUsers(action) {
    try {
        const token = getToken();
        const user = SplitToken();

        let groupUsers = yield axios.get(`api/Groups/AdminGetGroupUsers/${action.payload}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        yield put({ type: "ADMIN_SET_USERS_IN_GROUP", payload: groupUsers.data });
        yield put({ type: "ADMIN_SET_USERS_RETURN_STATUS", payload: groupUsers.status });

    } catch (error) {
        console.log('error in fetch group users saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with fetching users in group: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}
// dispatch({ type: 'ADMIN_GET_USERS_FOR_GROUP', payload: params });

function* AdminAddGroup(action) {
    try {
        // console.log('admin add group', action);
        const token = getToken();
        const user = SplitToken();

        let response = yield axios.post('api/Groups/AdminNewGroup', action.payload, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        // console.log('reponse:', response);
        let AllGroups = yield axios.get(`api/Groups/GetAllGroups`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        yield put({ type: "SET_GROUP_DATA", payload: AllGroups.data })
    } catch (error) {
        console.log('error in create new group saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with creating new group: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

function* AdminDeleteGroup(action) {
    try {
        // console.log('admin delete group', action);
        const token = getToken();
        const user = SplitToken();

        let response = yield axios.delete(`api/Groups/AdminDeleteGroup/${action.payload}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        // console.log('reponse:', response);

        let AllGroups = yield axios.get(`api/Groups/GetAllGroups`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        yield put({ type: "SET_GROUP_DATA", payload: AllGroups.data })
    } catch (error) {
        console.log('error in create new group saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with creating new group: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

function* AdminGrabFirmware(action) {
    try {
        // console.log('admin grab firmware');
        const token = getToken();
        let firmware = yield axios.get(`api/Firmware/GetAllFirmware`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        yield put({ type: "SET_FIRMWARE_DATA", payload: firmware.data });
    } catch (error) {
        console.log('error in grab firmware saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with grab firmware: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

function* AdminChangeUserStatus(action) {
    try {
        console.log('admin change user status', action);
        const token = getToken();
        // let firmware = yield axios.post(`api/Account/AdminChangeUserStatus/${action.userId}/${action.status}`, {}, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        let firmware = yield axios.post(`api/Account/AdminChangeUserStatus`, action.payload, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });

        yield put({ type: "ADMIN_GRAB_ALL_USERS" });
    } catch (error) {
        console.log('error in change user status saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with changing user status: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}
function* AdminDeleteUser(action) {
    try {
        const token = getToken();
        // console.log('admin Delete user status', action);
        yield axios.delete(`api/Account/AdminDeleteUser/${action.userId}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });

        yield put({ type: "ADMIN_GRAB_ALL_USERS" });
    } catch (error) {
        console.log('error in Delete user saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with deleting user: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

function* AdminAssignUserGroupRole(action) {
    try {
        console.log('YES admin assign user role', action);
        const token = getToken();
        yield axios.post(`api/Account/AdminAssignUserGroupRole`, action.payload, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        console.log(action.payload.userId)
        yield put({ type: 'ADMIN_CHANGE_USER_STATUS', payload: { UserId: action.payload.userId, status: 'Active' } });

    } catch (error) {
        console.log('error in assign user group role saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with assigning user: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

function* AdminUserUpdate(action) {
    try {
        // console.log('admin user update', action);
        const token = getToken();
        yield axios.post(`api/Account/AdminUpdateUser`, action.payload, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        yield put({ type: 'ADMIN_GRAB_ALL_USERS' });


    } catch (error) {
        console.log('error in assign user group role saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with assigning user: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

function* AdminGrabHardware(action) {
    try {
        const token = getToken();
        let hardware = yield axios.get(`api/Hardware/GetAllHardware`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        yield put({ type: "SET_HARDWARE_DATA", payload: hardware.data })
    } catch (error) {
        console.log('error in grab firmware saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with grab firmware: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}

function* AdminGrabGroups(action) {
    try {
        const token = getToken();
        let AllGroups = yield axios.get(`api/Groups/GetAllGroups`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        // console.log("retval for fetch all Groups", AllGroups.data);
        yield put({ type: "SET_GROUP_DATA", payload: AllGroups.data });

    } catch (error) {
        console.log('error in grabbing groups saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with grabbing groups: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}
export default AdminSaga;