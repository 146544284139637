import React, { useState } from "react";
import {
    Button,
    Modal,
    Box,
    Card,
    FormControl,
    Select,
    MenuItem,
    TextField
} from "@mui/material";
import {
    DataGrid,
    GridToolbar
} from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import Toast from "../../../alerts/Toast";
import getToken from "../../../components/Tokens/GetToken";
import Popup from "../../../components/Popup";
import UserGroupAssignment from "./UserGroupAssignment";
import theme from "../../../theme";
import { ACTIVE_ACCOUNT_STATUS, PENDING_ACCOUNT_STATUS, SUSPENDED_ACCOUNT_STATUS } from "../../../utils/Constants";


function UserDataGrid(props) {
    const [editRowsModel, setEditRowsModel] = useState({});
    const [userName, setUserName] = useState('');
    const [popChildGroups, setPopChildGroups] = useState(false);
    const [popGroups, setPopGroups] = useState({});
    const [userGroupAssign, setUserGroupAssign] = useState(false);
    const [selectUser, setSelectUser] = useState(null);
    const dispatch = useDispatch();
    const users = props.users;
    const groups = props.groups;
    const type = props.type;
    let editable = false;
    const roleOptions = [
        { id: 0, name: "Read" },
        { id: 1, name: "Write" },
        { id: 2, name: "Admin" },
    ];
    console.log("USERS",users)
    // console.log('groups in grid', groups);
    // console.log('type in user grid', type);
    
    // for the time being, no username or email address will be editable by admins
    if (type === ACTIVE_ACCOUNT_STATUS) {
        editable = true;
    }


    function verifyUserInfoChanged(currentModel, newModel)
    {

        console.log("verifyUserInfoChanged, currentModel", currentModel)
        console.log("verifyUserInfoChanged, newModel", newModel)

        if (Object.values(newModel) !== 0 
            && newModel.id == currentModel.id
            && (newModel.displayName != currentModel.displayName
            || newModel.emailAddress != currentModel.emailAddress
            || newModel.name != currentModel.name))
        {
            return true;
        }

        return false;
    }


    function GroupProcess(params) {
        let g = [];
        for (let group of groups) {
            if (params.row.groupId === group.parentGroupId) {
                g.push({ name: group.name, id: group.id });
                for (let group2 of groups) {
                    if (group.id === group2.parentGroupId) {
                        g.push({ name: group2.name, id: group2.id });
                    }
                }
            }
        }
        if (g.length === 0) {
            g.push({ id: 0, name: "None" })
        }
        setPopGroups(g);
    }

    let columns = [];

    function OpenUserGroupAssignment() {
        setUserGroupAssign(!userGroupAssign);
    };

    if (type === PENDING_ACCOUNT_STATUS) {
        columns.push(
            { field: 'displayName', headerName: 'Username', flex: 1, minWidth: 150, },
            { field: 'emailAddress', headerName: 'Email', flex: 1, minWidth: 150 },
            {
                field: 'approve', headerName: '', flex: 1, minWidth: 150, renderCell: (params) => (
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                            OpenUserGroupAssignment();
                            setSelectUser(params.row);
                        }}
                    >
                        Approve
                    </Button>
                )
            },
            {
                field: 'deny', headerName: '', flex: 1, minWidth: 150, renderCell: (params) => (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            dispatch({ type: 'ADMIN_CHANGE_USER_STATUS', payload: { userId: params.row.id, status: SUSPENDED_ACCOUNT_STATUS } });
                        }}
                    >
                        Deny
                    </Button>
                )
            }
        );
    } else if (type === ACTIVE_ACCOUNT_STATUS) {
        columns.push(
            { field: 'displayName', headerName: 'Username', defaultCellValue: "N/A", flex: 1, minWidth: 150, editable: false, 
                renderEditCell: (params) => {
                    return (
                        <FormControl fullWidth>
                            <TextField 
                                    defaultValue={params.row.name}
                                    variant='outlined'
                                    onChange={(event) => {
                                        console.log(event)
                                        console.log(params)
        
                                        // check whether the group name was changed
                                        if (params.row.name !== event.target.value
                                            && event.target.value !== "")
                                        {
                                            params.row.name = event.target.value;
    
                                        }
    
                                        setEditRowsModel(params.row);
        
                                    }}
                                />
                        </FormControl>
                    )
                } },
            { field: 'emailAddress', headerName: 'Email', flex: 1, minWidth: 150, editable: false, 
                renderEditCell: (params) => {
                    return (
                        <FormControl fullWidth>
                            <TextField 
                                    defaultValue={params.row.name}
                                    variant='outlined'
                                    onChange={(event) => {
                                        console.log(event)
                                        console.log(params)
        
                                        // check whether the group name was changed
                                        // if (params.row.name !== event.target.value
                                        //     && event.target.value !== "")
                                        // {
                                        //     params.row.name = event.target.value;
    
                                        // }
    
                                        // check whether the parent group value was also changed
                                        // if (params.row.parentGroupId !== editRowsModel.parentGroupId)
                                        // {
                                        //     params.row.parentGroupId = editRowsModel.parentGroupId;
                                        //     params.row.parentGroupName = editRowsModel.parentGroupName;
        
                                        // }
    
                                        setEditRowsModel(params.row);
        
                                    }}
                                />
                        </FormControl>
                    )
                } },
            { field: 'isEmailConfirmed', headerName: 'Email Verified', flex: 1, minWidth: 175 },
            // {
            //     field: 'groupId', headerName: 'Primary Group', flex: 1, minWidth: 150, editable: editable, renderCell: ((params) => {
            //         if (params.row.groupName) {
            //             return params.row.groupName
            //         } else if (params.row.groupId) {
            //             for (let group of groups) {
            //                 if (group.id === params.row.groupId) {
            //                     params.row.groupName = group.name;
            //                     return group.name
            //                 } else {
            //                     return ""
            //                 }
            //             }
            //         } else {
            //             return ""
            //         }

            //     }),
            //     renderEditCell: (params) => {
            //         return (
            //             <FormControl fullWidth>
            //                 <Select
            //                     defaultValue={params.row.groupName}
            //                     onChange={(event, newValue) => {
            //                         Object.values(editRowsModel)[0].groupId.value = newValue.props.id;
            //                     }}
            //                 >
            //                     {groups.map((x, i) => {
            //                         return <MenuItem key={i} value={x.name} id={x.id}>{x.name}</MenuItem>
            //                     })}
            //                 </Select>
            //             </FormControl>
            //         )
            //     },
            // },
            {
                field: 'groupRoleName', headerName: "User's Permissions", flex: 1, minWidth: 150, editable: editable, renderEditCell: (params) => {
                    return (
                        <FormControl fullWidth>
                            <Select
                                defaultValue={params.row.userRole}
                                disabled={params.row.groupName ? false : true}
                                onChange={(event, newValue) => {
                                    // Object.values(editRowsModel)[0].userRole.value = newValue.props.children;
                                    dispatch({ type: 'ADMIN_ASSIGN_USER_GROUP_ROLE', payload: { userId: params.row.id, RoleName: newValue.props.children } });
                                }}
                            >
                                {roleOptions.map((x, i) => {
                                    return <MenuItem key={i} value={x.id} >{x.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    )
                },
            },
            // {
            //     field: 'children', headerName: '', flex: 1, minWidth: 150, renderCell: (params) => (
            //         <Button
            //             variant="contained"
            //             // color="primary"
            //             sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }}
            //             onClick={() => {
            //                 GroupProcess(params);
            //                 setPopChildGroups(true);
            //                 setUserName(params.row.displayName);
            //             }}
            //         >
            //             Child Groups
            //         </Button>
            //     )
            // },
            {
                field: 'deny', headerName: '', flex: 1, minWidth: 150, renderCell: (params) => (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            dispatch({ type: 'ADMIN_CHANGE_USER_STATUS', payload: { userId: params.row.id, status: SUSPENDED_ACCOUNT_STATUS } });
                        }}
                    >
                        Deny
                    </Button>
                )
            }
        );
    } else if (type === SUSPENDED_ACCOUNT_STATUS) {
        columns.push(
            { field: 'displayName', headerName: 'Username', flex: 1, minWidth: 150, },
            { field: 'emailAddress', headerName: 'Email', flex: 1, minWidth: 150 },
            { field: 'isEmailConfirmed', headerName: 'Email address verified', flex: 1, minWidth: 150 },
            {
                field: 'approve', headerName: '', flex: 1, minWidth: 150, renderCell: (params) => (
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                            dispatch({ type: 'ADMIN_CHANGE_USER_STATUS', payload: { userId: params.row.id, status: ACTIVE_ACCOUNT_STATUS } });
                        }}
                    >
                        Approve
                    </Button>
                )
            },
            {
                field: 'delete', headerName: '', flex: 1, minWidth: 150, renderCell: (params) => (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            dispatch({ type: 'ADMIN_DELETE_USER', userId: params.row.id });
                        }}
                    >
                        Delete
                    </Button>
                )
            }
        )
    }

    const childColumns = [
        { field: 'name', headerName: 'Child Group', flex: 1, minWidth: 150 },
    ]

    const handleSave = () => {
        const objId = Object.keys(editRowsModel)[0]
        const obj = Object.values(editRowsModel)[2]

        if (!(/\S+@\S+\.\S+/.test(obj.emailAddress.value))) {
            return Toast.fire({
                icon: 'error',
                title: 'Please enter a valid email address'
            })
        }
        // console.log('obj id', objId);
        // console.log('obj', obj);

        const item = {
            Id: objId,
            EmailAddress: obj.emailAddress.value,
            UserName: obj.userName.value,
            GroupId: obj.groupId.value,
            RoleName: obj.userRole.value ? obj.userRole.value : "Read",
        };
        // console.log("save post item", item);
        dispatch({ type: "ADMIN_USER_UPDATE", payload: item });
    }

    const handleEditRowsModelChange = React.useCallback((model) => {
        // console.log("**** model change", model)
        // console.log("**** edit row model", editRowsModel);
        setEditRowsModel(model);
    }, []);

    return (
        <Box>
            <Card sx={{ height: 500 }}>
                <DataGrid
                    title="Users"
                    editRowsModel={editRowsModel}
                    editMode="cell"
                    onCellEditCommit={handleSave}
                    onEditRowsModelChange={handleEditRowsModelChange}
                    columns={columns}
                    rows={users}
                    slots={{
                        Toolbar: GridToolbar
                    }}
                />
            </Card>
            <Popup close={() => setPopChildGroups(false)} open={popChildGroups}>
                <Box style={{ textAlign: "center", height: '80%', margin: 20, marginTop: "5%" }}>
                    <h3 style={{ marginBottom: "10px" }}><b>The child groups that {userName} has access to:</b></h3>
                    <DataGrid
                        title="Groups"
                        columns={childColumns}
                        rows={popGroups}
                    />
                </Box>
            </Popup>
            <Modal close={() => setUserGroupAssign(false)} open={userGroupAssign}>
                <Box style={{ textAlign: "center", height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <UserGroupAssignment groups={groups} user={selectUser} close={OpenUserGroupAssignment} />
                </Box>
            </Modal>
        </Box>
    )

}

export default UserDataGrid;