import React, {useCallback} from 'react';
import {
    Box,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    TextField,
    Checkbox,
    FormControlLabel,
    Select,
    InputLabel,
    FormControl,
    Container,
    MenuItem
} from '@mui/material';
import { Folder, User } from 'react-feather';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from "react-redux";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import splitToken from '../../components/Tokens/SplitToken';
import Swal from "sweetalert2";
import base64ArrayBuffer from "../../components/base64Encoder";

const FileUploadPage = React.forwardRef((props, ref) => {
    /// manual file upload
    const dispatch = useDispatch();
    let user = splitToken();
    console.log("USER", user);

    const [fileType, setFileType] = React.useState();
    const [file, setFile] = React.useState([]);
    const [device, setDevice] = React.useState();
    const [date, setDate] = React.useState( new AdapterDayjs());
    const [physician, setPhysician] = React.useState();
    const [site, setSite] = React.useState();
    const [caseType, setCaseType] = React.useState();
    const [ebl, setEbl] = React.useState();
    const [wip, setWip] = React.useState(false);
    const [rhvip, setRhvip] = React.useState(false);
    const [ais, setAis] = React.useState(false);
    const [su, setSu] = React.useState(false);
    const [caseNotes, setCaseNotes] = React.useState();

    const [caseFile, setCaseFile] = React.useState();

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
          const reader = new FileReader()
    
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {
          // Do whatever you want with the file contents
            const binaryStr = reader.result;
            let encodedString = base64ArrayBuffer(binaryStr);
            console.log("SECOND BASE 64", encodedString);
            setCaseFile(encodedString);
            console.log(binaryStr);
          }
          reader.readAsArrayBuffer(file)
        })
        
      }, [])

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({onDrop});

    let dataToSend = {
        jsonFileFormatVersion: 1,
        syslogDeviceDescription: device,
        syslogDeviceMicroUUID: null,
        syslogDeviceMicroFwUUID: null,
        syslogCaseUUID: null,
        syslogProcedureDate: date,
        physician: physician,
        site: site,
        caseType: caseType,
        estimatedBloodLoss: Number(ebl),
        estimatedClotSize: Number(0),
        wireInPlace: wip,
        rhvInPlace: rhvip,
        airInSystem: ais,
        separatorUsed: su,
        representativeName: user.userName,
        caseNotes: caseNotes,
        additionalJsonData: null,
        syslogData: caseFile,
        casePhoto: null,
    }

    console.log("DATA", dataToSend);
    console.log("File", file);

    React.useEffect(() => {
        setFile(acceptedFiles);
    },[acceptedFiles],);

    const files = acceptedFiles.map(file => (
        <Tooltip key={file.path} title="Click to select different file">
            <ListItem sx={{ border: '1px solid black' }}>
                    <ListItemIcon>
                        <Folder/>
                    </ListItemIcon>
                    <ListItemText>
                        {file.path} - {file.size} bytes
                    </ListItemText>
            </ListItem>
        </Tooltip>
    ));

    function finishFileUpload(){
        if (!fileType || !caseFile || !device || !date || !physician || !site || !caseType || !ebl || !caseNotes || !file){
            Swal.fire({
                icon: "error",
                title: "Incomplete Form",
                text: "Please fill out all fields.",
                showCancelButton: "error",
            })
        } else {
            dispatch({ type: "UPLOAD_FILE", payload: dataToSend});
        }
    };

    return (
        <Box style={{ margin: '5%', justifyContent:'center', display: 'flex',}}>
            <div style={{ border: '2px solid #a72525', padding: 20, borderRadius:5}}>
                <h1 style={{textAlign:'center', borderBottom: '2px solid #a72525', paddingBottom: 5}}>Upload File</h1>
                <div style={{display: 'flex', flexDirection:'row'}}>
                    <div style={{flex:1, display: 'flex', flexDirection:'column', margin:15, width:'50%'}}>
                    {/* <label>Enter a custom <b>File Name</b>. Leave blank for <b>Default</b> file name generation.</label> */}
                    <FormControl style={{width:'300px', margin: '10px', display: 'flex', flexDirection:'row'}}>
                        <h4 style={{flex:1}}>File Type:</h4>
                        <Select style={{flex:2}} 
                            value={fileType} onChange={(event) => setFileType(event.target.value)}
                        >
                            <MenuItem value={'Binary File'}>Binary File</MenuItem>
                        </Select>
                    </FormControl>
                    <Box sx={{width:'300px', margin: '10px', display: 'flex', flexDirection:'row'}}>
                        <h4 style={{flex:1}}>File:</h4>
                        <Box 
                            style={{borderWidth: '2px', borderRadius: '5px', borderStyle: 'dashed', flex:2, textAlign:'center'}}
                            {...getRootProps({className: 'dropzone'})}
                        >
                            <input {...getInputProps()}/>
                            { acceptedFiles.length > 0 ? <List style={{margin:'auto'}}>{files}</List> : <p>Drag 'n' drop file</p> }
                        </Box>
                    </Box>
             
                    <FormControl style={{display:'flex', flexDirection:'row', alignItems:'center', margin: 10, height:50, justifyContent:'space-between'}}>
                        <h4 style={{flex:1}}>Device:</h4>
                        <Select style={{flex:2}}
                            value={device} onChange={(event) => setDevice(event.target.value)}
                        >
                            <MenuItem value={'Kirby'}>Kirby</MenuItem>
                            <MenuItem value={'Kirby'}>Link</MenuItem>
                            <MenuItem value={'Kirby'}>Jafar</MenuItem>
                            <MenuItem value={'Kirby'}>Alf</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl style={{display:'flex', flexDirection:'row', alignItems:'center', margin: 10, height:50, justifyContent:'space-between'}}>
                        {/* <Label htmlFor="outlined-adornment-amount">Expiration Date</Label> */}
                        <h4 style={{flex:1}}>Procedure Date:</h4>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker 
                        value={date} 
                        onChange={(newValue) => setDate(newValue)}
                        renderInput={ (params) => <TextField {...params} style={{flex:2}}/>}
                        />
                        </LocalizationProvider>
                    </FormControl>

                    <FormControl style={{display:'flex', flexDirection:'row', alignItems:'center', margin: 10, height:50, justifyContent:'space-between'}}>
                        <h4 style={{flex:1}}>Physician:</h4>
                        <TextField placeholder='<enter name>' style={{flex:2}} value={physician} onChange={(event) => setPhysician(event.target.value)}></TextField>
                    </FormControl>
                    </div>
                    <div style={{flex:1, display:'flex', flexDirection:'column', margin: 15, width:'50%'}}>
                    <FormControl style={{display:'flex', flexDirection:'row', alignItems:'center', margin: 10, height:50, justifyContent:'space-between'}}>
                        <h4 style={{flex:1}}>Site:</h4>
                        <TextField placeholder='<enter site>' style={{flex:2}} value={site} onChange={(event) => setSite(event.target.value)}></TextField>
                    </FormControl>
                    <FormControl style={{display:'flex', flexDirection:'row', alignItems:'center', margin: 10, height:50, justifyContent:'space-between'}}>
                        <h4 style={{flex:1}}>Case Type:</h4>
                        <Select style={{flex:2}} value={caseType} onChange={(event) => setCaseType(event.target.value)}>
                            <MenuItem value={'Kirby'}>Arterial Peripheral</MenuItem>
                            <MenuItem value={'Kirby'}>Arterial Mesenteric</MenuItem>
                            <MenuItem value={'Kirby'}>Pulmonary Ebmolism</MenuItem>
                            <MenuItem value={'Kirby'}>Venous Iliofemoral</MenuItem>
                            <MenuItem value={'Kirby'}>Venous Infrainguinal</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl style={{display:'flex', flexDirection:'row', alignItems:'center', margin: 10, height:50, justifyContent:'space-between'}}>
                        <h4 style={{flex:1}}>EBL:</h4>
                        <TextField placeholder="<enter amount>" style={{flex:2}} value={ebl} onChange={(event) => setEbl(event.target.value)}></TextField>
                    </FormControl>
                    <div style={{display:'flex', alignItems:'center', margin: 10, height:25,}}><Checkbox value={wip} onChange={(event) => setWip(!wip)}/><h4>Wire In Place </h4></div>
                    <div style={{display:'flex', alignItems:'center', margin: 10, height:25,}}><Checkbox value={rhvip} onChange={(event) => setRhvip(!rhvip)}/><h4>RHV In Place </h4></div>
                    <div style={{display:'flex', alignItems:'center', margin: 10, height:25,}}><Checkbox value={ais} onChange={(event) => setAis(!ais)}/><h4>Air In System </h4></div>
                    <div style={{display:'flex', alignItems:'center', margin: 10, height:25,}}><Checkbox value={su} onChange={(event) => setSu(!su)}/><h4>Separator Used </h4></div>
                    </div>
                </div>
                <div style={{flex:1, display: 'flex', flexDirection:'column', margin:15,}}>
                <TextField
                    id="outlined-multiline-static"
                    label="Case Notes"
                    multiline
                    rows={4}
                    value={caseNotes} 
                    onChange={(event) => setCaseNotes(event.target.value)}
                />
                </div>
                <div style={{flex:1, display: 'flex', flexDirection:'column', margin:15,}}>
                    <Button style={{width:'50%', margin:'auto', color:'#a72525', backgroundColor:'#fff', border: '2px solid #a72525'}} variant="contained" onClick={finishFileUpload} >Upload</Button>
                </div>
            </div>
        </Box>
    )
});

export default FileUploadPage;