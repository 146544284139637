import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { render } from 'react-dom';
import { Edit } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import theme from '../../theme';

const DeviceConfig = (props) => { // make it close
    const config = useSelector((state) => state.DeviceConfigReducer.DeviceConfig);
    const status = useSelector((state) => state.DeviceConfigReducer.ConfigStatus);
    const dispatch = useDispatch();
    const [edit, setEdit] = useState(false)
    const [measRate, setMeasRate] = useState('');
    const [repMeasCount, setRepMeasCount] = useState('');
    const [repMeasRate, setRepMeasRate] = useState('');
    const [methaneThreshold, setMethaneThreshold] = useState('');
    const [maxHatchAngle, setMaxHatchAngle] = useState('');
    const [minHatchAngle, setMinHatchAngle] = useState('');
    // const [newLat, setNewLat] = useState(props.device.latitude);
    // const [newLong, setNewLong] = useState(props.device.longitude);
    // console.log('#$ config in popup is:', config);
    // console.log('#$ status in popup is:', status);
    // console.log('*props', props);
    let device = props.device

    function handleEdit() {
        setEdit(!edit)
        setMeasRate(config.measurementRate)
        setRepMeasCount(config.repeatMeasCount)
        setRepMeasRate(config.repeatMeasRate)
        setMethaneThreshold(config.methaneThreshold)
        setMaxHatchAngle(config.hatchAngleMax)
        setMinHatchAngle(config.hatchAngleMin)
        // setNewLat(device.latitude)
        // setNewLong(device.longitude)
    }

    function handleSubmit() {
        const data = {
            deviceId: device.id,
            measurementRate: measRate,
            repeatMeasCount: repMeasCount,
            repeatMeasRate: repMeasRate,
            methaneThreshold,
            hatchAngleMax: maxHatchAngle,
            hatchAngleMin: minHatchAngle,
            // latitude: newLat,
            // longitude: newLong,
        }
        // console.log('data to send', data);
        dispatch({ type: 'DEVICE_CONFIG_EDIT', payload: data, id: device.id, })
        // setEdit(false);
        props.close();// trying to update the device array with the changed data for the updated device
    }

    if (!status === 0) {
        return (
            <div>
                <h3>Loading . . . </h3>
            </div>
        )
    }

    else {
        return (
            <div style={{ margin: 25 }}>
                <span style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <h1>Device Config</h1>
                    <IconButton onClick={() => handleEdit()}>
                        <Edit />
                    </IconButton>
                    {edit ?
                        <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                            <Typography textAlign={"center"} color={"red"} variant='h4'>
                                Editing Mode
                            </Typography>
                            <Typography textAlign={"center"} variant='body2'>
                                Double click to enter row, hit enter or click out to save changes. <br /> Click the Icon again to cancel editing.
                            </Typography>
                        </Box>
                        :
                        <></>
                    }
                </span>
                <h3>Device Name: <span style={{ color: 'blue' }}> { device.name ? device.name : ""}</span></h3>
                {/* <h3>Device IMEI: <span style={{ color: 'blue' }}> {device.imei ? device.imei : ""}</span></h3> */}
                {/* <h3>Repeat Measurement Count: {edit ? <TextField style={{ marginTop: '-15px' }} label="Repeat Measurement Count" margin="normal" variant="outlined" value={repMeasCount} onChange={(event) => setRepMeasCount(event.target.value)}>  </TextField> : <span style={{ color: 'blue' }}> {config.repeatMeasCount >= 0 ? config.repeatMeasCount : ""}</span>}</h3>
                <h3>Repeat Measurement Rate: {edit ? <TextField style={{ marginTop: '-15px' }} label="Repeat Measurement Rate" margin="normal" variant="outlined" value={repMeasRate} onChange={(event) => setRepMeasRate(event.target.value)}>  </TextField> : <span style={{ color: 'blue' }}> {config.repeatMeasRate >= 0 ? config.repeatMeasRate : ""}</span>}</h3>
                <h3>Methane Threshold: {edit ? <TextField style={{ marginTop: '-15px' }} label="Methane Threshold" margin="normal" variant="outlined" value={methaneThreshold} onChange={(event) => setMethaneThreshold(event.target.value)}>  </TextField> : <span style={{ color: 'blue' }}> {config.methaneThreshold >= 0 ? config.methaneThreshold : ""} (ppm/ppb?)</span>}</h3>
                <h3>Hatch Angle Maximum: {edit ? <TextField style={{ marginTop: '-15px' }} label="Hatch Angle Maximum" margin="normal" variant="outlined" value={maxHatchAngle} onChange={(event) => setMaxHatchAngle(event.target.value)}>  </TextField> : <span style={{ color: 'blue' }}> {config.hatchAngleMax >= 0 ? config.hatchAngleMax : ""}°</span>}</h3>
            <h3>Hatch Angle Minimum: {edit ? <TextField style={{ marginTop: '-15px' }} label="Hatch Angle Minimum" margin="normal" variant="outlined" value={minHatchAngle} onChange={(event) => setMinHatchAngle(event.target.value)}>  </TextField> : <span style={{ color: 'blue' }}> {config.hatchAngleMin >= 0 ? config.hatchAngleMin : ""}°</span>}</h3> */}
                <h3>Config 1: <span style={{ color: 'blue' }}></span></h3>
                <h3>Config 2: <span style={{ color: 'blue' }}></span></h3>
                <h3>Config 3: <span style={{ color: 'blue' }}></span></h3>
                <h3>Config 4: <span style={{ color: 'blue' }}></span></h3>
                <h3>Report Rate: {edit ? <TextField style={{ marginTop: '-15px' }} label="Measurement Rate (days)" margin="normal" variant="outlined" value={measRate} onChange={(event) => setMeasRate(event.target.value)}>  </TextField> : <span style={{ color: 'blue' }}> {config.measurementRate >= 0 ? config.measurementRate : ""} Hours</span>}</h3>
                <h3>Most Recent Update: <span style={{ color: 'blue' }}> {moment.utc(config.updated).local().format('M/D/YY, HH:mm ')}</span></h3>
                {/* <h3>Location (Lat/Long): {edit ? <> <TextField style={{ marginTop: '-15px' }} label="Latitude" margin="normal" variant="outlined" value={newLat} onChange={(event) => setNewLat(event.target.value)}>  </TextField>
                    / <TextField style={{ marginTop: '-15px' }} label="Longitude" margin="normal" variant="outlined" value={newLong} onChange={(event) => setNewLong(event.target.value)}>  </TextField> </>
                    : <span style={{ color: 'blue' }}> {newLat}/{newLong}</span>}</h3> */}
                {edit ? <Button variant='contained' sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }} onClick={() => handleSubmit()} > Submit </Button> : ""}
            </div>
        )
    }
}

export default DeviceConfig;
