import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Typography,
} from "@mui/material";
import CasePhotoDisplay from './CasePhotoDisplay';

function InfoModal({item, style}){
  const dispatch = useDispatch();
  /// displays message from customer for processor
  // console.log(item)
  let placeHolderStats = {data: 'N/A'};
  console.log(item.calculatedStatisticsJson);
  let parsedData = {};
  if(item.calculatedStatisticsJson != null){
    parsedData = JSON.parse(item.calculatedStatisticsJson);
  }
  let placeHolderExtraData = {data: 'N/A'};
  console.log(item.calculatedStatisticsJson);
  let parsedAdditionalData = {};
  if(item.additionalJsonData != null){
    parsedAdditionalData = JSON.parse(item.additionalJsonData);
  }

  const CasePhoto = useSelector((state) => state.CasePhotoReducer.CasePhoto);
  console.log("PHOTO", CasePhoto)
  const [photo, setPhoto] = React.useState(CasePhoto.casePhoto);
  console.log("PHOTO", photo)

  React.useEffect(() => {
    // dispatch({ type: 'GET_COMPANY_INVOICES', payload: files[0]?.companyId });
    // dispatch({ type: 'GET_CASE_PHOTO', payload: item.casePhotoId });
    setPhoto(CasePhoto.casePhoto);
  },[CasePhoto]);

    return(
        <Box sx={style} style={{display: 'flex', justifyContent: 'space-between', width: "65%"}}>
          <Box stlye={{flex:1}}>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              Syslog Case Information
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 4 }}>
              <span>Case ID: {item.syslogCaseUUID}</span>
              <br/>
              <span>Date: {item.syslogProcedureDate}</span>
              <br/>
              <span>Type: {item.caseType}</span>
              <br/>
              <span>Site: {item.site}</span>
              <br/>
              <span>Physician: {item.physician}</span>
              <br/>
              <span>Device: {item.syslogDeviceDescription}</span>
              <br/>
              <span>EBL: {item.estimatedBloodLoss} mL</span>
              <br/>
              <span>Clot Size: {item.estimatedClotSize}</span>
              <br/>
              { item.wireInPlace == true ?
              <span>Wire In Place: True</span>
              :
              <span>Wire In Place: False</span>
              }<br/>   
              { item.airInSystem == true ?
              <span>Air In System: True</span>
              :
              <span>Air In System: False</span>
              }<br/>   
              { item.separatorUsed == true ?
              <span>Separator Used: True</span>
              :
              <span>Separator Used: False</span>
              }<br/>   
              { item.rhvInPlace == true ?
              <span>RHV In Place: True</span>
              :
              <span>RHV In Place: False</span>
              }<br/>   
              <span>Representative: {item.representativeName}</span>
              <br/>
              <span>Case Notes: <br/> {item.caseNotes}</span>
              <br/>
              <span>Statistics: <br/> 
              {/* {item.calculatedStatisticsJson} */}
              {/* {item.calculatedStatisticsJson.map((statItem, i) =>(
                <span>{statItem}</span>
              ))} */}
              </span>
              {item.calculatedStatisticsJson != null ?
                <div>
                {
                  Object.entries(parsedData).map(([key, val]) => (
                      <span style={{marginLeft: 20}} key={key}>{key}: {val}<br/></span>
                  ))
                }
                </div>
              :
                <div>
                {
                  Object.entries(placeHolderStats).map(([key, val]) => (
                      <span style={{marginLeft: 20}} key={key}>{key}: {val}<br/></span>
                  ))
                }
                </div>
              }
              <br/>
              <span>Additional Data: <br/></span>
              {item.additionalJsonData != null ?
                <div>
                {
                  Object.entries(parsedAdditionalData).map(([key, val]) => (
                      <span style={{marginLeft: 20}} key={key}>{key}: {val}<br/></span>
                  ))
                }
                </div>
              :
                <div>
                {
                  Object.entries(placeHolderExtraData).map(([key, val]) => (
                      <span style={{marginLeft: 20}} key={key}>{key}: {val}<br/></span>
                  ))
                }
                </div>
              }
              <br/>
              {/* <span>{item.casePhotoId}</span>
              <br/>
              <span>{item.fileFormatVersion}</span>
              <br/>
              <span>{item.syslogDataId}</span>
              <br/>
              <span>{item.syslogMicroFwUUID}</span>
              <br/>
              <span>{item.syslogMicroFwUUID}</span>
              <br/> */}

            </Typography>
          </Box>
          <CasePhotoDisplay style={{flex:1}} photo={photo}/>
        </Box>
    )
}

export default InfoModal;