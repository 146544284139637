import React from 'react';
import { TextField, Button, Typography, Stepper, Step, StepLabel, Breadcrumbs } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { connect, useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/system';
import Toast from '../../alerts/Toast';
import theme from '../../theme';
import getToken from '../../components/Tokens/GetToken';
import { useState } from 'react';

const MyButton = styled(Button)({
    width: '30%',
    minHeight: '50px'
})

const Input = styled(TextField)({
    margin: '10px'
})

const DropDown = styled('div')({
    display: 'flex',
    justifyContent: 'center'
})

const Submit = styled('div')({
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between'
})

const Paper = styled('div')({
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    // border: '1px solid black'
})

const Form = styled('div')({
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
})

function getSteps() {
    return ['Add New Device', 'Device Configuration'];
}

const CreateDevice = React.forwardRef((props, ref) => {
    const [activeStep, setActiveStep] = useState(0);
    const [imei, setImei] = useState(null);
    const [hardware, setHardware] = useState(null);
    const [firmware, setFirmware] = useState(null);
    const [name, setName] = useState(null);
    const [iccid, setIccid] = useState(null);
    const [EID, setEID] = useState(null);
    const [lat, setLat] = useState(null);
    const [long, setLong] = useState(null);
    const [hardwareSerial, setHardwareSerial] = useState(null);
    const [group, setGroup] = useState(null);

    const [measRate, setMeasRate] = useState(720);
    const [repMeasCount, setRepMeasCount] = useState(3);
    const [repMeasRate, setRepMeasRate] = useState(5);
    const [methaneThreshold, setMethaneThreshold] = useState(2);
    const [maxHatchAngle, setMaxHatchAngle] = useState(15);
    const [minHatchAngle, setMinHatchAngle] = useState(0);

    const groups = props.groups
    const dispatch = useDispatch();
    const steps = getSteps();
    // const canGroups = useSelector((state) => state.deviceList.canGroup);
    // console.log('props in create device', props);

    const handleChange = (type, event) => {
        switch (type) {
            case 'imei':
                return setImei(event.target.value);
            case 'hardware':
                return setHardware(event);
            case 'hardwareSerial':
                return setHardwareSerial(event.target.value);
            case 'firmware':
                return setFirmware(event);
            case 'name':
                return setName(event.target.value);
            // case 'config':
            //     return setConfig(event.target.value);
            case 'group':
                return setGroup(event)
            case 'eID':
                return setEID(event.target.value)
            case 'ICCID':
                return setIccid(event.target.value)
            case 'lat':
                return setLat(event.target.value)
            case 'long':
                return setLong(event.target.value)
            default:
                break;
        }
    }

    const handleNext = () => {
        if (activeStep === 0) {
            if (name === null) {
                return Toast.fire({
                    icon: 'error',
                    title: 'Please enter Device Name'
                })
            }
            else if (imei === null) {
                return Toast.fire({
                    icon: 'error',
                    title: 'Please enter external ID'
                })
            }
            else if (group === null) {
                return Toast.fire({
                    icon: 'error',
                    title: 'Please select Machine Group'
                })
            }
            else if (hardware === null) {
                return Toast.fire({
                    icon: 'error',
                    title: 'Select hardware version'
                })
            }
            else if (firmware === null) {
                return Toast.fire({
                    icon: 'error',
                    title: 'Select firmware version'
                })
            }
            else if (hardwareSerial === null) {
                return Toast.fire({
                    icon: 'error',
                    title: 'Please Enter a Hardware Serial Number'
                })
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        } else if (activeStep === steps.length - 1) {
            if (measRate < 0 ) {
                return Toast.fire({
                    icon: 'error',
                    title: 'Please enter valid Measurement Rate'
                })
            }
            else if (repMeasCount < 0) {
                return Toast.fire({
                    icon: 'error',
                    title: 'Please enter valid Repeat Measurement Count'
                })
            }
            else if (repMeasRate < 0) {
                return Toast.fire({
                    icon: 'error',
                    title: 'Please enter valid Repeat Measurement Rate'
                })
            }
            else if (maxHatchAngle === null) {
                return Toast.fire({
                    icon: 'error',
                    title: 'Please enter valid Hatch Angle Maximum'
                })
            }
            else if (minHatchAngle === null) {
                return Toast.fire({
                    icon: 'error',
                    title: 'Please enter valid Hatch Angle Minimum'
                })
            }
            else if (methaneThreshold === null) {
                return Toast.fire({
                    icon: 'error',
                    title: 'Please enter valid Methane Threshold'
                })
            }
            const item = {
                Name: name,
                FirmwareVersionId: firmware,
                HardwareVersionId: hardware,
                HardwareSerialNumber: hardwareSerial,
                ICCID: iccid,
                eID: EID,
                IMEI: imei,
                GroupId: group,
                Latitude: lat ? lat : 47,
                Longitude: long ? long : -97,

                measurementRate: Number(measRate),
                repeatMeasCount: Number(repMeasCount),
                repeatMeasRate: Number(repMeasRate),
                methaneThreshold: Number(methaneThreshold),
                hatchAngleMax: Number(maxHatchAngle),
                hatchAngleMin: Number(minHatchAngle),

            };
            console.log('item to submit', item);
            dispatch({ type: "CREATE_NEW_DEVICE", payload: item })
            props.close();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        // setGroup([]);
        // setPermission([]);
        // setEmail(null);
        // setUser(null);
        // setPass(null);
        // setConfirm(null);
        setActiveStep(0);
    };

    function getStepContent(stepIndex) {
        let display = ''
        switch (stepIndex) {
            case 0:
                display = (
                    <>
                        <div style={{ textAlign: 'center', marginTop: -200, marginBottom: 30 }}>
                            <Typography
                                variant="h3"
                            >
                                ADD NEW DEVICE
                            </Typography>
                        </div>
                        <Form>
                            <DropDown style={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                                <Input
                                    required
                                    id="Hardware Serial"
                                    label="Hardware Serial Number"
                                    defaultValue={hardwareSerial}
                                    variant="outlined"
                                    onChange={(event) => handleChange('hardwareSerial', event)}
                                    style={{ width: '300px' }}
                                />
                                <Autocomplete
                                    id="Hardware *"
                                    label="Hardware *"
                                    options={props.hardware}
                                    style={{ width: '300px', margin: '10px' }}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            handleChange('hardware', newValue.id)
                                        }
                                        else {
                                            return
                                        }
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Hardware Version" variant="outlined" />}
                                />
                            </DropDown>
                            <DropDown>
                                <Autocomplete
                                    id="Firmware"
                                    options={props.firmware}
                                    sx={{ width: '300px', margin: '10px' }}
                                    onChange={(event, newValue) => {
                                        handleChange('firmware', newValue.id)
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Firmware Version" variant="outlined" />}
                                />
                                <Autocomplete
                                    required
                                    id="Group"
                                    options={groups}
                                    sx={{ width: '300px', margin: '10px' }}
                                    onChange={(event, newValue) => {
                                        handleChange('group', newValue.id)
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Group" variant="outlined" />}
                                />
                            </DropDown>
                            <div>
                                <Input
                                    required
                                    id="IMEI"
                                    label="IMEI"
                                    defaultValue={imei}
                                    variant="outlined"
                                    onChange={(event) => handleChange('imei', event)}
                                    style={{ width: '300px' }}
                                />
                                <Input
                                    required
                                    id="Name"
                                    label="Name"
                                    defaultValue={name}
                                    variant="outlined"
                                    onChange={(event) => handleChange('name', event)}
                                    style={{ width: '300px' }}
                                />
                            </div>
                            <div>
                                <Input
                                    required
                                    id="ICCID"
                                    label="ICCID"
                                    defaultValue={iccid}
                                    variant="outlined"
                                    onChange={(event) => handleChange('ICCID', event)}
                                    style={{ width: '300px' }}
                                />
                                <Input
                                    id="eID"
                                    label="eID"
                                    defaultValue={EID}
                                    variant="outlined"
                                    onChange={(event) => handleChange('eID', event)}
                                    style={{ width: '300px' }}
                                />
                            </div>
                            <div>
                                <Input
                                    required
                                    id="lat"
                                    label="Latitude (Decimal)"
                                    defaultValue={lat}
                                    variant="outlined"
                                    onChange={(event) => handleChange('lat', event)}
                                    style={{ width: '300px' }}
                                />
                                <Input
                                    id="long"
                                    label="Longitude (decimal)"
                                    defaultValue={long}
                                    variant="outlined"
                                    onChange={(event) => handleChange('long', event)}
                                    style={{ width: '300px' }}
                                />
                            </div>
                            <br />
                        </Form>
                    </>
                    // <Submit>
                    //     <MyButton
                    //         variant="contained"
                    //         color="primary"
                    //         onClick={props.close}
                    //     >
                    //         Cancel
                    //     </MyButton>
                    //     <MyButton
                    //         variant="contained"
                    //         color="primary"
                    //         onClick={handleNext}
                    //     >
                    //         {activeStep === (steps.length - 1) ? 'Submit' : 'Next' }
                    //     </MyButton>
                    // </Submit>
                )
                return display;
            case 1:
                display = (
                    <>
                        <div style={{ textAlign: 'center', marginTop: -200, marginBottom: 30 }}>
                            <Typography
                                variant="h3"
                            >
                                ADD DEVICE CONFIGURATION
                            </Typography>
                        </div>
                        <Form>
                            <div>
                                <TextField
                                    required
                                    type="number"
                                    id="Measurement Rate"
                                    label="Measurement Rate (minutes) *"
                                    defaultValue={measRate}
                                    variant="outlined"
                                    onChange={(event) => setMeasRate(event.target.value)}
                                    sx={{ width: '300px', margin: '10px' }}
                                />
                                <TextField
                                    required
                                    type='number'
                                    id="Repeat Measurement Count"
                                    label="Repeat Measurement Count *"
                                    variant="outlined"
                                    defaultValue={repMeasCount}
                                    style={{ width: '300px', margin: '10px' }}
                                    onChange={(event) => setRepMeasCount(event.target.value)}
                                />
                            </div>
                            <div>
                                <TextField
                                    required
                                    type='number'
                                    id="Repeat Measurement Rate"
                                    label="Repeat Measurement Rate *"
                                    variant="outlined"
                                    defaultValue={repMeasRate}
                                    sx={{ width: '300px', margin: '10px' }}
                                    onChange={(event) => setRepMeasRate(event.target.value)}
                                />
                                <TextField
                                    required
                                    type='number'
                                    id="Methane Threshold"
                                    label="Methane Threshold"
                                    variant="outlined"
                                    defaultValue={methaneThreshold}
                                    sx={{ width: '300px', margin: '10px' }}
                                    onChange={(event) => setMethaneThreshold(event.target.value)}
                                />
                            </div>
                            <div>
                                <TextField
                                    required
                                    type='number'
                                    id="Hatch Angle Maximum"
                                    label="Hatch Angle Maximum °"
                                    defaultValue={maxHatchAngle}
                                    variant="outlined"
                                    onChange={(event) => setMaxHatchAngle(event.target.value)}
                                    sx={{ width: '300px', margin: '10px' }}
                                />
                                <TextField
                                    required
                                    type='number'
                                    id="Hatch Angle Minimum"
                                    label="Hatch Angle Minimum °"
                                    defaultValue={minHatchAngle}
                                    variant="outlined"
                                    onChange={(event) => setMinHatchAngle(event.target.value)}
                                    sx={{ width: '300px', margin: '10px' }}
                                />
                            </div>
                            <br />
                        </Form>
                    </>
                )
                return display;
                {/* <h3>Device Name: <span style={{ color: 'blue' }}> {device.name}</span></h3>
                <h3>Device IMEI:	<span style={{ color: 'blue' }}> {device.imei}</span></h3>
                <h3>Measurement Rate: {edit ? <TextField style={{ marginTop: '-15px' }} label="Measurement Rate (minutes)" margin="normal" variant="outlined" value={measRate} onChange={(event) => setMeasRate(event.target.value)}>  </TextField> : <span style={{ color: 'blue' }}> {config.measurementRate} minutes</span>}</h3>
                <h3>Repeat Measurement Count: {edit ? <TextField style={{ marginTop: '-15px' }} label="Repeat Measurement Count" margin="normal" variant="outlined" value={repMeasCount} onChange={(event) => setRepMeasCount(event.target.value)}>  </TextField> : <span style={{ color: 'blue' }}> {config.repeatMeasCount}</span>}</h3>
                <h3>Repeat Measurement Rate: {edit ? <TextField style={{ marginTop: '-15px' }} label="Repeat Measurement Rate" margin="normal" variant="outlined" value={repMeasRate} onChange={(event) => setRepMeasRate(event.target.value)}>  </TextField> : <span style={{ color: 'blue' }}> {config.repeatMeasRate}</span>}</h3>
                <h3>Methane Threshold: {edit ? <TextField style={{ marginTop: '-15px' }} label="Methane Threshold" margin="normal" variant="outlined" value={methaneThreshold} onChange={(event) => setMethaneThreshold(event.target.value)}>  </TextField> : <span style={{ color: 'blue' }}> {config.methaneThreshold} (ppm/ppb?)</span>}</h3>
                <h3>Hatch Angle Maximum: {edit ? <TextField style={{ marginTop: '-15px' }} label="Hatch Angle Maximum" margin="normal" variant="outlined" value={maxHatchAngle} onChange={(event) => setMaxHatchAngle(event.target.value)}>  </TextField> : <span style={{ color: 'blue' }}> {config.hatchAngleMax}°</span>}</h3>
                <h3>Hatch Angle Minimum: {edit ? <TextField style={{ marginTop: '-15px' }} label="Hatch Angle Minimum" margin="normal" variant="outlined" value={minHatchAngle} onChange={(event) => setMinHatchAngle(event.target.value)}>  </TextField> : <span style={{ color: 'blue' }}> {config.hatchAngleMin}°</span>}</h3>
                <h3>Most Recent Updated: 	<span style={{ color: 'blue' }}> {moment.utc(config.updated).local().format('M/D/YY, HH:mm ')}</span></h3>
                 */}
            default:
                return 'Unknown stepIndex';
        }
    }
    const body = (
        <Paper>
            <Stepper
                style={{ padding: '0px' }}
                activeStep={activeStep}
                alternativeLabel
            >
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div style={{ width: '100%' }}>
                {activeStep === steps.length ? (
                    <div style={{ textAlign: 'center', marginTop: '15px' }}>
                        <Typography
                            sx={{
                                marginTop: theme.spacing(1),
                                marginBottom: theme.spacing(1)
                            }}
                        >
                            All steps completed
                        </Typography>
                        <Button
                            onClick={handleReset}
                        >
                            Reset
                        </Button>
                    </div>
                ) : (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <div style={{ marginTop: '15px', marginBottom: '5px', width: '100%', /* display: 'flex', justifyContent: 'center', alignItems: 'center' */ }}>
                            {getStepContent(activeStep)}
                        </div>
                        <div
                            style={{ marginTop: '15px', textAlign: 'center' }}
                        >
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ marginRight: theme.spacing(1) }}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                // color="primary"
                                sx={{ bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }}
                                onClick={handleNext}
                            >
                                {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Paper>
    );

    return body;
})



export default connect()(CreateDevice);
