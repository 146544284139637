import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../../alerts/Toast';
import getToken from '../../../components/Tokens/GetToken';
import fileDownload from 'js-file-download';

function* JsonFileDownloadSaga() {
    yield takeLatest('GET_JSON_FILE', FetchJsonFile);
}

function* FetchJsonFile(action) {
    console.log("Saga",action)
    try {
        let token = getToken();

        let retFile = yield axios.get(`api/JsonFile/GetJsonFile/${action.payload}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        console.log("retval for homedata", retFile.data);
        let fileJson = JSON.stringify(retFile.data);
        // yield put({ type: "SET_HOME_DATA", payload: retval.data });
        fileDownload(fileJson, 'SystemLogData.json');

    } catch (error) {
        console.log('error in FetchJsonFile saga', error);
        Toast.fire({
            icon: 'error',
            title: `Error with Grabbing Json File: Error Code ${error.response.status} ${error.response.statusText}`,
        });
    }
}//GetReadingsDayCount-GetReadingsMonthCount

export default JsonFileDownloadSaga;