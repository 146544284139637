import React, { useState, useEffect } from 'react';
import {
    Box,
} from "@mui/material";
import { useSelector } from 'react-redux';
import ChartService from "../../../utils/DashboardChartService";
import moment from 'moment';
import Swal from 'sweetalert2';

const DeviceAlarmStatus = (props) => {
    // const isLoading = useSelector((state) => state.FaultReducer.faultPage);
    const data = useSelector((state) => state.DeviceReducers.AlarmData);
    // const data = [
    //     {
    //         id: 12,
    //         type: 'Error',
    //         timestamp: "2024-02-26T20:51:31Z",
    //         message: 'Motor Jammed'
    //     },
    //     {
    //         id: 13,
    //         type: 'Warning',
    //         timestamp: "2024-02-27T20:51:31Z",
    //         message: 'Communication Error'
    //     },
    //     {
    //         id: 14,
    //         type: 'Info',
    //         timestamp: "2024-02-28T20:51:31Z",
    //         message: 'Non-optimal Charging'
    //     },
    // ];
    // console.log(' #$ data', data);
    const columns = [
        {
            field: "severity", headerName: "Type", flex: 1,
            cellClassName: (params) => {
                switch (params.row.severity) {
                    case 2:
                        return "Error"
                    case 1:
                        return "Warning"
                    case 0:
                        return "Info"
                    default:
                        break;
                }
            },
            renderCell: (params) => {
                switch (params.row.severity) {
                    case 2:
                        return "Error"
                    case 1:
                        return "Warning"
                    case 0:
                        return "Info"
                    default:
                        break;
                }
            },

        },
        { field: "timestamp", headerName: "Report Date", flex: 2, valueGetter: (params) => moment.utc(params.row.timestamp).local().format('M/D/YY, HH:mm ') + "UTC" },
        { field: "description", headerName: "Message", flex: 2 },

    ];

    // if (isLoading === true){
    //     Swal.fire({
    //         title: "Loading Faults...",
    //         imageUrl: '/static/images/loading-gif.gif',
    //         showConfirmButton: false,
    //         backdrop: "false",
    //     })
    // };
    // if (isLoading === false){
    //     Swal.close();
    // };

    return (
        <Box width={'100%'} height={'100%'}>
            <ChartService data={{ type: 'special chart', title: '', columns: columns, list: data }} />
        </Box>
    )
}

export default DeviceAlarmStatus;
