import { combineReducers } from 'redux';

const DeviceConfig = (state = [], action) => {
    switch (action.type) {
        case 'SET_DEVICE_CONFIG':
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const ConfigStatus = (state = 0, action) => {
    switch (action.type) {
        case 'SET_CONFIG_STATUS':
            state = action.payload;
            return state;
        default:
            return state;
    }
};
export default combineReducers({
    DeviceConfig,
    ConfigStatus,

});