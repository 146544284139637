import React from "react";
import {
    Box,
    Container,
    Tab,
    Tabs,
    AppBar,
    Card,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import Page from '../../components/Page';
import Group from './AdminGroups/AdminGroups';
import Firmware from './AdminFirmware/AdminFirmware';
import Hardware from './AdminHardware/AdminHardware';
import { connect, useDispatch, useSelector } from 'react-redux';
import UserTable from './AdminUsers/AdminUsers';
// import DeviceTables from "./Devices";
import theme from "../../theme";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router';
import getToken from '../../components/Tokens/GetToken';


function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
        >
            {value === index && (
                <Box sx={{ paddingTop: theme.spacing(1) }} >
                    {children}
                </Box>
            )}
        </div>
    );
}

const Admin = (props) => {
    const [value, setValue] = React.useState(0);
    // const data = useSelector((state) => state.admin.hardwareList);
    // const loading = useSelector((state) => state.admin.adminloading);
    const loading = false;
    const groups = useSelector((state) => state.GroupReducer.AllGroups);
    const users = useSelector((state) => state.AdminReducer.AdminAllUsers);
    const firmware = useSelector((state) => state.FirmwareReducer.AllFirmware);
    const hardware = useSelector((state) => state.HardwareReducer.AllHardware);
    const navigate = useNavigate();
    const token = getToken();
    // const devices = useSelector((state) => state.admin.devices);
    // const systemAdmin = useSelector((state) => state.setUser.systemAdmin);
    // const user = useSelector((state) => state.setUser.setUser);
    // const role = useSelector((state) => state.setUser.currentRole);
    const dispatch = useDispatch();
    // console.log('admin props', props);
    useEffect(() => {
        if (!token){
          navigate('/login');
        }
    }, [])
    
    // console.log('groups', groups);

    // React.useEffect(() => {
    //     if (systemAdmin) {
    //         dispatch({ type: "GRAB_ADMIN" })
    //     }
    //     else {
    //         dispatch({
    //             type: 'ADMIN_GRAB_ALL_USERS',
    //             payload: user,
    //             role: role.id
    //         })
    //     }
    //     dispatch({ type: 'GET_USER_AND_GROUP' })
    // }, []);
    // React.useEffect(() => {
    //     if ((window.location.pathname === '/admin' && role.name !== 'Admin') & !systemAdmin) {
    //         window.location.pathname = '/home'
    //     }
    // }, [role]);

    useEffect(() => {
        if (!token){
            navigate('/login');
        } else {
            dispatch({ type: 'ADMIN_GRAB_ALL_USERS' })
        }
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    };

    if (loading === false) {
        return (
            <Page title="Admin">
                <Container maxWidth={false} sx={{ paddingTop: theme.spacing(1) }}>
                    <Card>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="#a72525"
                                textColor="#a72525"
                                variant="fullWidth"
                            >
                                {/* <Tab label="Groups" /> */}
                                <Tab label="Users" style={{backgroundColor:'#fff', color:'#a72525'}}/>
                                {/* <Tab label="Firmware" /> */}
                                {/* <Tab label="Hardware" /> */}
                            </Tabs>
                        </AppBar>
                        {/* <TabPanel value={value} index={0}>
                            <Group data={groups} />
                        </TabPanel> */}
                        <TabPanel value={value} index={0}>
                            <UserTable data={users} groups={groups} />
                        </TabPanel>
                        {/* <TabPanel value={value} index={2}>
                            <Firmware data={firmware} />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <Hardware data={hardware} />
                        </TabPanel> */}
                    </Card>
                </Container>
            </Page>
        )
    } else {
        return (
            <Container
                maxWidth={false}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center"
                }}
            >
                <Backdrop
                    sx={{ color: '#fff' }}
                    open={true}
                >
                    <CircularProgress color="primary" thickness={5} size={100} />
                </Backdrop>
            </Container>
        )
    }
}
export default Admin;