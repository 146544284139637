import React, { useState, useEffect } from 'react';
import {
    Box,
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Tooltip, Typography } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import moment from 'moment';
import theme from '../../../theme';


const ServiceInfo = (props) => {
    const dispatch = useDispatch();
    const state = useSelector((store) => store.DeviceReducers.SearchClicked);
    const status = useSelector((store) => store.DeviceReducers.DeviceInfo);
    const returnCode = useSelector((store) => store.DeviceReducers.DeviceInfoStatus);
    // const state = [];
    // const status = {
    //     deviceName: 'Watermelon 1',
    //     model: "Solar Bee",
    //     batteryCharge: 92.4,
    //     fwVersion: 0.23,
    //     cabCompare: 'no match',
    //     cabExpect: 0.25,
    //     reportDate: '2-28-24, 19:16',
    // };
    
    // const returnCode = [];
    // console.log('return code', returnCode);
    // console.log('state', state);
    // console.log("#$ status", status);
    if (typeof status.deviceName !== 'undefined') {
        dispatch({ type: 'SEARCH_CLICKED', payload: false });
    }
    let colors = {};
    status.cabCompare = "match";
    if (!status.noConfig && !status.noStat) {
        // console.log('status ', status.noConfig, status.noStat);
        if (status.cabCompare === 'match') {
            colors.cab = 'green';
        } else if (status.cabCompare === 'no match') {
            colors.cab = 'red';
        } else {
            colors.cab = 'black'
        }
    }
    console.log("status", status);

    if (status.batteryCharge >= 75){
            colors.bat = "green";
    } else if (status.batteryCharge < 75 && status.batteryCharge > 20){
            colors.bat = "orange";
    } else if (status.batteryCharge < 20){
            colors.bat = "red";
    }

    return (
        <Box sx={{ width: '100%', height: "50%", marginLeft: '10px', /* marginTop: '25px', paddingLeft: '10px', textAlign: 'left', borderColor: theme.palette.secondary.purple, borderStyle: 'solid', borderWidth: '2px', borderRadius: '10px' */ }}>
            {state === true ? "Loading..." :
                <Typography variant="h5">
                    <p> 
                        Device Name: {!status.deviceName ? "" : status.deviceName}<br />
                        Model: {!status.model ? "" : status.model}<br />
                        State of Charge: {!status.batteryCharge ? "" : status.batteryCharge === "Not Reported" ? status.batteryCharge : <span style={{ color: colors.bat }}>{Number(status.batteryCharge).toFixed(1) + "%"}</span>}<br/>
                        FW Version: {!status.fwVersion ? "" : <span style={{ color: colors.cab }}> {status.fwVersion}</span>} {status.cabExpect ? "(" + status.cabExpect + " expected)" : ""}<br />
                        Last Reported: {!status.reportDate ? "" : moment.utc(status.reportDate).format('MM/DD/YY, HH:mm') + "UTC"} 
                    </p>
                </Typography>
            }
        </Box>
    )
}
export default ServiceInfo;