import React, { useEffect, useState } from "react";
import Page from '../../components/Page';
import ChartService from "../../utils/DashboardChartService";
import getToken from "../../components/Tokens/GetToken";
import {
    Box,
    Container,
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import theme from "../../theme";
import Toolbar from '../Toolbar/Toolbar';
import { styled } from '@mui/system';
import { useNavigate } from "react-router";
import moment from "moment";

const MyPage = styled(Page)({
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
})


const asdfg = () => {

    const token = getToken();
    const measurements = useSelector((state) => state.DeviceReducers.HomeMeasurements);
    console.log("#$ measurements", measurements);

    const [data, setData] = React.useState([]);
    const [startDate, setStartDate] = React.useState(moment().subtract(30, "days").startOf('day').format());
    const [endDate, setEndDate] = React.useState(moment().format());
    let datasets = []
    let datasets1 = []
    let datasets2 = []
    let datasets3 = []


    useEffect(() => {
        if (token) {
            // dispatch({ type: 'GET_DEVICE_VIEW_DATA', payload: "8867ad00-bcda-4a58-bcb3-7eb4f92c7d05", start: startDate, end: endDate });
            // dispatch({ type: 'FLEET_SERVICE', payload: {} });
        }
    }, [])

    const dispatch = useDispatch();

    let colorArray = ['#00B3E6', '#0e5e8a', '#33DAFF',
        '#4D80CC', '#4DB3FF', '#40F0E5', '#99FF99', '#FF6633', '#B34D4D',
        '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF', '#FFFF99'
    ]

    function GraphData() {
        if (measurements.length > 0) {
            let arrayGraph = []; // for the graph, resets every time through
            //motor current
            // battery
            // temp
            for (let i = 0; i < measurements.length; i++) {
                // for the graph
                arrayGraph.push({ x: moment.utc(measurements[i].created).format('M/D/YY, HH:mm'), y: measurements[i].motorCurrent, name: 'Motor Current' });
            }
            // data sets for info
            // console.log('1 array graph j', DataGrid[i]);
            console.log('#$ 2 array graph', arrayGraph);
            datasets1.push({
                // label: data.name,
                fill: false,
                borderColor: colorArray[0],
                lineTension: 0,
                // backgroundColor: colorArray[j+1],
                data: arrayGraph,
                barPercentage: 0.5,
                barThickness: 6,
                maxBarThinkness: 8,
                minBarLength: 2,
                borderWidth: 2,
            })
            return datasets1;
        } else {
            return [];
        }
    }

    let chartData1 = {
        datasets: GraphData(),
        type: "line",
        title: {display: false, text: "Reading vs. Time"},
        layout: { x: 0, y: 0, w: 6, h: 8 }
    }


    return (


        <div>
            
            <Box>
                <div style={{"font-size": "18pt", "margin": "15px", "color": "gray"}}>Device: FantasticLustrousArtifice</div>

                <Box style={{ height: "300px"}}>
                        <ChartService data={chartData1} />
                </Box>
            
            </Box>

        </div>
    )

}

export default asdfg;