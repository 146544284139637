import React from "react";
import {
    Card,
    Box,
    Typography,
    TextField,
    IconButton,
    Grid,
    Button,
    Autocomplete,
    Checkbox,
    Tooltip
} from '@mui/material';
import { XSquare, Cpu, Activity, Database } from 'react-feather';
import { useDispatch, useSelector } from "react-redux";
import {
    CheckBoxOutlineBlank,
    CheckBox as CheckBoxIcon
} from '@mui/icons-material';
import {
    DatePicker,
    LocalizationProvider,
} from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import axios from "axios";
import moment from "moment";
import Toast from "../../alerts/Toast";
import getToken from "../../components/Tokens/GetToken";
import SplitToken from "../../components/Tokens/SplitToken";
import theme from "../../theme";

const  Widget = React.forwardRef((props, ref) => {
    const [type, setType] = React.useState(null);
    const [chartType, setChartType] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [group, setGroup] = React.useState(null);
    const [device, setDevice] = React.useState([]);
    const [dataPoint, setDataPoint] = React.useState(null);
    const [timeline, setTimeline] = React.useState({ index: 0, range: 'past 3 days' });
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [tip, setTip] = React.useState(false);
    const [pointTip, setPointTip] = React.useState(false);
    // const allGroups = useSelector((state) => state.setUser.allGroups);
    // const allDevices = useSelector((state) => state.DashboardReducer.creationDevices);
    // const allPoints = useSelector((state) => state.DashboardReducer.creationDataPoints);
    // const user = useSelector((state) => state.setUser.setUser);
    // const token = useSelector((state) => state.setUser.token);
    const allGroups = useSelector((state) => state.GroupReducer.AllGroups);
    const allDevices = useSelector((state) => state.DeviceReducers.AllDevices);
    const allPoints = [];
    const user = SplitToken();
    const token = getToken();
    const dispatch = useDispatch();
    const timelines = [
        { index: 0, range: 'past 3 days' },
        { index: 1, range: 'past month' },
        { index: 2, range: 'past 3 months' },
        { index: 3, range: 'past 6 months' },
        { index: 4, range: 'custom' }
    ];
    if( chartType === 'map' ){
        timelines.push({ index: 5, range: 'last known location'})
    }
    const icon = <CheckBoxOutlineBlank fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const handleSubmit = () => {
        if ( name == null ){
            return Toast.fire({
                icon: 'error',
                title: 'Please add name'
            })
        }
        else{
            let deviceArray = []
            if( type.type === 'list' ){
                allDevices.map((x) => deviceArray.push(x.id));
            }
            if ( type.type === 'line' && !dataPoint ) {
                return Toast.fire({
                    icon: 'error',
                    title: 'Please add Data Points'
                })
            }
            if ( type.type === 'bar' && !dataPoint ) {
                return Toast.fire({
                    icon: 'error',
                    title: 'Please add Data Points'
                })
            }
            if ( type.type === 'pie' && !dataPoint ) {
                return Toast.fire({
                    icon: 'error',
                    title: 'Please add Data Points'
                })
            }
            if ( type.type === 'query' && device.length === 0 ) {
                allDevices.map((x) => deviceArray.push(x.id));
            }
            if ( type.type === 'map' && device.length === 0 ) {
                allDevices.map((x) => deviceArray.push(x.id));
            }
            if ( type.type === 'check' && device.length === 0 ) {
                allDevices.map((x) => deviceArray.push(x.id));
            }
            device.map((x) => deviceArray.push(x.id))
            if( type.type === 'line' && deviceArray.length === 0 ) {
                return Toast.fire({
                    icon: 'error',
                    title: 'Please select group'
                })
            }
            else {
                const item = {
                    name: name,
                    devices: deviceArray,
                    dataPoints: dataPoint ? dataPoint : [],
                    type: type.type,
                    userGuid: user.id,
                    timeline: timeline.index,
                    start: startDate,
                    end: endDate,
                    w: 6,
                    h: 6,
                    x: 0,
                    y: 0,
                    deleted: null,
                }
                axios.post('/api/Dashboard', item,{
                    headers: {
                      'Authorization': `Bearer ${token}`
                    }
                  }).then(() => dispatch({
                    type: "GET_CHART_DATA",
                    payload: user.id
                })).then(() => Toast.fire({
                    icon: 'success',
                    title: 'Widget Created'
                })).then(() => props.close())
                .then(() => {
                    dispatch({type: 'SET_DASH_DEVICES', payload: []})
                    dispatch({type: 'SET_DAST_DATA_POINTS', payload: []})
                })
                .catch((error) => console.log(`error posting new widget: ${error}`))
            }
        }
    }

    const handleOpen = (x) => {
        setType(x);
        setChartType(x.type);
    };

    const handleClose = () => {
        setType(null);
    }

    const handleGroupChange = (x) => {
        setGroup(x)
        dispatch({type: "GET_DASH_DEVICES", payload: x})
    }
    const handleDeviceChange = (x) => {
        setDevice(x)
        dispatch({type: "GET_DASH_POINTS", payload: x})
    }

    const tooltipOpen = (x) => {
        if (type.type === 'query' || type.type === 'map' || type.type === 'check') {
            switch (x) {
                case 'device':
                    return setTip(true);
                case 'point':
                    return setPointTip(true);
                default:
                    setTip(false);
                    setPointTip(false);
            }
        }
    }

    const tooltipClose = () => {
        setTip(false);
        setPointTip(false);
    }

    const item = [
        {
            title: 'Line Chart',
            src: '/images/LineChart.png',
            text: 'Custom line chart with selected dataset',
            type: 'line'
        },
        {
            title: 'Bar Chart',
            src: '/images/BarChart.png',
            text: 'Custom bar chart with selected dataset',
            type: 'bar'
        },
        {
            title: 'Pie Chart',
            src: '/images/PieChart.png',
            text: 'Custom pie chart with selected dataset',
            type: 'pie'
        },
        {
            title: 'Map',
            src: '/images/MapChart.png',
            text: 'Custom map',
            type: 'map'
        },
        {
            title: 'Device List',
            src: <Cpu color="#e64027" style={{ height: 40, width: 50 }}/>,
            text: 'List of all devices and last check-ins',
            type: 'list'
        },
        {
            title: 'Latest Check-in',
            src: <Activity color="#e64027" style={{ height: 40, width: 50 }}/>,
            text: 'Latest check-in from selected device',
            type: 'check'
        },
        {
            title: 'Data Query',
            src: <Database color="#e64027" style={{ height: 40, width: 50 }}/>,
            text: 'Query data from selected device',
            type: 'query'
        }
    ]

    const DataPointsView = (points) => {
        if ( chartType === "map" ){
            return (
                <React.Fragment></React.Fragment>
            )
        }
        if ( device.length > 1 ) { 
            return (
                <Autocomplete
                    id="datapoint-selection"
                    limitTags={2}
                    sx={{ width: '70%', margin: '5px' }}
                    loading={points.length > 0 ? false : true}
                    options={points}
                    onChange={(event, newValue) => setDataPoint([newValue])}
                    getOptionLabel={(option) => option}
                    renderOption={( props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} variant="standard" label="Data Points" />}
                />
            )
        }
        else{
            return (
                <Autocomplete
                    id="datapoint-selection"
                    multiple
                    loading={points.length > 0 ? false : true}
                    disableCloseOnSelect
                    limitTags={2}
                    sx={{ width: '70%', margin: '5px' }}
                    options={points}
                    onChange={(event, newValue) => setDataPoint(newValue)}
                    getOptionLabel={(option) => option}
                    renderOption={( props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} variant="standard" label="Data Points" />}
                /> 
            )
        }
    }

    const view = () => {
        if ( type === null ) {
            return (
                <Grid container spacing={3}>
                    {item.map((x, i) => {
                        return (
                            <Grid 
                                item
                                key={i}
                                xl={x.title === "Data Query" ? 12 : 6}
                                lg={x.title === "Data Query" ? 12 : 6}
                                md={x.title === "Data Query" ? 12 : 6}
                                xs={x.title === "Data Query" ? 12 : 6}
                            >
                                <Button
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        width: '100%'
                                    }}
                                    onClick={() => handleOpen(x)}
                                >
                                    <Box>
                                        <Typography
                                            variant="h5"
                                        >
                                            {x.title}
                                        </Typography>
                                    </Box>
                                    {x.title === "Device List" || x.title === "Latest Check-in" || x.title === "Data Query" ?
                                    <Box sx={{ height: 50, width: 50 }}>
                                        {x.src}
                                    </Box>
                                    :
                                    <Box>
                                        <img src={x.src} alt={x.title} />
                                    </Box>
                                    }
                                    <Box>
                                        <Typography
                                            variant="body2"
                                        >
                                            {x.text}
                                        </Typography>
                                    </Box>
                                </Button>
                            </Grid>
                        )
                    })}
                </Grid>
            )
        }
        else if ( chartType === 'list' ) {
            return (
                <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <IconButton
                            disabled
                            sx={{ margin: '10px' }}
                        >
                            {type.src}
                        </IconButton>
                        <Typography
                            variant="h5"
                            sx={{ margin: '10px' }}
                        >
                            {type.title}
                        </Typography>
                    </Box>
                    <Box>
                        <TextField
                            variant="standard"
                            label="Name"
                            onChange={(event) => setName(event.target.value)}
                            sx={{ width: '70%', margin: '5px' }}
                        />
                        <Autocomplete
                            id="group-selection"
                            sx={{ width: '70%', margin: '5px' }}
                            options={allGroups}
                            getOptionLabel={(option) => option.name}
                            onChange={( event, newValue ) => {handleGroupChange( newValue )}}
                            renderInput={(params) => <TextField {...params} variant="standard" label="Group" />}
                        />
                    </Box>
                    <Box
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}
                    >
                        <Button
                            variant="outlined"
                            sx={{ margin: '10px' }}
                            onClick={handleClose}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            // color="primary"
                            sx={{ margin: '10px', bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            )
        }
        else if ( chartType === 'check' ){
            return (
                <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <Box
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    <IconButton
                        disabled
                        sx={{ margin: '10px' }}
                    >
                        {type.src}
                    </IconButton>
                    <Typography
                        variant="h5"
                        sx={{ margin: '10px' }}
                    >
                        {type.title}
                    </Typography>
                </Box>
                <Box>
                    <TextField
                        variant="standard"
                        label="Name"
                        onChange={(event) => setName(event.target.value)}
                        sx={{ width: '70%', margin: '5px' }}
                    />
                    <Autocomplete
                        id="group-selection"
                        sx={{ width: '70%', margin: '5px' }}
                        options={allGroups}
                        getOptionLabel={(option) => option.name}
                        onChange={( event, newValue ) => {handleGroupChange( newValue )}}
                        renderInput={(params) => <TextField {...params} variant="standard" label="Group" />}
                    />
                    <Tooltip open={tip} onOpen={() => tooltipOpen('device')} onClose={tooltipClose} title="Leave empty for all devices in group and all data points" arrow placement="right">
                        <Autocomplete
                            id="device-selection"
                            multiple
                            limitTags={2}
                            sx={{ width: '70%', margin: '5px' }}
                            disableCloseOnSelect
                            options={allDevices}
                            onChange={( event, newValue ) => handleDeviceChange( newValue )}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} variant="standard" label="Devices" />}
                        />
                    </Tooltip>
                </Box>
                <Box
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}
                >
                    <Button
                        variant="outlined"
                        sx={{ margin: '10px' }}
                        onClick={handleClose}
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        // color="primary"
                        sx={{ margin: '10px', bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>
            )
        }
        else {
            return (
                <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <IconButton
                            disabled
                            sx={{ margin: '10px' }}
                        >
                            {type.title === "Device List" || type.title === "Data Query" ? type.src : <img src={type.src} alt={type.title} /> }
                        </IconButton>
                        <Typography
                            variant="h5"
                            sx={{ margin: '10px' }}
                        >
                            {type.title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <TextField
                            variant="standard"
                            label="Name"
                            onChange={(event) => setName(event.target.value)}
                            sx={{ width: '70%', margin: '5px' }}
                        />
                        <Autocomplete
                            id="group-selection"
                            sx={{ width: '70%', margin: '5px' }}
                            options={allGroups}
                            getOptionLabel={(option) => option.name}
                            onChange={( event, newValue ) => {handleGroupChange( newValue )}}
                            renderInput={(params) => <TextField {...params} variant="standard" label="Group" />}
                        />
                        <Tooltip open={tip} onOpen={() => tooltipOpen('device')} onClose={tooltipClose} title="Leave empty for all devices in group and all data points" arrow placement="right">
                            <Autocomplete
                                id="device-selection"
                                multiple
                                loading={allDevices.length > 0 ? false : true}
                                limitTags={2}
                                sx={{ width: '70%', margin: '5px' }}
                                options={allDevices}
                                onChange={( event, newValue ) => handleDeviceChange( newValue )}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} variant="standard" label="Devices" />}
                            />
                        </Tooltip>
                        <Tooltip open={pointTip} onOpen={() => tooltipOpen('point')} onClose={tooltipClose} title="Leave empty for all data points" arrow placement="right">
                            {DataPointsView(allPoints)}
                        </Tooltip>
                        <Autocomplete
                            sx={{ width: '70%', margin: '5px' }}
                            options={timelines}
                            getOptionLabel={(option) => option.range}
                            defaultValue={timeline}
                            isOptionEqualToValue={(option, value) => option.index === value.index}
                            onChange={(event, newValue) => {
                                setTimeline(newValue)
                            }}
                            renderInput={(params) => <TextField {...params} variant="standard" label="Timeline" />}
                        />
                        { timeline.range === 'custom' ? 
                        <Box sx={{ marginTop: '15px' }}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker 
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => {
                                        setStartDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} /> }
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker 
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => {
                                        setEndDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} /> }
                                />
                            </LocalizationProvider>
                        </Box> : '' }
                    </Box>
                    <Box
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}
                    >
                        <Button
                            variant="outlined"
                            sx={{ margin: '10px' }}
                            onClick={handleClose}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            // color="primary"
                            sx={{ margin: '10px', bgcolor: theme.palette.button.primary, '&:hover' : {bgcolor : theme.palette.button.hover} }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            )
        }
    }
 
    return (
        <Card
            sx={{ height: '75%', maxHeight: '1050px' , width: '40%', overflow: "auto", display: 'flex', flexDirection: 'column' }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '20px'
                }}
            >
                <Typography
                    variant="h4"
                >
                    Add Widget
                </Typography>
                <Box>
                </Box>
                <IconButton color="primary" onClick={props.close} >
                    <XSquare/>
                </IconButton>
            </Box>
            <Box sx={{ marginTop: '20px', marginBottom: '20px', height: '100%', width: '100%' }}>
                {view()}
            </Box>
        </Card>
    )
})

export default Widget;