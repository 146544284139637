import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../src/components/Logo';
import getToken from '../components/Tokens/GetToken';
import splitToken from '../components/Tokens/SplitToken';
import { useDispatch } from 'react-redux';
import Logo2 from '../components/penumbra-logo.png';
import theme from '../theme';

const TopBar = ({ onMobileNavOpen }) => {
  const [notifications] = useState([]);
  const [monitor, setMonitor] = useState(false);
  const [manage, setManage] = useState(false);
  const [settings, setSettings] = useState(false);
  const [target, setTarget] = useState("");
  const navigate = useNavigate();
  const token = getToken(); // add logic to render specific thing when a user fits specific criteria
  const user = splitToken();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
        dispatch({ type: 'FETCH_EVERYTHING'});
    }
  }, [])


  return (
    <AppBar
      elevation={0}
      sx={{ backgroundColor: `${theme.palette.primary.main}`,   backgroundImage: `linear-gradient(to right, #fff , #a72525)`
    }}
    >
      <Toolbar /* style={{ display: 'flex',  */>
        <RouterLink to="/" >
          {/* <Logo2 style={{ width: "130px", margin: "5px 0px 10px 10px"  }} /> */}
          <img src={Logo2} style={{ width: "130px", margin: "5px 0px 10px 10px"  }}/>

        </RouterLink>
        <Box flexGrow={1} sx={{ display: 'flex', justifyContent: "right"}} >
          { token ? 
          <>
            <Button onClick={() => navigate('/')} style={{ display: 'flex', color: 'white', marginLeft: '30px', marginRight: "30px", fontSize: "1.25rem", fontWeight: "800" }}>
              Home
            </Button>
            {/* <Button onClick={() => navigate('/fleet')} style={{ display: 'flex', color: 'white', marginLeft: '30px', marginRight: "30px",  fontSize: "1.25rem", fontWeight: "800" }}>
              Fleet
            </Button>
            <Button onClick={() => navigate('/deviceView')} style={{ display: 'flex', color: 'white', marginLeft: '30px', marginRight: "30px",  fontSize: "1.25rem", fontWeight: "800" }}>
              Device
            </Button> */}
            {/* <Button id='monitorButton' onClick={(event) => {
              setTarget(event.currentTarget)
              setMonitor(!monitor)
            }}
              style={{ display: 'flex', color: 'white', marginLeft: '30px', marginRight: "30px" }}
            >

              Monitor<KeyboardArrowDownIcon />
            </Button> */}
            { user.isAdmin[1] == "Admin" ?
              <Button id='uploadButton'
                onClick={() => navigate('/fileUpload')}
                style={{ display: 'flex', color: 'white', marginLeft: '30px', marginRight: "30px",  fontSize: "1.25rem", fontWeight: "800" }}
              >
                Upload
              </Button>
              :
              <></>
            }
            { user.isAdmin[1] == "Admin" ?
              <Button id='manageButton' onClick={(event) => {
                setTarget(event.currentTarget)
                setManage(!manage)
              }}
                style={{ display: 'flex', color: 'white', marginLeft: '30px', marginRight: "30px",  fontSize: "1.25rem", fontWeight: "800" }}
              >
                Manage<KeyboardArrowDownIcon />
              </Button>
              :
              <></>
            }
            
            
            <Button id='settingsButton' onClick={(event) => {
              setTarget(event.currentTarget)
              setSettings(!settings)
            }}
              style={{ display: 'flex', color: 'white', marginLeft: '30px', marginRight: "30px",  fontSize: "1.25rem", fontWeight: "800" }}
            >
              Settings<KeyboardArrowDownIcon />
            </Button>

            {/* <Menu
              id="basic-menu"
              anchorEl={target}
              open={monitor}
              onClose={() => setMonitor(!monitor)}
            >
              <MenuItem onClick={() => {
                setMonitor(!monitor)
                navigate('/fleet')
              }}>
                Fleet
              </MenuItem>
              <MenuItem onClick={() => {
                setMonitor(!monitor)
                navigate('/deviceView')
              }}>
                Device Status
              </MenuItem>
            </Menu> */}

            <Menu
              id="basic-menu"
              anchorEl={target}
              open={manage}
              onClose={() => setManage(!manage)}
            >
              {/* <MenuItem onClick={() => {
                setManage(!manage)
                navigate('/configuration')
              }}>
                Device Configuration
              </MenuItem> */}
              <MenuItem onClick={() => {
                navigate('/admin')
              }}>
                Admin
              </MenuItem>
            </Menu>

            <Menu
              id="basic-menu"
              anchorEl={target}
              open={settings}
              onClose={() => setSettings(!settings)}
            >
              <MenuItem onClick={() => {
                navigate('/account')
              }}>
                Account
              </MenuItem>


              <MenuItem onClick={() => {
                navigate('/logout')
              }}>
                Sign Out
              </MenuItem>
            </Menu>
          </>
          : 
          <>
            <Button id='manageButton' onClick={(event) => {
              navigate('/login')
            }}
              style={{ display: 'flex', color: 'white', marginLeft: '30px', marginRight: "30px", fontSize: "1.25rem", fontWeight: "800" }}
            >
              Sign In
            </Button> 
            <Button id='manageButton' onClick={(event) => {
              navigate('/register')
            }}
              style={{ display: 'flex', color: 'white', marginLeft: '30px', marginRight: "30px", fontSize: "1.25rem", fontWeight: "800" }}
            >
              Register
            </Button>
          </>
          }

          {/* <Hidden xlUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="medium">
            <MenuIcon />
          </IconButton>
        </Hidden> */}
        </Box>
        {/* <RouterLink to="/" >
          <Logo2 style={{ width: "120px", marginRight: "10px" }} />
        </RouterLink> */}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;